import React, {useContext} from 'react';

import {DashboardLayout} from '../../components/Layout/Layout';
import {useNavigate} from "react-router-dom";
import AuthenticationContext from "../../contexts/AuthenticationContext";

const TeamPanel = () => {
    const {right} = useContext(AuthenticationContext);
    let navigate = useNavigate();
    return (
        <DashboardLayout >
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Team Panel</h2>
            <div className="flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <button onClick={() => {
                    navigate(`/${right}/team/course-list`)
                }}
                        className='m-3 p-3 border-1 rounded bg-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1 '>liste des cours
                </button>
            </div>
        </DashboardLayout>
)
}

export default TeamPanel;