import axios from "axios"
import {TOKEN, url} from "../utils/constants";

const client = axios.create({
    baseURL: url + "auth"
})


const getRight = async () => {
    try {
        const response = await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}})
        return response.data;
    } catch (e) {
        return e.response.status
    }
}



const login = async (email, password) => {
    try {
        const payload = {
            credentials: email,
            password: password,
        }
        const token = await client.post("/login", payload);
        localStorage.setItem('userid', token.data.id)
        localStorage.setItem(TOKEN, token.data.jwt)
        return token.data;
    } catch (e) {
        if (e.message !== undefined) {
            if (e.message === 'Network Error') {
                return e.code
            }
        }
        return e.response.status
    }
}


const register = async (
    lastName,
    firstName,
    gender,
    birthDate,
    street,
    houseNumber,
    box,
    postcode,
    locality,
    phone,
    level,
    tShirt,
    email,
    password,
    imageAuthorization
) => {
    if (typeof lastName !== 'string' || typeof firstName !== 'string') {
        throw new Error('lastName et firstName doivent être des chaînes');
    }
    let lastNameMaj = lastName.substring(0, 1).toUpperCase();
    let firstNameMaj = firstName.substring(0, 1).toUpperCase();
    lastName = lastNameMaj + lastName.substring(1).toLowerCase();
    firstName = firstNameMaj + firstName.substring(1).toLowerCase();
    let box_to_send = () => {
        if (box === "" || box === null) {
            return ' ';
        } else {
            return box;
        }
    }

    const payload = {
        lastName: lastName,
        firstName: firstName,
        gender: gender,
        birthDate: birthDate,
        street: street.toLowerCase(),
        houseNumber: houseNumber,
        box: box_to_send,
        postalCode: postcode,
        locality: locality.toLowerCase(),
        level: level,
        tShirt: tShirt,
        phone: phone,
        email: email,
        password: password,
        imageRight: imageAuthorization
    };

    try {
        const response = await client.post("/register", payload);
        localStorage.setItem('userid', response.data.id);
        localStorage.setItem(TOKEN, JSON.stringify(response.data.jwt));
        return response.data;
    } catch (error) {
        //console.log('=== DEBUG AuthService Error ===');
        //console.log('Error response data:', error.response?.data);
        
        const customError = new Error();
        
        if (error.response?.data?.status === 'error') {
            const { message, type, field, fields } = error.response.data;
            customError.message = message;
            customError.type = type;
            
            if (type === 'duplicate_multiple') {
                customError.fields = fields;
            } else if (type === 'duplicate') {
                customError.field = field;
            }
        } else {
            customError.message = "Une erreur est survenue lors de l'inscription";
            customError.type = 'error';
        }
        
        customError.status = error.response?.status;
        
        //console.log('Final error object:', {
        //    message: customError.message,
        //    field: customError.field,
        //    fields: customError.fields,
        //    type: customError.type,
        //    status: customError.status
        // });

        throw customError;
    }
};

const paswordForgot = async (email) => {
    const payload = {
        email: email
    }
    try {
        const token = await client.post("/password/forgotten", payload)
        return token.data;
    } catch (e) {
        if (e.message !== undefined) {
            if (e.message === 'Network Error') {
                return e.code
            }
        }
        return e.response.status
    }
}

const updatePassword = async (newPassword) => {
    try{
        const payload = {
            password: newPassword
        }
        await client.put( "/password", payload, {headers: {'Authorization': localStorage.getItem('token')}});

    } catch (e) {
        console.error(e);
        throw e;
    }
}



export {
    login,
    register,
    getRight,
    paswordForgot,
    updatePassword,
}