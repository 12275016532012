import React from 'react';

import {DashboardLayout} from '../../components/Layout/Layout';

const TeamsPage = ({right}) => {
    return (
        <DashboardLayout right={right}>
            <h2 className='absolute -top-8 left-10 font-sans  text-xl'>Team Page</h2>
        </DashboardLayout>
    )
}

export default TeamsPage;
