import React from "react";
import styled from "styled-components";

const Input = styled.input.attrs(props => ({
    type: "text",
    size: props.small ? 5 : undefined
}))`
    color: #0d0d0d;
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <Input
            id="search"
            type="text"
            placeholder="Search"
            value={filterText}
            onChange={onFilter}
        />
        <button className="border-1 rounded p-1 border-white border-solid" onClick={onClear}>X</button>
    </>
);

export default FilterComponent;
