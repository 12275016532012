import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "inscriptions"
})

const inscriptions_list = async () => {
    try {
        return await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        console.error(e);
    }
}

const inscription_stage = async (stageId,accountId) => {
    try {
        const payload = {
            accountId: parseInt(accountId) ,
            trainingCourseId : parseInt(stageId)
        }

        return await client.post("/trainingCourses", payload,{headers: {'Authorization': localStorage.getItem('token')}});
    }catch (e) {
        throw(e);
    }
}


const delete_inscription_stage = async (inscriptionId) => {
    try {
        return await client.delete("/" + inscriptionId, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        console.log(e);
    }
}

const inscription_subscription = async (accountId,subscriptionId) => {
    try {
        const payload = {
            accountId: accountId,
            subscriptionId: subscriptionId
        }
        return await client.post("/subscriptions", payload, {headers: {'Authorization': localStorage.getItem('token')}})
    }catch (e) {
       throw (e);
    }
}


const delete_inscription = async (inscriptionId) => {
    try {
        return await client.delete("/" + inscriptionId, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        console.log(e);
    }
}

const mark_inscription_as = async (inscriptionId,paid,cancelled) => {
    const payload = {
        paid: paid,
        cancelled:cancelled
    }
    try {
        return await client.put("/" + inscriptionId, payload,{headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        console.log(e);
    }
    return null;
}


export {
    inscriptions_list, delete_inscription,inscription_stage,inscription_subscription,mark_inscription_as
}