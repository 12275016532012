import React from "react";
import Select from "react-select";
import PaymentInscriptionButton from "./PaymentInscriptionButton";

const SelectorCourse = ({
                            courseList,
                            courseFiltered,
                            handleCourseSelect,
                            handleAddToCart,
                            selectedCourse,
                            onUpdate,
                            error,
                            setError,
                            outputMessage
                        }) => {
    return (
        <div>
            <div
                className="flex flex-col items-center justify-center ">
                <h1 className="text-3xl font-bold mb-2">Veuillez
                    sélectionner un
                    cours </h1>
                <div
                    className="flex flex-col items-center justify-center ">
                    {courseList.length === 0 ? <p className="text-2xl font-bold text-red-600 mb-5" >Aucun cours </p> :
                        <div className=" h-max w-full>">
                            <Select
                                className="text-black mb-2"
                                options={courseFiltered}
                                onChange={(e) => {
                                    handleCourseSelect(e.value)
                                }}/>
                            {selectedCourse &&
                                <PaymentInscriptionButton selectedCourse={selectedCourse} courseList={courseList}
                                                          handleAddToCart={handleAddToCart} onUpdate={onUpdate}
                                                          error={error} setError={setError} outputMessage={outputMessage}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>

    );
}

export default SelectorCourse;