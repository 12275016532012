import axios from "axios";
import {url} from "../utils/constants";

const client = axios.create({
    baseURL: url + "members-types"
})
const getCotisation = async () => {
    const response = await client.get("/",{headers: {'Authorization': localStorage.getItem('token')}});
    return response.data;
}

const editCotisation = async (id, name, price) => {
    const payload = {
        name: name,
        price: price,
    }
    const response = await client.put(`/${id}`, payload,{headers: {'Authorization': localStorage.getItem('token')}});
    return response
}


export {
    getCotisation,
    editCotisation
}