import React, {Fragment, useState} from "react";
import Select from 'react-select'
import Portal from "../Portal/Portal";
import * as api from "../../services/BrevetService"

const AddBrevetModal = ({onCancel, onUpdate}) => {
  const [description, setDescription] = useState();
  const [brevet, setBrevet] = useState();
  const [position, setPosition] = useState();
  const [error, setError] = useState();
  const [selectedType, setSelectedType] = useState();
  const types = [
    {value: 0, label: 'indoor'},
    {value: 1, label: 'outdoor'},
  ]

  const handleSubmit = async e => {
    e.preventDefault();
    if (description.length === 0) {
      setError("description vide")
      return;
    }
    if (brevet.length === 0) {
      setError("brevet vide")
      return;
    }
    if (position.length === 0) {
      setError("position vide")
      return;
    }
    if (selectedType === undefined) {
      setError("type vide")
      return;
    }


    let res = await api.addBrevet(selectedType,brevet,description,position);
    if (res.status !== undefined) {
      onUpdate();
      onCancel();
    } else {
      setError('une erreur est apparue sur le serveur');
    }
  }

  const handleChangeTextArea = event => {
    setDescription(event.target.value)
  }

  const handleChangeBrevetArea = event => {
    setBrevet(event.target.value)
  }

  const handleChangeSelect = (type) => {
    setSelectedType(type)
  }

  return (
      <Fragment>
        <div className="fixed inset-0 bg-black opacity-25 "/>
        <Portal>
          <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
          <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
            <div className="md:flex items-center">
              <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                {error &&
                    <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                }
                <p className="font-bold">Ajouter un brevet </p>
                <p className="text-sm text-gray-700 mt-1">
                  Veuillez remplir ce formulaire pour ajouter un brevet.
                </p>
              </div>
            </div>
            <div className=" flex text-center  justify-center mt-3  ">
              <form>
                {
                  //brevet
                }
                <div className="flex ">
                  <p className="mr-2">Brevet:</p>
                  <textarea onChange={handleChangeBrevetArea} defaultValue={""}
                            className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent">
                                </textarea>
                </div>
                <div className="flex space-x-4 ">
                  {
                    //description
                  }
                  <div className="flex ">
                    <p className="mr-2">description:</p>
                    <textarea onChange={handleChangeTextArea} defaultValue={""}
                              className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent">
                                </textarea>
                  </div>
                  {
                    //position
                  }
                  <div>
                    <label className="mb-2 flex">
                      <p className="mr-2">position:</p>
                      <input type="number" min="0"
                             onChange={e => setPosition(e.target.value)}
                             className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                      />
                    </label>


                    <label className="mb-2 flex">
                      <p className="mr-2">type:</p>
                      <Select options={types} isSearchable={false}
                              onChange={(e) => {
                                handleChangeSelect(e.label)
                              }}/>
                    </label>
                  </div>
                </div>

                <div className="flex">
                  <button onClick={e => {
                    handleSubmit(e)
                  }}
                          className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                    Ajouter le brevet
                  </button>
                  <button onClick={() => {
                    onCancel()
                  }}
                          className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                    Fermer
                  </button>
                </div>
              </form>
            </div>

          </div>
        </Portal>
      </Fragment>
  );
};

export default AddBrevetModal;
