import React from 'react';

import {DashboardLayout} from '../../components/Layout/Layout';

const AdminOutdoor = ({right}) => {
    return (<DashboardLayout right={right}>
        <h2 className="absolute -top-8 left-10 font-sans  text-xl">Admin Outdoor</h2>
        <div className=" mt-14 ml-10 mr-10 content-center">

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            </div>
        </div>
    </DashboardLayout>)
}

export default AdminOutdoor;