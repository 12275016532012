import React, {Fragment, useEffect, useState} from "react";
import Portal from "../Portal/Portal";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {getTeamMembers} from "../../services/AccountService";
import {WEEKDAYS} from "../../utils/constants";
import {editCourse} from "../../services/CourseService"

const EditCourseModal = ({onCancel, onUpdate, course, sectionList, locationList,}) => {
    const navigate = useNavigate();
    const [weekDay, setWeekDay] = useState(WEEKDAYS.find(day => day.value === course.week_day));
    const [beginTime, setBeginTime] = useState(course.begin_time.substring(0,course.begin_time.length-3));
    const [endTime, setEndTime] = useState(course.end_time.substring(0,course.end_time.length-3));
    const [maxCapacity, setMaxCapacity] = useState(course.max_capacity);
    const [price, setPrice] = useState(course.price);
    const [beginDate, setBeginDate] = useState(course.begin_date);
    const [endDate, setEndDate] = useState(course.end_date);
    const [reserved, setReserved] = useState(course.reserved);
    const [sectionId, setSectionId] = useState(sectionList.find(section => section.value === course.section_id));
    const [locationId, setLocationId] = useState(locationList.find(location => location.value === course.location_id));
    const [memberList, setMemberList] = useState([])
    const [responsible, setResponsible] = useState();
    const [error, setError] = useState();
    const [outputMessage , setOutputMessage] = useState();

    useEffect(() => {
        async function loader() {
            const members = await getTeamMembers()
            let teamMembers = []
            for (let member of members) {
                //format it for selector before pushing it
                let formattedMember = {
                    value: member.id,
                    label: member.first_name + " " + member.last_name
                }
                if (member.id === course.teacher_id) setResponsible(formattedMember)
                teamMembers.push(formattedMember)
            }

            setMemberList(teamMembers)
        }

        loader()
    }, [])

    const handleSubmit = async (e) =>{
        e.preventDefault()
       let rest = await editCourse(course.id,sectionId.value,responsible.value,locationId.value,weekDay.value,beginTime,endTime,maxCapacity,price,beginDate,endDate,reserved)
        if(rest){
            setOutputMessage('erreur❌')
        }else{
            setOutputMessage('modifié✅')
        }
        onUpdate()
    }


    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            {error &&
                                <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                            }
                            <p className="font-bold">Editer le cour</p>
                        </div>
                    </div>
                    <div className=" flex h-full overflow-y-scroll  text-center  justify-center mt-3 ">
                        <form>
                            {
                                //weekday
                            }
                            <div className="flex flex-wrap ">
                                <p>jour:</p>
                                <Select className="text-black ml-5 mb-2 " value={weekDay} options={WEEKDAYS}
                                        onChange={setWeekDay}/>

                                <div className="form-check form-check-inline ml-3">
                                    <label className="form-check-label inline-block mr-2">Heure de début :</label>
                                    <input type="time" onChange={e => setBeginTime(e.target.value)}
                                           value={beginTime}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                    />
                                </div>
                                <div className="form-check form-check-inline ml-3">
                                    <label className="form-check-label inline-block mr-2">Heure de fin :</label>
                                    <input type="time" onChange={e => setEndTime(e.target.value)}
                                           value={endTime}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                    />
                                </div>
                            </div>
                            {
                                //section location and reservation
                            }
                            <div className="flex flex-wrap ">
                                <div className="form-check form-check-inline ml-3">
                                    <label className="form-check-label inline-block mr-2">section:</label>
                                    <Select className="text-black ml-5 mb-2 " value={sectionId} options={sectionList}
                                            onChange={setSectionId}
                                    />
                                </div>
                                <div className="form-check form-check-inline ml-3">
                                    <label className="form-check-label inline-block mr-2">lieu:</label>
                                    <Select className="text-black ml-5 mb-2 " value={locationId} options={locationList}
                                            onChange={setLocationId}
                                    />
                                </div>
                                <div className="form-check form-check-inline ml-3">
                                    <label className="form-check-label inline-block mr-2">responsable: </label>
                                    <Select className="text-black ml-2 mb-2  " options={memberList} value={responsible}
                                            onChange={setResponsible}
                                    />
                                </div>

                            </div>

                            {
                                // maximal capacity of the course
                            }
                            <div className="flex justify-around flex-wrap ">
                                <div className="flex ">
                                    <label className="form-check-label inline-block mr-2"> capacité maximale :</label>
                                    <input type="number" onChange={e => setMaxCapacity(e.target.value)}
                                           value={maxCapacity}
                                           className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                                </div>
                                {
                                    // prices
                                }
                                <div className="flex ">
                                    <label className="form-check-label inline-block mr-2"> prix :</label>
                                    <input type="number" onChange={e => setPrice(e.target.value)}
                                           value={price}
                                           className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                                </div>

                                <div className="form-check form-check-inline ml-5 ">
                                    <label className="form-check-label inline-block mr-2"> reservé :</label>
                                    <input type="checkbox" onChange={e => {
                                        if (reserved === 0) {
                                            setReserved(1);
                                        } else {
                                            setReserved(0)
                                        }
                                    }}
                                           checked={reserved === 1}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 "/>

                                </div>

                            </div>

                            {
                                // dates
                            }
                            <div className="flex tablet:flex-wrap ">
                                <div className="form-check form-check-inline ml-3 ">
                                    <label className="form-check-label inline-block mr-2"> date début :</label>
                                    <input type="date" onChange={e => setBeginDate(e.target.value)}
                                           value={beginDate}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"/>
                                </div>
                                <div className="form-check form-check-inline ml-3">
                                    <label className="form-check-label inline-block mr-2"> date fin :</label>
                                    <input type="date" onChange={e => setEndDate(e.target.value)}
                                           value={endDate}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"/>
                                </div>
                            </div>
                            <div className="flex">
                                <button
                                    onClick={e => handleSubmit(e) }
                                    className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1  bg-pinkEv text-white ">
                                    modifier le cour
                                </button>
                                <label>{outputMessage}</label>
                                <button onClick={() => {
                                    onCancel()
                                }}
                                        className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                                    fermer
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Portal>
        </Fragment>
    );
};

export default EditCourseModal;
