import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "locations"
})

const getLocationList = async () => {
    try {
        const list = await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}})
        return list.data
    } catch (e) {
        console.error(e);
    }
}

const addLocation = async (name, street, houseNumber ,postalCode, locality ,box) => {
    try {
        const payload = {
            name: name,
            street: street,
            houseNumber: houseNumber,
            box: box,
            postalCode: postalCode,
            locality: locality
        }
        const out = await client.post("/", payload, {headers: {'Authorization': localStorage.getItem('token')}});
        return out;
    } catch (e) {
        console.error(e);
    }
}

const deleteLocation = async (id) => {
    try {
        await client.delete("/"+id, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        return e;
    }
}

const updateLocation = async (id, name, street, houseNumber, postalCode, locality, box) => {
    try {
        const payload = {
            name: name,
            street: street,
            houseNumber: houseNumber,
            box: box,
            postalCode: postalCode,
            locality: locality
        }
        return await client.put("/"+id, payload, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        return e;
    }
}


export {
    getLocationList, addLocation, deleteLocation, updateLocation
}
