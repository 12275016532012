import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "trainingCourses"
})

const stageList = async () => {
    try {
        const stages = await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}});
        return stages.data
    } catch (e) {
        console.error(e);
    }
}
const stageMembreList = async (stageId) => {
    try {
        const stages = await client.get("/" + stageId + "/members", {headers: {'Authorization': localStorage.getItem('token')}})
        return stages.data
    } catch (e) {
        console.error(e);
    }
}

const stageCategories = async () => {
    try {
        const stages = await axios.get(url+"trainingCoursesCategories/", {headers: {'Authorization': localStorage.getItem('token')}});
        return stages.data
    } catch (e) {
        console.error(e);
    }
}
const addStageCategory = async (name) => {
    try {
        const payload = {
            "name": name
        }
        return await axios.post(url+"trainingCoursesCategories/", payload, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e);
    }
}

const getStage = async (id) => {
    try {
        const stage = await client.get("/" + id, {headers: {'Authorization': localStorage.getItem('token')}})
        return stage.data
    } catch (e) {
        console.error(e)
    }
}

const deleteStage = async (id) => {
    try {
        return await client.delete("/" + id, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e)
    }
}

const deleteStageCategory = async (id) => {
    try {
        return await axios.delete(url+"trainingCoursesCategories/"+id, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e)
    }
}

const addStage = async (category,description,maxCapacity,beginDate,endDate,price,priceForTeamMember,type,reserved) => {
    try {
        const payload = {
            categoryId: category,
            description: description,
            maxCapacity: maxCapacity,
            beginDate: beginDate,
            endDate: endDate,
            price: price,
            priceForTeamMember : priceForTeamMember ,
            type:type,
            reserved: reserved,
        }
        return await client.post("/", payload, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e);
    }
}

const editStage = async (trainingCourseId, category, description, maxCapacity, beginDate, endDate, full, price, priceForTeamMember, type, reserved) => {
    try {
        const payload = {
            trainingCourseId: trainingCourseId,
            categoryId: category,
            description: description,
            maxCapacity: maxCapacity,
            beginDate: beginDate,
            endDate: endDate,
            full: full,
            price: price,
            priceForTeamMember: priceForTeamMember,
            type: type,
            reserved: reserved,
        }
        return await client.put("/"+trainingCourseId, payload, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e);
        
    }
}
const manual_inscription_stage = async (accountId, stageId) => {
    try {
        const payload = {
            accountId: accountId,
            trainingCourseId: stageId
        }
        return await axios.post(url+"inscriptions/trainingCourses/manual", payload, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        throw e
    }
}

export {
    stageList,
    stageMembreList,
    getStage,
    editStage,
    addStage,
    stageCategories,
    addStageCategory,
    deleteStage,
    deleteStageCategory,
    manual_inscription_stage
}
