import React, { Fragment, useState } from "react";
import Portal from "../Portal/Portal";
import {
  GENDERS,
  LANG_OPTIONS,
  LEVEL_OPTIONS,
  T_SHIRT_OPTIONS,
} from "../../utils/constants";
import Form from "react-bootstrap/Form";
import {
  updateAccount,
  updateChildAccount,
} from "../../services/AccountService";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const EditAccount = ({ onCancel, onUpdate, account }) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = React.useState(account.first_name);
  const [lastName, setLastName] = React.useState(account.last_name);
  const [gender, setGender] = React.useState(account.gender);
  const [email, setEmail] = React.useState(account.email);
  const [phone, setPhone] = React.useState(account.phone);
  const [birthdate, setBirthdate] = React.useState(account.birth_date);
  const [street, setStreet] = React.useState(account.street);
  const [houseNumber, setHouseNumber] = React.useState(account.house_number);
  const [box, setBox] = React.useState(account.box);
  const [postalCode, setPostalCode] = React.useState(account.postal_code);
  const [locality, setLocality] = React.useState(account.locality);
  const [club, setClub] = React.useState(account.club);
  const [level, setLevel] = React.useState(
    LEVEL_OPTIONS.find((o) => o.value === account.level)
  );
  const [tshirt, setTshirt] = React.useState(
    T_SHIRT_OPTIONS.find((shirt) => shirt.value === account.t_shirt)
  );
  const [language, setLanguage] = React.useState(
    LANG_OPTIONS.find((o) => o.value === account.lang)
  );
  const [outputMessage, setOutputMessage] = useState();

  const handleEditAccount = async (e) => {
    e.preventDefault();
    try {
      if (account.active) {
        const response = await updateAccount(
          account.id,
          firstName,
          lastName,
          phone,
          email,
          street,
          houseNumber,
          box,
          postalCode,
          locality,
          birthdate,
          gender,
          level.value,
          tshirt.value,
          language.value,
          account.password
        );
        if (response === "ERR_NETWORK") {
          navigate("/server_maintenance");
        }
        setOutputMessage(
          "modifié✅ les modifications seront visible à la fermeture"
        );
        onUpdate();
      } else {
        const response = await updateChildAccount(
          account.parentId,
          account.id,
          firstName,
          lastName,
          street,
          houseNumber,
          box,
          postalCode,
          locality,
          birthdate,
          gender,
          level.value,
          tshirt.value,
          language.value
        );
        if (response === "ERR_NETWORK") {
          navigate("/server_maintenance");
        }
        if (response)
          setOutputMessage(
            "modifié✅ les modifications seront visible à la fermeture"
          );
        onUpdate();
      }
    } catch (e) {
      console.error(e);
      setOutputMessage("erreur❌");
    }
  };

  const handleLevelChange = (level) => {
    setLevel(level);
  };

  const handleLangChange = (language) => {
    setLanguage(language);
  };

  const handlChangeTshirt = (tshirt) => {
    setTshirt(tshirt);
  };

  return (
    <Fragment>
      <div className="fixed inset-0 bg-black opacity-25 " />
      <Portal>
        <div
          onClick={onCancel}
          className="bg-black opacity-50  absolute z-10 inset-0"
        />
        <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
          <div className=" flex text-center  justify-center mt-3 border-dark border-2 p-5 rounded ">
            <form>
              <div>
                <div className="flex flex-wrap">
                  <p className="font-bold">Prénom:</p>
                  <input
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                  />
                  <p className="font-bold">Nom:</p>
                  <input
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                  />
                  <div className="flex justify-center">
                    <p className="mr-2">Sexe:</p>
                    {GENDERS.map((gen, i) => (
                      <div key={i} className="form-check form-check-inline">
                        <Form.Check
                          inline
                          label={gen}
                          name="inlineRadioOptions"
                          type="radio"
                          id="inlineRadio2"
                          value={gen}
                          checked={gender === gen}
                          onChange={(e) => setGender(e.target.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-wrap">
                  {account.active && (
                    <>
                      <p className="font-bold">Email:</p>
                      <input
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                      />
                      <p className="font-bold">Téléphone:</p>
                      <input
                        type="number"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                      />
                    </>
                  )}
                  <p className="font-bold">Date de naissance:</p>
                  <input
                    type="date"
                    onChange={(e) => setBirthdate(e.target.value)}
                    value={birthdate}
                    className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                  />
                </div>
                <div className="flex flex-wrap">
                  <p className="font-bold">Adresse:</p>
                  <p className="font-bold ml-2">rue:</p>
                  <input
                    type="text"
                    onChange={(e) => setStreet(e.target.value)}
                    value={street}
                    className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                  />
                  <p className="font-bold ml-2">numero:</p>
                  <input
                    type="text"
                    onChange={(e) => setHouseNumber(e.target.value)}
                    value={houseNumber}
                    className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                  />
                  <p className="font-bold ml-2">boite:</p>
                  <input
                    type="text"
                    onChange={(e) => setBox(e.target.value)}
                    value={box}
                    className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                  />
                  <p className="font-bold ml-2">postal code:</p>
                  <input
                    type="text"
                    onChange={(e) => setPostalCode(e.target.value)}
                    value={postalCode}
                    className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                  />
                  <p className="font-bold ml-2">localité:</p>
                  <input
                    type="text"
                    onChange={(e) => setLocality(e.target.value)}
                    value={locality}
                    className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                  />
                </div>
                <div className="flex flex-wrap">
                  <p className="font-bold">Club:</p>
                  <input
                    type="text"
                    onChange={(e) => setClub(e.target.value)}
                    value={club}
                    className="shadow bg-transparent border  rounded  py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                  />

                  <p className="font-bold">Niveau:</p>
                  <Select
                    className="text-black ml-5 "
                    defaultValue={level}
                    options={LEVEL_OPTIONS}
                    onChange={handleLevelChange}
                  />
                  <p className="font-bold">T-shirt:</p>
                  <Select
                    className="text-black ml-5 "
                    defaultValue={tshirt}
                    options={T_SHIRT_OPTIONS}
                    onChange={handlChangeTshirt}
                  />
                </div>
                <div className="flex flex-wrap">
                  <p className="font-bold">Langue:</p>
                  <Select
                    className="text-black ml-5 "
                    defaultValue={language}
                    options={LANG_OPTIONS}
                    onChange={handleLangChange}
                  />
                </div>
                <div className="flex">
                  <button
                    onClick={(e) => {
                      handleEditAccount(e);
                    }}
                    className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white "
                  >
                    editer
                  </button>
                  {outputMessage && (
                    <p className="rounded bg-pinkEv-800 m-3 p-2 shadow-lg ">
                      {outputMessage}
                    </p>
                  )}
                  <button
                    onClick={() => {
                      onCancel();
                    }}
                    className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black "
                  >
                    fermer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Portal>
    </Fragment>
  );
};

export default EditAccount;
