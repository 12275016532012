import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
  baseURL: url + "brevets"
})

const getBrevetsList = async () => {
  try {
    const brevets = await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}})
    return brevets.data
  } catch (e) {
    console.error(e)
  }
}

const getBrevet = async (id) => {
  try {
    const brevet = await client.get("/" + id, {headers: {'Authorization': localStorage.getItem('token')}})
    return brevet.data
  } catch (e) {
    console.error(e)
  }
}

const addBrevet = async (type,brevet,description,position) => {
  try {
    const payload = {
      type: type,
      brevet: brevet,
      description: description,
      position: position,
    }
    return await client.post("/", payload, {headers: {'Authorization': localStorage.getItem('token')}})
  } catch (e) {
    console.error(e);
  }
}

const getBrevetsAssignedList = async () => {
  try {
    const brevetsAssigned = await client.get("/assigned", {headers: {'Authorization': localStorage.getItem('token')}})
    return brevetsAssigned.data
  } catch (e) {
    console.error(e)
  }
}

const assignBrevet = async (brevet_id,account_id) => {
  try {
    const payload = {
      brevetId: brevet_id,
      accountId: account_id
    }
    return await client.post("/assigned", payload, {headers: {'Authorization': localStorage.getItem('token')}})
  } catch (e) {
    console.error(e);
  }
}

const removeAssignedBrevet = async (brevet_id,account_id) => {
  try {
    return await client.delete("/assigned/"+brevet_id+"/"+account_id, {headers: {'Authorization': localStorage.getItem('token')}})
  } catch (e) {
    console.error(e);
  }
}

const getAllBrevetAssignedByBrevetId = async (id) => {
  try {
    const brevetsAssigned = await client.get("/assigned/" + id, {headers: {'Authorization': localStorage.getItem('token')}})
    return brevetsAssigned.data
  } catch (e) {
    console.error(e)
  }
}

const deleteBrevet = async (brevetId) => {
  try {
    await client.delete("/" + brevetId, {headers: {'Authorization': localStorage.getItem('token')}})
  } catch (e) {
    console.error(e);
  }
}

const editBrevet = async (id,type,brevet,description,position) => {
  const payload = {
    type: type,
    brevet: brevet,
    description: description,
    position: position,
  }
  try {
    await client.put("/"+id, payload, {headers: {'Authorization': localStorage.getItem('token')}})
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export {
  getBrevet,getBrevetsList,addBrevet,getBrevetsAssignedList,assignBrevet,removeAssignedBrevet,getAllBrevetAssignedByBrevetId,deleteBrevet,editBrevet
}
