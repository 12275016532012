import React, {useEffect, useState} from 'react';
import {change_course} from "../../services/InscriptionCoursesService";

const ChangeCourseModal = ({currentInscription, currentCourse, coursesList, onClose, onUpdate, setCourseChangeMessage }) => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [outputMessage , setOutputMessage] = useState();

  useEffect(() => {
    async function loader(){
      const course = coursesList.find(course => course.id === currentCourse.id);
      if (course) {
        currentCourse.section = course.section;
      }
    }
    loader();
  }, []);
  const handleCourseChange = (e) => {
    const course = coursesList.find(course => course.id === parseInt(e.target.value));
    setSelectedCourse(course);
  };

  const calculatePriceDifference = () => {
    if (!selectedCourse) return 0;
    return selectedCourse.price - currentCourse.price;
  };

  const priceDifference = calculatePriceDifference();
  let priceDifferenceColor = 'black';
  if (priceDifference > 0) {
    priceDifferenceColor = 'red';
  } else if (priceDifference < 0) {
    priceDifferenceColor = 'green';
  }

  const handleSubmit = async (e) => {
    try{
      await change_course(currentInscription,selectedCourse.id)
      onUpdate();
      setCourseChangeMessage("Inscription transférée avec succès");
      onClose();
    }catch (e) {
      setOutputMessage('erreur❌')
      setCourseChangeMessage("Un problème est survenu lors du transfert");
      console.log(e);
    }
  };

  return (
      <div className="mfa-popup fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
        <div className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-md w-full relative">
          <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-300"
              onClick={onClose}
          >
            <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
              <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h2 className="text-2xl font-semibold mb-6 text-center text-gray-100">Changer de cours</h2>
          <div className="flex justify-between items-start">
            <div className="w-1/2">
              <h3 className="text-xl font-bold text-gray-100">Cours actuel</h3>
              <p className="text-lg mt-2 text-gray-400">Référence: {currentCourse.reference}</p>
              <p className="text-lg mt-2 text-gray-400">Jour: {currentCourse.week_day}</p>
              <p className="text-lg mt-2 text-gray-400">Heure: {currentCourse.begin_time} - {currentCourse.end_time}</p>
              <p className="text-lg mt-2 text-gray-400">Section: {currentCourse.section?.description}</p>
              <p className="text-lg mt-2 text-gray-400">Prix: {currentCourse.price}€</p>
            </div>
            <div className="w-1/2">
              <select
                  className="border border-gray-700 p-2 rounded-lg w-full bg-gray-800 text-gray-100"
                  onChange={handleCourseChange}
              >
                <option value="">Choisir le nouveau cours</option>
                {coursesList
                .filter(course=>course.id!==currentCourse.id)
                .map(course => (
                    <option key={course.id} value={course.id} className="bg-gray-900 text-gray-100">
                      {course.week_day} pour {course.section?.description}
                    </option>
                ))}
              </select>
              {selectedCourse && (
                  <div className="mt-4">
                    <p className="text-lg mt-2 text-gray-400">Référence: {selectedCourse.reference}</p>
                    <p className="text-lg mt-2 text-gray-400">Jour: {selectedCourse.week_day}</p>
                    <p className="text-lg mt-2 text-gray-400">Heure: {selectedCourse.begin_time} - {selectedCourse.end_time}</p>
                    <p className="text-lg mt-2 text-gray-400">Section: {selectedCourse.section?.description}</p>
                    <p className="text-lg mt-2 text-gray-400">Prix: {selectedCourse.price}€</p>
                  </div>
              )}
            </div>
          </div>
          <div className="mt-4 text-center">
            <p className={`text-lg font-bold ${priceDifferenceColor}`}>
              Différence de prix: {Math.abs(priceDifference)}€
            </p>
          </div>
          <div className="mt-6">
            <button
                className="bg-red-500 text-white py-2 px-4 rounded-lg w-full hover:bg-red-600 transition duration-200"
                onClick={handleSubmit}
            >
              Valider
            </button>
            <label>{outputMessage}</label>
          </div>
        </div>
      </div>
  );
};

export default ChangeCourseModal;
