import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthenticationContext from "../../contexts/AuthenticationContext";
import {DashboardLayout} from "../../components/Layout/Layout";
import {
  getInscriptionDates,
  verifyCanRegister
} from "../../services/InscriptionDatesService";
import {utcToZonedTimeFormated} from "../../utils/timeUtils";

const Inscriptions = () => {
  const [canRegister, setCanRegister] = useState(0);
  const [oldMemberDate, setOldMemberDate] = useState("");
  const [newMemberDate, setNewMemberDate] = useState("");
    const {right} = useContext(AuthenticationContext);
    let navigate = useNavigate();
    useEffect(() => {
    async function loader() {
      const valueRegister= await verifyCanRegister();
      setCanRegister(valueRegister);
      const now= new Date();
      const inscriptionDates = await getInscriptionDates(now.getFullYear());
      setOldMemberDate(formatDate(inscriptionDates.old_member_date));
      setNewMemberDate(formatDate(inscriptionDates.new_member_date));
      }
      loader()
    }, [])

  const formatDate = (date)=>{
    if(date===null)return "à une date non déterminée";
    const time = date.split('T')[1]
    if (time) {
      const timeZone = "Europe/Brussels";
      date = utcToZonedTimeFormated(date,timeZone);
    }
    return "le " + date;
  }

    return (
        <DashboardLayout>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Inscriptions</h2>
          {canRegister === 2 ? //404 dates non déterminées
              <div
                  className="flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><p>Les dates d'inscriptions n'ont pas encore été déterminées</p></div>
              : canRegister === 3 ? //403 dates pas encore arrivées
                  <div
                      className="flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><p>Les inscriptions pour les anciens membres ouvriront {oldMemberDate} et les inscriptions pour les nouveaux membres ouvriront {newMemberDate}</p> </div>
                  : canRegister === 1 && //200 peut s'inscrire
                  <div
                      className="flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <button onClick={() => {
                      navigate(`/${right}/inscriptions/indoor`)
                    }}
                            className='m-3 p-3 border-1 rounded bg-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1 '>Indoor
                    </button>
                    <button
                        onClick={() => {
                          navigate(`/${right}/inscriptions/outdoor`)
                        }}
                        className='m-3 p-3 border-1 rounded bg-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1 '>Outdoor
                    </button>
                    {
                      // <button
                      //    className='m-3 p-3 border-1 rounded bg-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1 '> competition
                      // </button>
                    }
                  </div>
          }
        </DashboardLayout>
    )
}

export default Inscriptions;
