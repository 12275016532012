import React, {Fragment, useState} from "react";

import Portal from "../Portal/Portal";
import {editCotisation} from "../../services/CotisationService";

const EditCotisationModal = ({onCancel, onUpdate, id, name}) => {

    const [price, setPrice] = useState();
    const [error, setError] = useState();

    console.log(name)

    const handleSubmit = async e => {
        e.preventDefault();
        if ( !price ) {
            setError(' Veuillez completer tout les champs ');
            return;
        }

        let res = await editCotisation(id, name, price)
        if (res.status !== undefined) {
            onUpdate();
            onCancel();
        } else {
            setError('une erreur est apparue sur le serveur');
        }
    }


    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            {error &&
                                <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                            }
                            <p className="font-bold">Editer une cotisation </p>
                        </div>
                    </div>
                    <div className=" flex text-center  justify-center mt-3  ">
                        <form>
                            {
                                //price
                            }
                            <label className="mb-2 flex">
                                <p> prix :</p>
                                <input type="number" onChange={e => setPrice(e.target.value)}
                                       className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>

                            </label>


                            <div className="flex">
                                <button onClick={e => {
                                    handleSubmit(e)
                                }}
                                        className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                                    éditer
                                </button>
                                <button onClick={() => {
                                    onCancel()
                                }}
                                        className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                                    fermer
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Portal>
        </Fragment>
    );
};

export default EditCotisationModal;
