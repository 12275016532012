import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "orders/"
})


const getOrdersList = async () => {
    try {
        const list = await client.get("current/", {headers: {'Authorization': localStorage.getItem('token')}})
        return list
    } catch (e) {
        if (e.response.status !== 500) {
            console.error(e)
        }
    }
}

const getInscriptionsFromOrder = async (orderId) => {
    try {
        const list = await client.get(orderId+"/detail", {headers: {'Authorization': localStorage.getItem('token')}})
        return list.data
    } catch (e) {
        if (e.response.status !== 500) {
            console.error(e)
        }
    }
    return null;
}

const deleteOrder = async (orderId) => {
    try {
        await client.delete("/" + orderId, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e);
    }
}


export {
    getOrdersList,
    deleteOrder,
    getInscriptionsFromOrder
}