import React from 'react';
import {ProviderWrapper as AccountProviderWrapper} from '../../contexts/AuthenticationContext';
import {ProviderWrapper as CarProviderWrapper} from "../../contexts/CartContext";
import App from './App';


const AppLoader = () => {
    return (
        <AccountProviderWrapper>
            <CarProviderWrapper>
                <App/>
            </CarProviderWrapper>
        </AccountProviderWrapper>
    );
}

export default AppLoader;
