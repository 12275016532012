import React from 'react';

import {DashboardLayout} from '../../components/Layout/Layout';

const MembersPage = ({right}) => {
    return (
        <DashboardLayout right={right}>
            <h2>Members Page</h2>
        </DashboardLayout>
    )
}

export default MembersPage;
