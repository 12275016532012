import React from 'react';
import {DashboardLayout} from "../../components/Layout/Layout";

const AdminPanelPage = () => {
    return (
        <DashboardLayout>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Admin Panel</h2>
            <div className=" ">


            </div>
        </DashboardLayout>
    )
}

export default AdminPanelPage;
