import React, {useState} from "react";
import {RIGHT, TOKEN} from "../utils/constants";
import {jwtDecode} from "jwt-decode";


const Context = React.createContext(null);

const ProviderWrapper = (props) => {
    const getToken = () => {
        return localStorage.getItem(TOKEN);
    }

    const getRight = () => {
        return localStorage.getItem(RIGHT);
    }
    //const [status,setStatus]=useState(); // this is the status of the user

    const [token, setToken] = useState(getToken); // this is the hook that handle the user token
    const [right, setRight] = useState(getRight);

    const authenticate = (token, right) => {
        setToken(token);
        setRight(right);
    }

    const disconnect = () => {
        localStorage.clear();
        setToken(null);
        setRight(null);
    }

    const exposedValue = {
        token, right, authenticate, disconnect
    }

    return (
        <Context.Provider value={exposedValue}>{props.children}</Context.Provider>
    );
};

export {Context, ProviderWrapper};
export default Context;
