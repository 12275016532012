import React, {useEffect, useState} from 'react';
import {DashboardLayout} from "../../components/Layout/Layout";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {MDBDataTable} from 'mdbreact';
import {ifExpiredDisconnect} from "../../utils/toolbox";
import {
  deleteBrevet, getBrevet,
  getBrevetsAssignedList,
  getBrevetsList, removeAssignedBrevet
} from "../../services/BrevetService";
import AddBrevetModal from "../../components/Modals/AddBrevetModal";
import {
  getAllAccountsNames
} from "../../services/AccountService";
import AssignBrevetModal from "../../components/Modals/AssignBrevetModal";
import EditBrevetModal from "../../components/Modals/EditBrevetModal";


const AdminBrevet = () => {
  const [brevetModal, setBrevetModal] = useState(false);
  const [AssignationModal, setAssignationModal] = useState(false);
  const [editBrevetModal, setEditBrevetModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [brevetsList, setBrevetsList] = useState([]);
  const [brevetsAssignedList,setBrevetsAssignedList] = useState([])
  const [showBrevetsAssignedList,setShowBrevetsAssignedList] = useState(false)
  const [selectedBrevetToEdit, setSelectedBrevetToEdit] = useState();

  //use effect to fetch all stages
  useEffect(() => {
    ifExpiredDisconnect()
    async function loader() {
      const brevets = await getBrevetsList();
      const accounts = await getAllAccountsNames();
      const assignedBrevets = await getBrevetsAssignedList();
      if (brevets !== undefined) {
        setBrevetsList(formatData(brevets))
      } else {
        setBrevetsList([])
      }

      if(assignedBrevets!==undefined){
        assignedBrevets.forEach(assigned => {
          brevets.forEach(brev => {
            if (assigned.brevet_id === brev.id) {
              assigned.brevet = brev.brevet;
              assigned.position = brev.position;
            }
          })
          accounts.forEach(acc=>{
            if(assigned.account_id===acc.id){
              assigned.first_name=acc.first_name;
              assigned.last_name=acc.last_name;
            }
          })
        })
        setBrevetsAssignedList(formatAssigned(assignedBrevets));
      }else{
        setBrevetsAssignedList([]);
      }

    }

    loader()
  }, [update])


  const onUpdate = () => {
    setUpdate(!update)
  }
  const onCancelBrevetModal = () => {
    setBrevetModal(false);
  }

  const onCancelAssignationModal = () => {
    setAssignationModal(false);
  }

  const onDeleteBrevet = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce brevet ?")) {
      //delete the brevet
      await deleteBrevet(id)
      onUpdate()
    }
  }

  const onDeleteAssigned = async (brevetId, accountId)=>{
    if (window.confirm("Êtes-vous sûr de vouloir retirer ce brevet ?")) {
      //remove the brevet from the account
      await removeAssignedBrevet(brevetId,accountId);
      onUpdate()
    }
  }

  const onEditBrevet = async (id) => {
    const brevet = await getBrevet(id);
    setEditBrevetModal(!editBrevetModal)
    setSelectedBrevetToEdit(brevet)
    setUpdate(!update);
  }

  const onCancelEditBrevetModal = () => {
    setEditBrevetModal(false);
  }


  const formatData = (data) => {
    if (data !== undefined) {
      data.forEach(brevet => {
        brevet.modify = <span> <button className="border rounded p-1 border-white text-white m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-white hover:text-black"
                                       onClick={() => onEditBrevet(brevet.id)}>Modifier</button>
                    </span>
        brevet.delete = <span> <button className="btn btn-danger p-1 inline-flex font-semibold  "
                                      onClick={() => onDeleteBrevet(brevet.id)}>Supprimer</button>
                    </span>
      })
      const sortedData = data.slice().sort((a, b) => {
        if (a.brevet !== b.brevet) {
          return a.brevet.localeCompare(b.brevet);
        }
        return a.position - b.position;
      });

      let table = {
        columns: [
          {
            label: 'brevet',
            field: 'brevet',
            sort: 'asc',
            width: '12%'
          },
          {
            label: 'position',
            field: 'position',
            width: '12%'
          },
          {
            label: 'description',
            field: 'description',
            width: '54%'
          },
          {
            label: 'type',
            field: 'type',
            sort: 'asc',
            width: '12%'
          },
          {
            label:'modifier',
            field: 'modify'
          },
          {
            label: 'supprimer',
            field: 'delete',
          }
        ],
        rows: sortedData
      }
      return table
    }
  }

  const formatAssigned = (data)=> {
    if (data !== undefined) {
      data.forEach(brevet => {
        brevet.delete = <span> <button className="btn btn-danger p-1 inline-flex font-semibold  "
                                       onClick={() => onDeleteAssigned(brevet.brevet_id,brevet.account_id)}>Supprimer</button>
                    </span>
      })
      const sortedData = data.slice().sort((a, b) => {
        if(a.last_name!==b.last_name){
          return a.brevet.localeCompare(b.brevet);
        }
        if(a.first_name!==b.first_name){
          return a.brevet.localeCompare(b.brevet);
        }
        if(a.brevet !== b.brevet) {
          return a.brevet.localeCompare(b.brevet);
        }
        return a.position - b.position;
      });

      let table = {
        columns: [
          {
            label: 'Nom',
            field: 'last_name',
            sort: 'asc',
          },
          {
            label: 'Prénom',
            field: 'first_name',
            sort: 'asc'
          },
          {
            label: 'Brevet',
            field: 'brevet',
            sort: 'asc'
          },
          {
            label: 'Position',
            field: 'position',
            sort: 'asc',
          },
          {
            label: 'supprimer',
            field: 'delete',
          }
        ],
        rows: sortedData
      }
      return table
    }
  }
  return (
      <DashboardLayout>
        <h2 className="absolute -top-8 left-10 font-sans  text-xl">Admin Brevets</h2>
        <div className=" mt-14 ml-10 mr-10 content-center">
          <button
              onClick={() => {
                setBrevetModal(true)
              }}
              className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
            Ajouter un brevet
          </button>
          <button
              onClick={() => {
                setAssignationModal(true)
              }}
              className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
            Assigner un brevet
          </button>
          {showBrevetsAssignedList === false ?
              <button
                  onClick={() => {
                    setShowBrevetsAssignedList(!showBrevetsAssignedList)
                  }}
                  className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                Afficher la liste des brevets assignés
              </button>
              :
              <button
                  onClick={() => {
                    setShowBrevetsAssignedList(!showBrevetsAssignedList)
                  }}
                  className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                Cacher la liste des brevets assignés
              </button>
          }
          {showBrevetsAssignedList &&
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <MDBDataTable theadTextWhite={true} tbodyTextWhite={true} striped bordered sortable
                              data={brevetsAssignedList} noBottomColumns={true} searching={true}/>
              </div>
          }
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <MDBDataTable theadTextWhite={true} tbodyTextWhite={true} striped bordered sortable
                          data={brevetsList} noBottomColumns={true} searching={true}/>
          </div>
        </div>
        {brevetModal &&
            <AddBrevetModal onCancel={onCancelBrevetModal} onUpdate={onUpdate}/>
        }
        {AssignationModal &&
            <AssignBrevetModal onCancel={onCancelAssignationModal} onUpdate={onUpdate}/>

        }
        {editBrevetModal &&
            <EditBrevetModal onCancel={onCancelEditBrevetModal} onUpdate={onUpdate}
                             brevetSelected={selectedBrevetToEdit}/>
        }
      </DashboardLayout>
  )
}

export default AdminBrevet;
