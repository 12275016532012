import React, {useContext, useEffect, useState} from "react";
import * as userApi from "../../../services/AccountService";
import * as stageApi from "../../../services/StageService"
import SelectorUser from "../../../components/InscriptionsComponents/StageForms/SelectorUser";
import * as apiInscription from "../../../services/InscriptionService";
import CartContext from "../../../contexts/CartContext";
import {ifExpiredDisconnect} from "../../../utils/toolbox";

const Stages = ({retour}) => {
    const [selectedAccount, setSelectedAccount] = useState();
    const [childList, setChildList] = useState([]);
    const [stageList, setStageList] = useState([])
    const [selectedStage, setSelectedStage] = useState()
    const [indoorStageList, setIndoorStageList] = useState([])
    const [selectedIndoorStageList, setSelectedIndoorStageList] = useState([])
    const [account, setAccount] = useState([]);
    const [childAndAccountSelect, setChildAndAccountSelect] = useState([]);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState();
    const [outputMessage, setOutputMessage] = useState();
    const {updateCart} = useContext(CartContext);
    useEffect(() => {
        let tab = []
        ifExpiredDisconnect()
        const children = async () => {
            let children = await userApi.getCurrentUserChildrens()
            let account = await userApi.getCurrentAccount()
            setChildList(children);
            setAccount(account);
            children.forEach(child => {
                tab.push({value: child.id, label: child.first_name + " " + child.last_name})
            })
            tab.push({value: account.id, label: account.first_name + " " + account.last_name})
            setChildAndAccountSelect(tab);
        }

        const stages = async () => {
            let stages = await stageApi.stageList()
            setStageList(stages)
            let indoorStages = stages.filter(stage => stage.type === "indoor" && stage.reserved === 'false')
            setIndoorStageList(indoorStages)
            let selectedIndoorStageList = indoorStages.map(stage => {
                return {value: "" + stage.id, label: stage.description}
            })
            setSelectedIndoorStageList(selectedIndoorStageList)
        }
        stages();
        children();
    }, [update])

    const onUpdate = () => {
        setUpdate(!update)
    }

    const handleSelectedStage = (stage) => {
        setSelectedStage(stage)
        onUpdate()
    }
    const handleAddToCart = async (stageID) => {
        try {
            //add the course to the cart
            const res = await apiInscription.inscription_stage(stageID, selectedAccount);
            setOutputMessage('ajouté au panier✅')
            onUpdate();
            updateCart();
        } catch (e) {
            if (e.response.data.message === "Cannot register account to the course, order is in payment process"){
                setError("Vous avez un payment en cours,\n merci de le finaliser ou annuler avant de commencer un autre panier")
            }else{
                setError(e.response.data.message)
            }
        }

    }

    return (
        <>
            {stageList.length > 0 ?
                <div>
                    <SelectorUser
                        selectedAccount={selectedAccount}
                        handleChildSelect={setSelectedAccount}
                        userList={childAndAccountSelect}
                        onUpdate={onUpdate}
                        selectedIndoorStageList={selectedIndoorStageList}
                        selectedStage={selectedStage}
                        handleSelectedStage={handleSelectedStage}
                        stageList={stageList}
                        handleAddToCart={handleAddToCart}
                        error={error}
                        outputMessage={outputMessage}
                    />
                </div>
                :
                <div className="flex flex-col items-center justify-center ">
                    <div className=" h-max ">
                        <h1 className="text-2xl font-bold text-red-600 m-5">Aucuns
                            stage
                            disponible actuellement </h1>
                    </div>
                </div>
            }
        </>

    )

}
export default Stages;