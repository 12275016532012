import React, {useEffect, useMemo, useState} from "react";
import {ifExpiredDisconnect} from "../../utils/toolbox";
import * as apiStage from "../../services/StageService";
import {DataTableTheme} from "../../components/DataTableComponents/DataTableTheme";
import FilterComponent from "../../components/DataTableComponents/FilterComponent";
import {DashboardLayout} from "../../components/Layout/Layout";
import DataTable from "react-data-table-component";
import {BarLoader} from "react-spinners";
import ShowStudentsStageModal from "../../components/Modals/ShowStudentsStageModal";

const  TeamStageList = ({right}) =>{
    const [update, setUpdate] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [stageList, setStageList] = useState([]);
    const [pending, setPending] = React.useState(true);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [showStudentModal, setShowStudentModal] = useState(false);
    const [selectedCourseToShowDetails, setSelectedCourseToShowDetails] = useState();

    useEffect(() => {
        ifExpiredDisconnect()
        const getdatas = async () => {
            const output = await Promise.all([apiStage.stageList(),apiStage.stageCategories()])
            //for each training_course replace the category_id by the category name
            output[0].forEach(stage => {
                let category = output[1].find(category => category.id === stage.category_id)
                stage.category_id = category.name
                //format date into format dd/mm/yy because it's currently yyyy/mm/ddThh:mm:ss
                stage.begin_date = stage.begin_date.substring(0, 10).split('-').reverse().join('/')
                stage.end_date = stage.end_date.substring(0, 10).split('-').reverse().join('/')
            });
            setStageList(output[0]);
            // category
            setCategoryList(output[1]);
        }
        getdatas();
        setPending(false);

    }, [update]);


    const onUpdate = () => {
        setUpdate(!update)
    }

    const handleShowStudentModal = () => {
        setShowStudentModal(!showStudentModal)
    }
    const handleSetShowDetailsCourse = row => {
        setSelectedCourseToShowDetails(row.id)
        setShowStudentModal(!showStudentModal)
    }

//           DATATABLE

    const formatData = (data) => {
        let table = {
            columns: [
                {
                    name: 'description',
                    selector: row => row.description ,
                    sortable: true,
                    grow:2
                },
                {
                    name: 'date-debut',
                    selector: row => row.begin_date,
                    sortable: true,
                },
                {
                    name: 'date-fin',
                    selector: row => row.end_date,
                    sortable: true,
                },
                {
                    name: 'places',
                    selector: row => row.max_capacity,
                    sortable: true,
                },
                {
                    name: 'prix',
                    selector: row => row.price,
                    sortable: true,
                },
                {
                    name: 'prix-membre',
                    selector: row => row.price_for_team_member,
                    sortable: true,
                },
                {
                    name: 'type',
                    selector: row => row.type,
                    sortable: true,
                },
                {
                    name: 'categorie',
                    selector: row => row.category_id,
                }

            ],
            rows: data
        }
        return table
    }

    DataTableTheme()
    const filteredItems = stageList.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <DashboardLayout right={right}>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Stage List</h2>
            <div className=" h-screen">
                <div className=" mt-14 ml-10 mr-10 content-center h-full overflow-auto">
                    <div className=" overflow-auto shadow-md sm:rounded-lg h-full ">
                        <DataTable columns={formatData(stageList).columns}
                                   data={filteredItems}
                                   showGridlines
                                   theme="TableEV"
                                   pagination
                                   highlightOnHover
                                   subHeader
                                   progressPending={pending}
                                   progressComponent={<BarLoader color="#e44c65" size='100'/>}
                                   onRowClicked={handleSetShowDetailsCourse}
                                   subHeaderComponent={subHeaderComponent}
                        />
                    </div>
                </div>
            </div>
            {showStudentModal && <ShowStudentsStageModal selectedStage={selectedCourseToShowDetails} onCancel={handleShowStudentModal}/>}

        </DashboardLayout>
    )
}
export default TeamStageList