import { LuInfo } from "react-icons/lu";
import { useState } from "react"; // Assurez-vous d'installer react-icons si ce n'est pas déjà fait

const InfoIconWithTooltipRight = ({ info }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="hidden desktop:flex desktop:relative">
      <LuInfo
        className="text-lg cursor-pointer absolute bottom-2"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />
      {showTooltip && (
        <div className="absolute bottom-full right-2 p-2 min-w-[250px] bg-[#3c3f53]  rounded">
          <p className="text-sm text-gray-200 flex">{info}</p>
        </div>
      )}
    </div>
  );
};

export default InfoIconWithTooltipRight;
