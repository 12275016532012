import {createTheme} from "react-data-table-component";

export const DataTableTheme = ()=> {
   return  createTheme(
        'TableEV',
        {
            text: {
                primary: '#ffffff',
                secondary: '#2aa198',
            },
            background: {
                default: '#1c1d26',
            },
            context: {
                background: "#1c1d26",
                text: '#FFFFFF',
            },
            divider: {
                default: '#f4f7f8',
            },
            button: {
                default: '#2aa198',
                hover: 'rgba(0,0,0,.08)',
                focus: 'rgba(255,255,255,.12)',
                disabled: 'rgba(255, 255, 255, .34)',
            },
            sortFocus: {
                default: '#2aa198',
            },

        },
        'dark',
    );
}