import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "payments"
})

const payment = async () => {
    try {
        const data = {"paymentMethodId": 2}
        const res = await client.post("", data, {headers: {'Authorization': localStorage.getItem('token')}})
        //make a post request to /api.ev-club/stripe/checkout-session?sessionId=+external_payment_id so the base url is /api.ev-club/stripe/
        if (res.data.external_payment_id !== null) {
            const link = await axios.get(url + "stripe/checkout-session?sessionId=" + res.data.external_payment_id, {headers: {'Authorization': localStorage.getItem('token')}})
            //redirect to the link if the link is not null
            if (link.data !== null) {
                //redirect to the link in another tab
                window.open(link.data, "_blank");
                //redirect to the link in the same tab
                window.location.replace(link.data);
            }
        } else {
            console.error("external_payment_id is null when trying to make a payment")
        }
    } catch (e) {
        console.error(e);
    }
}


const getSessionId = async () => {
    try {
        const res = await client.get("current",{headers: {'Authorization': localStorage.getItem('token')}})
        return res.data.external_payment_id;
    } catch (e) {
        throw e;
    }
}

const abortPayment = async () => {
    try {
        const sessionID= await getSessionId()
        if (sessionID) {
            const res = await axios.delete(url + "stripe/checkout-session?sessionId="+sessionID, {headers: {'Authorization': localStorage.getItem('token')}})
            return res;
        }
    } catch (e) {
        throw e;
    }
}

export {
    payment,abortPayment
}