import React, {useContext, useEffect, useState} from "react";
import * as api from "../../../services/AccountService";
import SelectorUser from "../../../components/InscriptionsComponents/SubscriptionsForms/SelectorUser";
import CartContext from "../../../contexts/CartContext";
import * as apiInscription from "../../../services/InscriptionService";
import * as sub from "../../../services/SubscriptionService";
import {subscription_list} from "../../../services/SubscriptionService";
import {useNavigate} from "react-router-dom";
import {ifExpiredDisconnect} from "../../../utils/toolbox";

const Subscriptions = ({retour}) => {
    const navigate = useNavigate();
    const [selectedAccount, setSelectedAccount] = useState();
    const [childList, setChildList] = useState([]);
    const [account, setAccount] = useState([]);
    const [childAndAccountSelect, setChildAndAccountSelect] = useState([]);
    const [startSubscriptionDate, setStartSubscriptionDate] = useState();
    const [endSubscriptionDate, setEndSubscriptionDate] = useState();
    const [duration, setDuration] = useState();
    const [selectedSubscription, setSelectedSubscription] = useState(null)
    const [subscriptionList, setSubscriptionList] = useState([])
    const [price, setPrice] = useState();
    const [update, setUpdate] = useState(false);
    const [outputMessage , setOutputMessage] = useState();
    const {updateCart} = useContext(CartContext);

    const [error, setError] = useState();

    useEffect(() => {
        let tab = []
        ifExpiredDisconnect()
        const children = async () => {
            try {
                let children = await api.getCurrentUserChildrens()
                let account = await api.getCurrentAccount()
                setChildList(children);
                setAccount(account);
                children.forEach(child => {
                    tab.push({value: child.id, label: child.first_name + " " + child.last_name})
                })
                tab.push({value: account.id, label: account.first_name + " " + account.last_name})
                setChildAndAccountSelect(tab);
            } catch (e) {
                if (e.status === 401) {
                    localStorage.clear();
                    navigate("/");
                    console.error(e.message);
                }
            }
        }
        children();

    }, [update])

    const onUpdate = () => {
        setUpdate(!update)
    }

    const handleSelectDuration = (duration) => {
        setDuration(duration);
        onUpdate();
    }

    const handleAddToCart = async (selectedAccount, subscription) => {
        try {
            //add the course to the cart
            const res = await apiInscription.inscription_subscription(selectedAccount, subscription.id);
            onUpdate();
            updateCart();
            setOutputMessage('ajouté au panier✅')
        } catch (e) {
            if (e.response.data.message === "Cannot register account to the course, order is in payment process"){
                setOutputMessage(" ❌ Vous avez un payment en cours,\n merci de le finaliser ou annuler avant de commencer un autre panier")
            }else{
                setError(e.response.data.message)
                setOutputMessage("❌ L\'article n\'est pas ajouté vous avez peut-être déjà ce produit dans le panier")
            }
        }
    }
    const handleChildSelect = (selected )=>{
        setSelectedAccount(selected)
        setSelectedSubscription(null)
    }
    const handlSelectSubscription = (selected)=>{
        setSelectedSubscription(selected)
    }

    return (
        <>
            <SelectorUser
                selectedAccount={selectedAccount}
                handleChildSelect={handleChildSelect}
                userList={childAndAccountSelect}
                handleSelectDuration={handleSelectDuration}
                setStartSubscriptionDate={setStartSubscriptionDate}
                startSubscriptionDate={startSubscriptionDate}
                endSubscriptionDate={endSubscriptionDate}
                selectedDuration={duration}
                onUpdate={onUpdate}
                handleAddToCart={handleAddToCart}
                error={error}
                setError={setError}
                selectedSubscription = {selectedSubscription}
                handlSelectSubscription = {handlSelectSubscription}
                outputMessage={outputMessage}
            />
        </>
    )

}
export default Subscriptions;
