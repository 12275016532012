import React, {Fragment,useState} from "react";
import Portal from "../Portal/Portal";
import * as api from "../../services/StageService";


const AddCategoryModal = ({onCancel, onUpdate}) => {
    const [error, setError] = useState()
    const [categorie,setCategorie] = useState()

    const handleSubmit = async e => {
        e.preventDefault();
        //let res = await api.addLocation(name, address, postalCode)
        if(categorie.length === 0){
            setError("description vide")
            return;
        }
        let res = await api.addStageCategory(categorie)
        if (res.status !== undefined) {
            onUpdate();
            onCancel();
        } else {
            setError('une erreur est apparue sur le serveur');
        }
    }
    const handleChangeTextArea = (e) => {
        setCategorie(e.target.value)
    }



    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            {error &&
                                <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                            }
                            <p className="font-bold">Ajouter une catégorie </p>
                            <p className="text-sm text-gray-700 mt-1">
                                veuillez remplir ce formulaire pour ajouter une catégorie de stage.
                            </p>
                        </div>
                    </div>
                    <div className=" flex text-center  justify-center mt-3  ">
                        <form>
                            <div className="flex ">
                                <p className="mr-2">description:</p>
                                <textarea onChange={handleChangeTextArea} defaultValue={""}
                                          className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent">
                                </textarea>
                            </div>
                            <div className="flex">
                                <button onClick={e => {
                                    handleSubmit(e)
                                }}
                                        className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                                    ajouter la catégorie
                                </button>
                                <button onClick={() => {
                                    onCancel()
                                }}
                                        className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                                    fermer
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Portal>
        </Fragment>)
}
export default AddCategoryModal;