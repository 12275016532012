import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "accounts_roles"
})


const getRight = async (id) => {
    try {
        const token = await client.get("/" + id, {headers: {'Authorization': localStorage.getItem('token')}})
        return token.data;
    } catch (e) {
        return e.response.status
    }
}
const setRightToTeamMember = async (id) => {
    try {
        const payload={
            "roleId": 3,
            "accountId": id,
        }
        const token = await client.put("/" + id, payload, {headers: {'Authorization': localStorage.getItem('token')}})
        return token.data;
    } catch (e) {
        return e.response.status
    }
}
const setRightToUser= async (id) => {
    try {
        const payload={
            "type": "user",
            "accountId": id,
        }
        const token = await client.put("/" + id, payload, {headers: {'Authorization': localStorage.getItem('token')}})
        return token.data;
    } catch (e) {
        return e.response.status
    }
}


export {
    getRight,setRightToTeamMember,setRightToUser
}
