import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ data, onTimerEnd }) => {
    // Trouver la date d'inscription la plus ancienne
    const findOldestInscriptionDate = () => {
        const allInscriptions = [
            ...data.inscriptionsCourses,
            ...data.inscriptionsTrainingCourses,
            ...data.inscriptionsSubscriptions,
        ];

        // Extraire les dates d'inscription
        const inscriptionDates = allInscriptions.map((item) => new Date(item.inscription.inscription_date));

        // Retourner la date la plus ancienne
        return inscriptionDates.length > 0 ? new Date(Math.min(...inscriptionDates)) : null;
    };

    // Récupérer la date la plus ancienne
    const oldestInscriptionDate = findOldestInscriptionDate();

    // Calculer la date limite pour le décompte (la minute suivante après une heure à partir de la date d'inscription la plus ancienne)
    const getNextMinute = (date) => {
        const newDate = new Date(date);
        newDate.setMinutes(newDate.getMinutes() + 1); // Ajoute une minute
        newDate.setSeconds(0); // Réinitialise les secondes
        return newDate;
    };

    const expirationDate = oldestInscriptionDate ? getNextMinute(new Date(oldestInscriptionDate.getTime() + 60 * 60 * 1000)) : null;

    // État pour le temps restant en secondes
    const [timeLeft, setTimeLeft] = useState(expirationDate ? calculateTimeLeft(expirationDate) : 0);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        // Mettre à jour le timer chaque seconde
        const timer = setInterval(() => {
            if (expirationDate) {
                const remainingTime = calculateTimeLeft(expirationDate);
                if (remainingTime <= 0) {
                    clearInterval(timer);
                    // Planifier l'appel de onTimerEnd après 3 secondes
                    const id = setTimeout(() => {
                        onTimerEnd();
                    }, 100);
                    setTimeoutId(id);
                    setTimeLeft(0);
                } else {
                    setTimeLeft(remainingTime);
                }
            }
        }, 1000);

        // Nettoyage de l'intervalle lors du démontage du composant
        return () =>{
            clearInterval(timer);
            clearTimeout(timeoutId);
        };
    }, [expirationDate, onTimerEnd, timeoutId]);

    // Fonction pour calculer le temps restant en secondes
    function calculateTimeLeft(endDate) {
        const now = new Date();
        const timeLeftInSeconds = Math.ceil((endDate - now) / 1000);
        return timeLeftInSeconds > 0 ? timeLeftInSeconds : 0;
    }

    // Fonction pour formater le temps restant en mm:ss
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div className="absolute -top-1 -right-1 justify-center items-center ">
            <h2 className="text-sm font-bold text-gray-700 mr-2">Temps restant pour finaliser votre panier:</h2>
            <p className="font-mono text-red-600">{formatTime(timeLeft)}</p>
        </div>
    );
};

export default CountdownTimer;