import {useNavigate} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {TYPE_STAGE, WEEKDAYS} from "../../utils/constants";
import {getTeamMembers} from "../../services/AccountService";
import {editCourse} from "../../services/CourseService";
import Portal from "../Portal/Portal";
import Select from "react-select";
import * as api from "../../services/CourseService";
import {editStage} from "../../services/StageService";

const EditStageModal = ({onCancel, onUpdate, stage, categoriesList}) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [description, setDescription] = useState(stage.description);
    const [maxCapacity, setMaxCapacity] = useState(stage.max_capacity);
    const [begin_date, setBegin] = useState(stage.begin_date.substring(0,stage.begin_date.length-14));
    const [end_date, setEnd] = useState(stage.end_date.substring(0, stage.end_date.length-14));
    const [price, setPrice] = useState(stage.price);
    const [priceForTeamMember, setPriceForTeamMember] = useState(stage.price_for_team_member);
    const [selectedType, setSelectedType] = useState(TYPE_STAGE.find(type => type.label === stage.type));
    const [selectedCategory, setSelectedCategory] = useState(categoriesList.find(category => category.value === stage.category_id));
    const [reserved, setReserved] = useState(stage.reserved);
    const [outputMessage , setOutputMessage] = useState();


    const handleSubmit = async e => {
         e.preventDefault();
         try {
             let rest = await editStage(stage.id, selectedCategory.value, description, maxCapacity, begin_date, end_date, 0, price, priceForTeamMember, selectedType.label, reserved)
             if (rest.status>299){
                 setOutputMessage('erreur❌')
             }else{
                 setOutputMessage('modifié✅')
             }
             onUpdate()
         }catch (e) {
             setError(e.message)
             return
         }

    }

    const handleChangeTextArea = (event) => {
        setDescription(event.target.value)
    }

    const handleChangeSelect = (type) => {
        setSelectedType(type)
    }

    const handleChangeCategory = (category) => {
        setSelectedCategory(category)
    }

    const handleChangeReserved = (event) => {
        if(reserved === 0){
            setReserved(1)
        }else if( reserved === 1) {
            setReserved(0)
        }
    }


    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            {error &&
                                <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                            }
                            <p className="font-bold">Modifier un stage </p>
                        </div>
                    </div>
                    <div className=" flex text-center  justify-center mt-3  ">
                        <form>
                            {
                                //description
                            }
                            <div className="flex ">
                                <p className="mr-2">description:</p>
                                <textarea onChange={handleChangeTextArea} defaultValue={description}
                                          className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent">
                                </textarea>
                            </div>
                            <div className="flex space-x-4 ">
                                {
                                    //dates
                                }
                                <div>
                                    <label className="mb-2 flex">
                                        <p> date debut :</p>
                                        <input type="date" onChange={e => setBegin(e.target.value)} value={begin_date}
                                               className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                                    </label>
                                    <label className="mb-2 flex">
                                        <p className="mr-2">date de fin:</p>
                                        <input type="date" onChange={e => setEnd(e.target.value)} value={end_date}
                                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                        />
                                    </label>
                                </div>
                                {
                                    //  size
                                }
                                <div>
                                    <label className="mb-2 flex">
                                        <p className="mr-2">capacité maximale:</p>
                                        <input type="number" min="0" onChange={e => setMaxCapacity(e.target.value)} value={maxCapacity}
                                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                        />
                                    </label>


                                    <label className="mb-2 flex">
                                        <p className="mr-2">type:</p>
                                        <Select options={TYPE_STAGE} value={selectedType}  isSearchable={false} onChange={(e) => {
                                            handleChangeSelect(e)
                                        }}/>
                                    </label>

                                    <label className="mb-2 flex">
                                        <p className="mr-2">category:</p>
                                        <Select options={categoriesList} value={selectedCategory} isSearchable={false} onChange={(e) => {
                                            handleChangeCategory(e)
                                        }}/>
                                    </label>
                                </div>
                                {
                                    //price
                                }
                                <div>
                                    <label className="mb-2 flex">
                                        <p className="mr-2">prix:</p>
                                        <input type="number" min="0" onChange={e => setPrice(e.target.value)} value={price}
                                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                        />
                                    </label>
                                    <label className="mb-2 flex">
                                        <p className="mr-2">prix actifs:</p>
                                        <input type="number" min="0"
                                               onChange={e => setPriceForTeamMember(e.target.value)} value={priceForTeamMember}
                                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                        />
                                    </label>
                                </div>

                            </div>


                            <label className="mb-2 flex">
                                <p className="mr-2">reserver:</p>
                                <input type="checkbox" checked={reserved} onClick={e=>{
                                    handleChangeReserved()
                                }}
                                       className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                />
                            </label>

                            <div className="flex">
                                <button onClick={e => {
                                    handleSubmit(e)
                                }}
                                        className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                                    editer le stage
                                </button>
                                <label>{outputMessage}</label>
                                <button onClick={() => {
                                    onCancel()
                                }}
                                        className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                                    fermer
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Portal>
        </Fragment>
    );
}
export default EditStageModal