import DataTable from "react-data-table-component";
import {DataTableTheme} from "../DataTableComponents/DataTableTheme";
import React, {useEffect, useState} from "react";
import {BarLoader} from "react-spinners";

const ListBrevets = ({OwnedBrevetsList}) =>{
  const [currentRow, setCurrentRow] = useState(null);
  const [ownedBrevetsContent, setOwnedBrevetsContent] = useState([]);
  const [brevetsList, setBrevetsList] = useState([]);

  useEffect(() => {
    loadBrevetsList({OwnedBrevetsList}).then(r => true)
  }, []);

  let brevetsColumn = {
    columns: [
      {
        name: 'Brevet',
        selector: row => row.brevet,
        sortable: true
      },
      {
        name: 'type',
        selector: row => row.type,
        sortable: true
      },
      {
        name: 'owned',
        selector: row => row.totalOwned,
        sortable: true
      },
      {
        name: 'total',
        selector: row => row.total,
        sortable: true
      }
    ]
  }

  let lineBrevetColumn = {
    columns: [
      {
        name: "position",
        selector: row => row.position,
        sortable:true,
        width:'15%'
      },
      {
        name: "description",
        selector: row => row.description,
        wrap:true,
        width:'73%'
      },
      {
        name: "owned",
        selector: row=> row.owned,
        sortable:true,
        width:'12%'
      }
    ]
  }

  const ExpandedComponent = () => {
    const sortedData = ownedBrevetsContent.slice().sort((a, b) => a.position - b.position);
    return (<pre className='max-w-full'><DataTable columns={lineBrevetColumn.columns} data={sortedData}
                                              showGridlines
                                              progressComponent={<BarLoader color="#e44c65" size='100' />}
                                              theme="TableEV"
                                              highlightOnHover/></pre>);
  }

  const loadBrevetsList = async (brevets) =>{
    let brevetsContainer = [];
    let brevetsTypes=brevets.OwnedBrevetsList;
    brevetsTypes.forEach((brevet)=>{
      let hasBrevet=brevetsContainer.some(function(bre){
        return (bre.brevet===brevet.brevet && bre.type===brevet.type);
      });
      if(hasBrevet){
        brevetsContainer.forEach(function(bre){
          if(bre.brevet===brevet.brevet && bre.type===brevet.type){
            bre.total++;
            if(brevet.assigned){
              bre.totalOwned++;
            }
          }
        })
      }else{
        let owned=0;
        if(brevet.assigned){
          owned++;
        }
        let newBrevet={
          brevet:brevet.brevet,
          type: brevet.type,
          totalOwned: owned,
          total: 1,
        }
        brevetsContainer.push(newBrevet);
      }
    })
    const sortedData = brevetsContainer.slice().sort((a, b) => a.brevet.localeCompare(b.brevet));
    setBrevetsList(sortedData);
  }

  const loadBrevets = async (row) =>{
    setCurrentRow(row);
    setOwnedBrevetsContent([]);
    const brevets = {OwnedBrevetsList}.OwnedBrevetsList;
    let brevetsContainer=[];

    brevets.forEach((bre)=>{
      if(bre.brevet===row.brevet && bre.type===row.type){
        let has="no";
        if(bre.assigned){
          has="yes";
        }
        let lineBrevet={
          position: bre.position,
          description: bre.description,
          owned: has
        }
        brevetsContainer.push(lineBrevet);
      }
    })
    setOwnedBrevetsContent(brevetsContainer);
  }

  DataTableTheme()

  return (
      <div className="overflow-auto overflow-scroll">
        <h1>Liste de brevets</h1>
            <DataTable
                columns={brevetsColumn.columns}
                data={brevetsList}
                expandableRows
                expandableRowExpanded={(row) => (row === currentRow)}
                onRowClicked={(row) => setCurrentRow(row)}
                expandableRowsComponent={ExpandedComponent}
                onRowExpandToggled={(bool, row) => loadBrevets(
                    row)}
                showGridlines
                pagination
                highlightOnHover
                progressComponent={<BarLoader color="#e44c65" size='100'/>}
                theme="TableEV"
                paginationRowsPerPageOptions={[1, 5, 10, 20, 25, 50, 100]}
            />
      </div>
  );
}

export default ListBrevets;
