import Select from "react-select";
import React from "react";
import SelectorDuration from "./SelectorDuration";

const SelectorUser = ({
                          handleChildSelect,
                          userList,
                          selectedAccount,
                          handleSelectDuration,
                          startSubscriptionDate,
                          selectedDuration,
                          setStartSubscriptionDate,
                          handleAddToCart,
                          onUpdate,
                          error,
                          setError,
                          selectedSubscription,
                          handlSelectSubscription,
                          outputMessage
                      }) => {

    return (
        <div className="flex flex-col items-center justify-center m-5">
            <h1 className="text-3xl font-bold mb-2">Veuillez sélectionner un compte</h1>
            <div className="flex flex-col items-center justify-center ">
                <div className=" h-screen w-full">
                    <Select className="text-black " options={userList} onChange={(e) => {
                        handleChildSelect(e.value)
                    }}/>
                    {selectedAccount &&
                        <SelectorDuration
                            selectedAccount={selectedAccount}
                            handleChildSelect={handleChildSelect}
                            handleSelectDuration={handleSelectDuration}
                            startSubscriptionDate={startSubscriptionDate}
                            selectedDuration={selectedDuration}
                            setStartSubscriptionDate={setStartSubscriptionDate}
                            onUpdate={onUpdate}
                            handleAddToCart={handleAddToCart}
                            error={error}
                            setError={setError}
                            selectedSubscription = {selectedSubscription}
                            handlSelectSubscription = {handlSelectSubscription}
                            outputMessage={outputMessage}
                        />

                    }
                </div>
            </div>
        </div>
    );

}

export default SelectorUser;