import React, {Fragment, useEffect, useState} from "react";
import * as api from '../../services/CourseService'
import Portal from "../Portal/Portal";
import Select from "react-select";
import {getTeamMembers} from "../../services/AccountService";
import {WEEKDAYS} from "../../utils/constants";

const AddCourseModal = ({
                            onCancel,
                            sectionList,
                            locationList,
                            onUpdate,
                            weekDayPredefine,
                            beginTimePredefine,
                            endTimePredefine,
                            maxCapacityPredefine,
                            pricePredefine,
                            beginDatePredefine,
                            endDatePredefine,
                            reservedPredefine,
                            sectionIdPredefine,
                            locationIdPredefine,
                        }) => {

    const [weekDay, setWeekDay] = useState(weekDayPredefine);
    const [beginTime, setBeginTime] = useState(beginTimePredefine);
    const [endTime, setEndTime] = useState(endTimePredefine);
    const [maxCapacity, setMaxCapacity] = useState(maxCapacityPredefine);
    const [price, setPrice] = useState(pricePredefine);
    const [beginDate, setBeginDate] = useState(beginDatePredefine);
    const [endDate, setEndDate] = useState(endDatePredefine);
    const [reserved, setReserved] = useState(reservedPredefine ? reservedPredefine : 0);
    const [sectionId, setSectionId] = useState(sectionIdPredefine);
    const [locationId, setLocationId] = useState(locationIdPredefine);
    const [responsible, setResponsible] = useState();
    const [error, setError] = useState();
    const [memberList, setMemberList] = useState([])

    //useeffect to fetch all inscriptions
    useEffect(() => {
        async function loader() {
            const members = await getTeamMembers()
            // for each member check if he is a team member (id 4 or 3) by calling getRight from accountrightservice
            const teamMembers = [];
            for (let member of members) {
                let formattedMember = {
                    value: member.id,
                    label: member.first_name + " " + member.last_name
                }
                teamMembers.push(formattedMember)
            }
            setMemberList(teamMembers)
        }

        loader()
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        if (!sectionId || !locationId) {
            setError("il n'y a pas de section ou de lieu dans la base de données merci d'en ajouter")
        } else if (!beginTime || !endTime) {
            setError("merci de completer les heures")
        } else if (!price) {
            setError("merci d'indiquer le prix")
        } else {
            let res = await api.addCourse(sectionId.value, responsible.value, locationId.value, weekDay.value, beginTime, endTime, maxCapacity, price, beginDate, endDate, reserved);
            if (res !== undefined) {
                onUpdate();
                onCancel();
            } else {
                setError('une erreur est apparue sur le serveur');
            }
        }
    }


    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0 "/>
                <div
                    className="bg-white rounded-lg  fixed inset-x-0 bottom-60 z-50 m-auto md:relative  overflow-auto ">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            <p className="font-bold">Ajouter un cour</p>
                            <p className="text-sm text-gray-700 mt-1">
                                veuillez remplir ce formulaire pour ajouter un cour.
                            </p>
                        </div>
                        {error &&
                            <h1 className="rounded bg-red-600 w-80 m-auto p-2 shadow-lg ">{error}</h1>
                        }
                    </div>
                    <div className=" flex h-full overflow-y-scroll  text-center  justify-center mt-3 ">
                        <form>
                            {
                                //weekday
                            }
                            <div className="flex ">
                                <p>jour:</p>
                                <Select className="text-black ml-5 mb-2 " value={weekDay} options={WEEKDAYS}
                                        onChange={setWeekDay}/>

                                <div className="form-check form-check-inline ml-3">
                                    <label className="form-check-label inline-block mr-2">Heure de début :</label>
                                    <input type="time" onChange={e => setBeginTime(e.target.value)}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                    />
                                </div>
                                <div className="form-check form-check-inline ml-3">
                                    <label className="form-check-label inline-block mr-2">Heure de fin :</label>
                                    <input type="time" onChange={e => setEndTime(e.target.value)}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                    />
                                </div>
                            </div>
                            {
                                //section location and reservation
                            }
                            <div className="flex ">
                                <div className="form-check form-check-inline ml-3">
                                    <p className="form-check-label inline-block mr-2">section:</p>
                                    <Select className="text-black ml-5 mb-2 " value={sectionId} options={sectionList}
                                            onChange={setSectionId}/>
                                </div>
                                <div className="form-check form-check-inline ml-3">
                                    <p className="form-check-label inline-block mr-2">lieu:</p>
                                    <Select className="text-black ml-5 mb-2 " value={locationId} options={locationList}
                                            onChange={setLocationId}/>
                                </div>
                                <div className="form-check form-check-inline ml-3">
                                    <p className="form-check-label inline-block mr-2">responsable:</p>
                                    <Select className="text-black ml-2 mb-2  " options={memberList}
                                            onChange={setResponsible}/>
                                </div>

                            </div>

                            {
                                // maximal capacity of the course
                            }
                            <div className="flex ">
                                <p> capacité maximale :</p>
                                <input type="number" onChange={e => setMaxCapacity(e.target.value)}
                                       className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                            </div>
                            {
                                // prices
                            }
                            <div className="flex  tablet:flex-wrap m-3">
                                <div className="m-auto">
                                    <p> prix :</p>
                                    <input type="number" onChange={e => setPrice(e.target.value)}
                                           className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                                </div>
                                <div className="form-check form-check-inline ml-5 ">
                                    <p className="form-check-label inline-block mr-2"> reservé :</p>
                                    <input type="checkbox" onChange={e => {
                                        if (reserved === 0) {
                                            setReserved(1);
                                        } else {
                                            setReserved(0)
                                        }
                                    }
                                    }
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 "/>

                                </div>

                            </div>

                            {
                                // dates
                            }
                            <div className="flex tablet:flex-wrap ">
                                <div className="form-check form-check-inline ml-3 ">
                                    <p className="form-check-label inline-block mr-2"> date début :</p>
                                    <input type="date" onChange={e => setBeginDate(e.target.value)}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"/>
                                </div>
                                <div className="form-check form-check-inline ml-3">
                                    <p className="form-check-label inline-block mr-2"> date fin :</p>
                                    <input type="date" onChange={e => setEndDate(e.target.value)}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"/>
                                </div>
                            </div>

                            <div className="flex">
                                <button onClick={e => {
                                    handleSubmit(e)
                                }}
                                        className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1  bg-pinkEv text-white ">
                                    ajouter le cour
                                </button>
                                <button onClick={() => {
                                    onCancel()
                                }}
                                        className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                                    fermer
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Portal>
        </Fragment>
    )
        ;
};

export default AddCourseModal;
