import React, {Fragment, useEffect, useState} from "react";
import {stageMembreList} from "../../services/StageService";
import {DataTableTheme} from "../DataTableComponents/DataTableTheme";
import Portal from "../Portal/Portal";
import CsvDownloadButton from "react-json-to-csv";
import DataTable from "react-data-table-component";
import {BarLoader} from "react-spinners";


const ShowStudentsStageModal = ({selectedStage, onCancel}) => {
    const [stageStudentList, setStageStudentList] = useState([])
    const [formatedList, setFomatedList] = useState([]);
    const [pending, setPending] = React.useState(true);

    useEffect(() => {
        const handleShowStudent = async () => {
            const stageMember = await stageMembreList(selectedStage)
            await setPending(false);
            setStageStudentList(stageMember)
            stageMember.forEach(member => {
                if (member.image_right === 0) {
                    member.image_right = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">non</span>
                }else{
                    member.image_right = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">oui</span>
                }
                if (member.allow_going_home_alone === 0) {
                    member.allow_going_home_alone = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">non</span>
                }else {
                    member.allow_going_home_alone = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">oui</span>
                }
            })
            setFomatedList(formatData(stageMember))
        }
        const formatData = (data) => {
            let table = {
                columns: [
                    {
                        name: 'prénom',
                        selector: row => row.first_name,
                        sortable: true,
                    },
                    {
                        name: 'nom',
                        selector: row => row.last_name,
                        sortable: true,
                    },
                    {
                        name: 'email',
                        selector: row => row.email,
                        sortable: true,
                        grow: 3,
                    },
                    {
                        name: 'phone',
                        selector: row => row.phone,
                        sortable: true,
                        grow: 2,
                    },
                    {
                        name: 'sex',
                        selector: row => row.gender,
                        sortable: true,
                    },
                    {
                        name: 'birthdate',
                        selector: row => row.birth_date,
                        sortable: true,
                    },
                    {
                        name: 't-shirt',
                        selector: row => row.t_shirt,
                        sortable: true,
                    },
                    {
                        name:'droit image',
                        selector:row=>row.image_right,
                        sortable:true
                    },
                    {
                        name:'rentrer-seul',
                        selector:row=>row.allow_going_home_alone,
                        sortable:true
                    }
                ],
                rows: data
            }
            return table
        }

        handleShowStudent()
    }, [])

    DataTableTheme()

    return (
        <Fragment>
            <div onClick={onCancel} className="fixed inset-0 bg-black opacity-25" />
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50 fixed inset-0 z-40" />
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="bg-backgroundEV rounded-lg w-full overflow-hidden">
                        <div className="p-4">
                            <div className="flex justify-end">
                                <button
                                    onClick={() => {
                                        onCancel();
                                    }}
                                    className="border rounded p-1 m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black"
                                >
                                    fermer
                                </button>
                            </div>
                            <form className="mt-4">
                                {stageStudentList.length > 0 ? (
                                    <div className="overflow-auto shadow-md sm:rounded-lg max-h-[90vh]">
                                        <div className="mb-4">
                                            <CsvDownloadButton
                                                className="border bg-1c1d26 font-bold rounded p-1 border-pinkEv text-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white"
                                                data={stageStudentList}
                                                filename={'stage'+new Date().toDateString()}
                                            >
                                                télécharger le CSV
                                            </CsvDownloadButton>
                                        </div>

                                        <DataTable
                                            columns={formatedList.columns}
                                            data={stageStudentList}
                                            showGridlines
                                            theme="TableEV"
                                            pagination
                                            highlightOnHover
                                            progressPending={pending}
                                            progressComponent={<BarLoader color="#e44c65" size="100" />}
                                            paginationRowsPerPageOptions={[20, 25, 50, 100]}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <h1>aucun élève inscrit</h1>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </Portal>
        </Fragment>
    );
}
export default ShowStudentsStageModal