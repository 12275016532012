import React, {useEffect, useMemo, useState} from 'react';
import {DashboardLayout} from "../../components/Layout/Layout";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {ifExpiredDisconnect} from "../../utils/toolbox";
import DataTable from 'react-data-table-component';
import FilterComponent from "../../components/DataTableComponents/FilterComponent";
import {DataTableTheme} from "../../components/DataTableComponents/DataTableTheme";
import {BarLoader} from "react-spinners";
import AddInscriptionDatesModal
    from "../../components/Modals/AddInscriptionDatesModal";
import {MDBDataTable} from "mdbreact";
import {
    deleteInscriptionYear, fullyCloseInscriptionDate,
    getAllInscriptionDates,
    getInscriptionDates, openInscriptionDate
} from "../../services/InscriptionDatesService";
import EditInscriptionDatesModal
    from "../../components/Modals/EditInscriptionDatesModal";
import ChangeCourseModal
    from "../../components/Modals/ChangeCourseModal";
import {utcToZonedTimeFormated} from "../../utils/timeUtils";
import * as apiSection from "../../services/SectionService";
import * as apiInscription from "../../services/InscriptionService"
import {getCourses} from "../../services/CourseService";

const AdminInscriptionsList = () => {
    const [inscriptionDatesModal, setInscriptionDatesModal] = useState(false);
    const [editInscriptionDatesModal, setEditInscriptionDatesModal] = useState(false);
    const [inscriptions, setInscriptions] = useState([])
    const [formatedInscriptions, setFormatedInscriptions] = useState([])
    const [filterText, setFilterText] = React.useState("");
    const [pending, setPending] = React.useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [inscriptionDatesList,setInscriptionDatesList] = useState([]);
    const [showInscriptionDatesList,setShowInscriptionDatesList] = useState(false);
    const [selectedInscriptionDatesToEdit, setSelectedInscriptionDatesToEdit] = useState();
    const [openInscriptions,setOpenInscriptions]= useState(true);
    const [update, setUpdate] = useState(false)
    const [courseToChange, setCourseToChange] = useState(null);
    const [inscriptionToChange, setInscriptionToChange] = useState(null);
    const [showChangeCourseModal, setShowChangeCourseModal] = useState(false);
    const [coursesList, setCoursesList] = useState([]);
    const [courseChangeMessage,setCourseChangeMessage] = useState("");
    /**
     * this fuction edit the paid status of an inscription for an admin
     * @param idIscription
     * @param status
     */
    const handlePaidLabel = async (idIscription, status, cancelled) => {
        await apiInscription.mark_inscription_as(idIscription,status,cancelled)
        setUpdate(!update)
    }

    const handleCancelInscription = async (id,paid,cancelled) => {
        if (window.confirm("Êtes-vous sûr de vouloir annuler cette inscription ? l'inscription ne serra pas suprimée mais plus prise en compte ")) {
            await apiInscription.mark_inscription_as(id,paid,cancelled)
            setUpdate(!update)
        }
    }

    //useeffect to fetch all inscriptions
    useEffect(() => {
            ifExpiredDisconnect()
            const loader = async () => {
                try {
                    const courses = await getCourses();
                    setCoursesList(courses);
                    const output = await Promise.all([apiInscription.inscriptions_list(),apiSection.getSectionList()])
                    const response =  output[0]
                    //sort response.data by inscription_date the most rescent on top of the list
                    response.data.sort((a, b) => {
                        return new Date(b.inscription_date) - new Date(a.inscription_date);
                    })

                    setInscriptions(response.data)
                    const formatData = (data) => {
                        data.forEach((item) => {
                                //for each stage format the begin and end date in format DD-MM-YYYY
                                data.forEach(inscription => {
                                    //format inscription_date but keep time
                                    const time = inscription.inscription_date.split('T')[1]
                                    if (time) {
                                        const timeZone = 'Europe/Brussels';
                                        inscription.inscription_date = utcToZonedTimeFormated(inscription.inscription_date,timeZone);
                                    }
                                    inscription.begin_date = inscription.begin_date.split('T')[0];
                                    inscription.begin_date = inscription.begin_date.split('-').reverse().join('/');
                                    inscription.end_date = inscription.end_date.split('T')[0];
                                    inscription.end_date = inscription.end_date.split('-').reverse().join('/');
                                    inscription.first_name = inscription.account.first_name
                                    inscription.last_name = inscription.account.last_name
                                })
                                 if (item.cancelled === 0) {
                                     if (item.paid === 1) {
                                         item.cancel =
                                             <button
                                                 className="btn-danger p-1 inline-flex rounded"
                                                 onClick={() => handleCancelInscription(item.id, true, true)}>
                                                 annuler
                                             </button>
                                     }else{
                                         item.cancel =
                                             <button
                                                 className="btn-danger p-1 inline-flex rounded"
                                                 onClick={() => handleCancelInscription(item.id, false, true)}>
                                                 annuler
                                             </button>
                                     }
                                 }else{
                                     item.cancel = <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">annulée</span>
                                 }

                                if (item.type === 'member') {
                                    item.type = <span
                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">cotisation EV</span>
                                    item.ref = '/'
                                    item.change = '/'
                                } else if (item.type === 'course') {
                                    item.type = <span
                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">cours</span>
                                    let section = output[1].find(
                                        section => section.id
                                            === item.course.section_id)
                                    item.ref = item.course.week_day.substring(0,
                                            2) + item.course.begin_time.substring(0,
                                            2) + '-'
                                        + section.description.substring(0, 5)
                                        + section.min_age + '-'
                                        + item.course.begin_date.substring(0, 4)
                                    item.change = <button onClick={(e) => {
                                        e.preventDefault();
                                        handleShowChangeCourseModal();
                                        setCourseToChange(item.course);
                                        setInscriptionToChange(item.id);
                                    }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded">Changer</button>
                                } else if (item.type === 'member_cab') {
                                    item.type = <span
                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">cotisation cab</span>
                                    item.ref = '/'
                                    item.change = '/'
                                } else if (item.type === 'training_course') {
                                    item.type = <span
                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">stage</span>
                                    item.ref = '/'
                                    item.change = '/'
                                } else if (item.type === 'subscription') {
                                    item.type = <span
                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800 ">abonnement</span>
                                    item.ref = '/'
                                    item.change = '/'
                                }
                                if (item.order !== null) {
                                    if (item.order.status === 'STRIPE_CHECKOUT_SESSION_COMPLETED') {
                                        item.status = <span
                                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Paid</span>
                                    } else if (item.order.status === 'PAYMENT_CREATED') {
                                        item.status = <span
                                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">Pending</span>
                                    } else if (item.order.status === 'PAYMENT_FAILED') {
                                        item.status = <span
                                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Failed</span>
                                    } else if (item.order.status === 'CREATED') {
                                        item.status = <span
                                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">panier</span>
                                    } else if (item.order.status === 'STRIPE_CHECKOUT_SESSION_EXPIRED') {
                                        item.status = <span
                                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Payment expirer</span>
                                    }
                                } else {
                                    if (item.paid === 1) {
                                        if (item.cancelled === 1) {
                                            item.status = <button
                                                className="px-2 inline-flex text-xs leading-5 font-semibold  bg-green-100 text-green-800 duration-500 ease-in-out transform hover:-translate-y-1 "
                                                onClick={() => handlePaidLabel(item.id, false,true)}>Paid</button>
                                        }else{
                                            item.status = <button
                                                className="px-2 inline-flex text-xs leading-5 font-semibold  bg-green-100 text-green-800 duration-500 ease-in-out transform hover:-translate-y-1 "
                                                onClick={() => handlePaidLabel(item.id, false, false)}>Paid</button>

                                        }
                                    } else {
                                        if (item.cancelled === 1) {
                                        item.status = <button
                                            className="px-2 inline-flex text-xs leading-5 font-semibold  bg-red-100 text-red-800 duration-500 ease-in-out transform hover:-translate-y-1 " onClick={() => handlePaidLabel(item.id, true, true)}>pas payé</button>
                                        }else{
                                            item.status = <button
                                                className="px-2 inline-flex text-xs leading-5 font-semibold  bg-red-100 text-red-800 duration-500 ease-in-out transform hover:-translate-y-1 " onClick={() => handlePaidLabel(item.id, true,false)}>pas payé</button>

                                        }
                                    }

                                }
                            }
                        )
                        let table = {
                            columns: [
                                {
                                    name: 'id',
                                    selector: row => row.id,
                                    sortable: true,

                                },
                                {
                                    name: 'prénom',
                                    selector: row => row.account.first_name,
                                    sortable: true,
                                },
                                {
                                    name: 'nom',
                                    selector: row => row.account.last_name,
                                    sortable: true,
                                },
                                {
                                    name: 'id panier',
                                    selector: row => {
                                        if (row.order === null) {
                                            return 'manuelle'
                                        }
                                        return row.order.id
                                    },
                                    sortable: true,
                                },
                                {
                                    name: 'ref Cour',
                                    selector: row => row.ref,
                                    sortable: true,
                                },
                                {
                                    name: 'Type',
                                    selector: row => row.type,
                                    sortable: true,
                                },
                                {
                                    name: 'Inscription Date',
                                    selector: row => row.inscription_date,
                                    sortable: true,
                                    grow:2
                                },
                                {
                                    name: 'montant',
                                    selector: row => row.price,
                                    sortable: true,
                                },
                                {
                                    name: 'reduction',
                                    selector: row => row.reduction,
                                    sortable: true,
                                },
                                {
                                    name: 'Status',
                                    selector: row => row.status,
                                    sortable: true,
                                },
                                {
                                  name:'Changer cours',
                                    selector: row=>row.change,
                                },
                                {
                                    name: 'Annuler',
                                    selector: row => row.cancel,
                                }
                            ],
                            rows: data
                        }
                        return table
                    }
                    setFormatedInscriptions(formatData(response.data))

                    //inscription dates
                    const inscriptionDates = await getAllInscriptionDates();
                    if(inscriptionDates!==undefined){
                        inscriptionDates.forEach(inscriptionDate=>{
                            if(inscriptionDate.old_member_date===null){
                                inscriptionDate.old_member_date_format="Date non choisie";
                            }else{
                                const time = inscriptionDate.old_member_date.split('T')[1]
                                if (time) {
                                    const timeZone = "Europe/Brussels";
                                    inscriptionDate.old_member_date_format=utcToZonedTimeFormated(inscriptionDate.old_member_date,timeZone);
                                }
                            }
                            if(inscriptionDate.new_member_date===null){
                                inscriptionDate.new_member_date_format="Date non choisie";
                            }else{
                                const time = inscriptionDate.new_member_date.split('T')[1]
                                if (time) {
                                    const timeZone = "Europe/Brussels";
                                    inscriptionDate.new_member_date_format=utcToZonedTimeFormated(inscriptionDate.new_member_date,timeZone);
                                }
                            }
                        })
                        setInscriptionDatesList(formatInscriptionDates(inscriptionDates));
                    }else{
                        setInscriptionDatesList([]);
                    }
                    const now = new Date();
                    const thisYear = now.getFullYear();
                    const thisYearInscription = await getInscriptionDates(thisYear);
                    if(thisYearInscription){
                        setOpenInscriptions(!thisYearInscription.fully_closed);
                    }
                } catch (e) {
                    console.log(e)
                }
                await setPending(false);
            }
            loader()

        }, [update]
    )
    const onUpdate = () => {
        setUpdate(!update)
    }
    const onCancelInscriptionDatesModal = () => {
        setInscriptionDatesModal(false);
    }

    const onCancelEditInscriptionDatesModal = () => {
        setEditInscriptionDatesModal(false);
    }
    const onEditInscriptionDates = async (year) => {
        const inscriptionDates = await getInscriptionDates(year);
        setEditInscriptionDatesModal(!editInscriptionDatesModal);
        setSelectedInscriptionDatesToEdit(inscriptionDates);
        setUpdate(!update);
    }
    const onDeleteInscriptionYear = async (year) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ces dates d'inscriptions ?")){
            await deleteInscriptionYear(year);
            setUpdate(!update);
        }
    }
    const onFullyCloseInscriptions = async ()=>{
        const now= new Date();
        const year = now.getFullYear();
        if (window.confirm("Êtes-vous sûr de vouloir complètement fermer les inscriptions pour cette année?")){
            await fullyCloseInscriptionDate(year);
            setUpdate(!update);
        }
    }

    const onOpenInscriptions = async ()=>{
        const now= new Date();
        const year = now.getFullYear();
        if (window.confirm("Êtes-vous sûr de vouloir ré-ouvrir les inscriptions pour cette année?")){
            await openInscriptionDate(year);
            setUpdate(!update);
        }
    }

    const handleShowChangeCourseModal = () => {
        setShowChangeCourseModal(!showChangeCourseModal)
    }

    const formatInscriptionDates = (data) => {
        if (data !== undefined) {
            data.forEach(inscriptionDates => {
                inscriptionDates.modify = <span> <button
                    className="border rounded p-1 border-white text-white m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-white hover:text-black"
                    onClick={() => onEditInscriptionDates(
                        inscriptionDates.year)}>Modifier</button>
                    <button
                        className="btn btn-danger p-1 inline-flex font-semibold"
                        onClick={() => onDeleteInscriptionYear(
                            inscriptionDates.year)}>Supprimer</button>
                    </span>
            })
            const sortedData = data.slice().sort((a, b) => {
                return a.year - b.year;
            });

            let table = {
                columns: [
                    {
                        label: 'Année',
                        field: 'year',
                        sort: 'asc',
                        width: '12%'
                    },
                    {
                        label: 'Début inscription anciens membres',
                        field: 'old_member_date_format',
                        width: '35%'
                    },
                    {
                        label: 'Début inscription nouveaux membres',
                        field: 'new_member_date_format',
                        width: '35%'
                    },
                    {
                        label:'options',
                        field: 'modify'
                    }
                ],
                rows: sortedData
            }
            return table
        }
    }

    DataTableTheme()
    const filteredItems = inscriptions.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <DashboardLayout>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Admin
                Inscription List</h2>
            <div className=" mt-14 ml-10 mr-10 content-center">
                {showInscriptionDatesList === false ?
                    <button
                        onClick={() => {
                            setShowInscriptionDatesList(
                                !showInscriptionDatesList)
                        }}
                        className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                        Afficher la liste des dates d'inscription
                    </button>
                    :
                    <button
                        onClick={() => {
                            setShowInscriptionDatesList(
                                !showInscriptionDatesList)
                        }}
                        className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                        Cacher la liste des dates d'inscription
                    </button>
                }
                {showInscriptionDatesList && (
                    <div
                        className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <button
                            onClick={() => {
                                setInscriptionDatesModal(true)
                            }}
                            className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                            Ajouter une année d'inscription
                        </button>
                        {openInscriptions ? (
                            <button
                                className="btn btn-danger p-1 inline-flex font-semibold"
                                onClick={() => onFullyCloseInscriptions()}>Fermer
                                complètement les inscriptions</button>
                        ) : (
                            <button
                                className="btn btn-danger p-1 inline-flex font-semibold"
                                onClick={() => onOpenInscriptions()}>Ré-ouvrir
                                les inscriptions</button>
                        )}
                        <MDBDataTable theadTextWhite={true}
                                      tbodyTextWhite={true} striped bordered
                                      sortable
                                      data={inscriptionDatesList}
                                      noBottomColumns={true} searching={true}/>
                    </div>
                )}
            </div>
            <div className=" mt-14 ml-10 mr-10 content-center">
                <label className="text-green-500 mt-4">{courseChangeMessage}</label>
                <div
                    className="relative overflow-x-auto shadow-md sm:rounded-lg ">
                    <DataTable
                        columns={formatedInscriptions.columns}
                        data={filteredItems}
                        showGridlines
                        theme="TableEV"
                        highlightOnHover
                        subHeader
                        progressPending={pending}
                        progressComponent={<BarLoader color="#e44c65"
                                                      size='100'/>}
                        subHeaderComponent={subHeaderComponent}
                    />
                </div>
            </div>
            {inscriptionDatesModal &&
                <AddInscriptionDatesModal
                    onCancel={onCancelInscriptionDatesModal}
                    onUpdate={onUpdate}/>
            }
            {editInscriptionDatesModal &&
                <EditInscriptionDatesModal
                    onCancel={onCancelEditInscriptionDatesModal}
                    onUpdate={onUpdate}
                    inscriptionDatesSelected={selectedInscriptionDatesToEdit}/>
            }
            {showChangeCourseModal &&
                <ChangeCourseModal currentInscription={inscriptionToChange}
                                   currentCourse={courseToChange}
                                   onClose={handleShowChangeCourseModal}
                                   coursesList={coursesList}
                                   onUpdate={onUpdate}
                                   setCourseChangeMessage={setCourseChangeMessage}/>
            }
        </DashboardLayout>
    )
}

export default AdminInscriptionsList;
