import React, {useState} from "react";

//if payment is successful show a page with a centered green box with a Payment Successful message and a button to go back to the home page
//if payment is not successful show a page with a centered red box with a Payment Failed message and a button to go back to the home page
const ServerInMaintenance = () => {

    const [paymentStatus, setPaymentStatus] = useState("pending");

    //handle redirect to home page
    const menu = () => {
        window.location.href = "/";
    }
    return (
        <div className="min-h-screen bg-backgroundEV">
            <div className="h-screen flex items-center justify-center ">
                <main>
                    <section class="advice">
                        <h1 class="advice__title">Site en maintenance </h1>
                        <p class="advice__description">Nous faisons notre possible pour améliorer votre experience</p>
                    </section>
                    <section className="city-stuff">
                        <ul className="skyscrappers__list">
                            <li class="skyscrapper__item skyscrapper-1"></li>
                            <li class="skyscrapper__item skyscrapper-2"></li>
                            <li class="skyscrapper__item skyscrapper-3"></li>
                            <li className="skyscrapper__item skyscrapper-4"></li>
                            <li className="skyscrapper__item skyscrapper-5"></li>
                        </ul>
                        <ul className="tree__container">
                            <li className="tree__list">
                                <ul className="tree__item tree-1">
                                    <li className="tree__trunk"></li>
                                    <li className="tree__leaves"></li>
                                </ul>
                                <ul className="tree__item tree-2">
                                    <li className="tree__trunk"></li>
                                    <li className="tree__leaves"></li>
                                </ul>
                                <ul className="tree__item tree-3">
                                    <li className="tree__trunk"></li>
                                    <li className="tree__leaves"></li>
                                </ul>
                                <ul className="tree__item tree-4">
                                    <li className="tree__trunk"></li>
                                    <li className="tree__leaves"></li>
                                </ul>
                                <ul className="tree__item tree-5">
                                    <li className="tree__trunk"></li>
                                    <li className="tree__leaves"></li>
                                </ul>
                                <ul className="tree__item tree-6">
                                    <li className="tree__trunk"></li>
                                    <li className="tree__leaves"></li>
                                </ul>
                                <ul className="tree__item tree-7">
                                    <li className="tree__trunk"></li>
                                    <li className="tree__leaves"></li>
                                </ul>
                                <ul className="tree__item tree-8">
                                    <li className="tree__trunk"></li>
                                    <li className="tree__leaves"></li>
                                </ul>
                            </li>
                        </ul>
                        <ul className="crane__list crane-1">
                            <li className="crane__item crane-cable crane-cable-1"></li>
                            <li className="crane__item crane-cable crane-cable-2"></li>
                            <li className="crane__item crane-cable crane-cable-3"></li>
                            <li className="crane__item crane-stand"></li>
                            <li className="crane__item crane-weight"></li>
                            <li className="crane__item crane-cabin"></li>
                            <li className="crane__item crane-arm"></li>
                        </ul>
                        <ul className="crane__list crane-2">
                            <li className="crane__item crane-cable crane-cable-1"></li>
                            <li className="crane__item crane-cable crane-cable-2"></li>
                            <li className="crane__item crane-cable crane-cable-3"></li>
                            <li className="crane__item crane-stand"></li>
                            <li className="crane__item crane-weight"></li>
                            <li className="crane__item crane-cabin"></li>
                            <li className="crane__item crane-arm"></li>
                        </ul>
                        <ul className="crane__list crane-3">
                            <li class="crane__item crane-cable crane-cable-1"></li>
                            <li class="crane__item crane-cable crane-cable-2"></li>
                            <li class="crane__item crane-cable crane-cable-3"></li>
                            <li class="crane__item crane-stand"></li>
                            <li class="crane__item crane-weight"></li>
                            <li class="crane__item crane-cabin"></li>
                            <li class="crane__item crane-arm"></li>
                        </ul>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default ServerInMaintenance;