import React, {useEffect, useState} from "react";
import {getOrdersList} from "../services/OrderService";


const Context = React.createContext(null);

const ProviderWrapper = (props) => {
    const [update, setUpdate] = useState(false);
    const [orders, setOrders] = useState({
        inscriptionsCourses: [],
        inscriptionsMembers: [],
        inscriptionsMembersCab: [],
        inscriptionsSubscriptions: [],
        inscriptionsTrainingCourses: [],
        order: {total_price: 0.00}
    });

    useEffect(() => {
        const getOrders = async () => {
            if (localStorage.getItem("token")) {
                const output = await getOrdersList();
                if (output.status === 200) {
                    setOrders(output.data);
                }
            }
        }
        getOrders();
    }, [update]);

    const updateCart = () => {
        setUpdate(!update)
    }

    const updateOrder = (order) => {
        setOrders(order);
    }
    const removeOrder = (order) => {
        setOrders(orders.filter(o => o !== order));
    }
    const addOrder = (order) => {
        setOrders([...orders, order]);
    }

    const exposedValue = {
        orders, updateOrder, removeOrder, addOrder, updateCart
    }

    return (
        <Context.Provider value={exposedValue}>{props.children}</Context.Provider>
    );
};

export {Context, ProviderWrapper};
export default Context;
