import React, {Fragment, useState} from "react";

import Portal from "../Portal/Portal";
import * as api from "../../services/LocationService"
import isPostalCode from "validator/es/lib/isPostalCode";

const AddLocationModal = ({onCancel, onUpdate}) => {

    const [name, setName] = useState();
    const [postalCode, setPostalCode] = useState();
    const [houseNumber, setHouseNumber] = useState();
    const [locality, setLocality] = useState();
    const [box,setBox] = useState();
    const [street, setStreet] = useState();
    const [error, setError] = useState();


    const handleSubmit = async e => {
        e.preventDefault();
        if (!postalCode || !street || !name || !houseNumber || !locality) {
            setError(' Veuillez completer tout les champs ');
            return;
        }
        if (!isPostalCode(postalCode.toString(), 'BE')) {
            setError('Entrez un code postal Belge correcte')
            return;
        }
        let res = await api.addLocation(name, street, houseNumber, postalCode, locality, box);
        if (res.status !== undefined) {
            onUpdate();
            onCancel();
        } else {
            setError('l\'addresse existe déjà ou une erreur est apparue sur le serveur');
        }
    }


    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            {error &&
                                <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                            }
                            <p className="font-bold">Ajouter un lieu de cours </p>
                            <p className="text-sm text-gray-700 mt-1">
                                veuillez remplir ce formulaire pour ajouter un lieu de cours.
                            </p>
                        </div>
                    </div>
                    <div className=" flex text-center  justify-center mt-3  ">
                        <form>
                            {
                                //name
                            }
                            <div className="flex ">
                                <p className="mr-2">nom:</p>
                                <input type="text" value={name} onChange={e => setName(e.target.value)}
                                       className="shadow bg-transparent border  rounded w-auto   mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                />
                            </div>
                            {
                                //address and postal code
                            }
                            <div className="flex flex-wrap">
                                <label className="mb-2 flex">
                                    <p className="mr-2">numéro:</p>
                                    <input type="text" value={houseNumber} onChange={e => setHouseNumber(e.target.value)}
                                           className="shadow bg-transparent border  rounded w-auto   mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                    />
                                </label>
                                <label className="mb-2 flex">
                                    <p className="mr-2">rue:</p>
                                    <input type="text" value={street} onChange={e => setStreet(e.target.value)}
                                           className="shadow bg-transparent border  rounded w-auto   mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                    />
                                </label>
                            </div>
                            <div className="flex flex-wrap">
                                <label className="mb-2 flex">
                                    <p> code postal :</p>
                                    <input type="number"  value={postalCode} onChange={e => setPostalCode(e.target.value)}
                                           className="mt-1 mb-4 block w-60 bg-transparent border rounded "/>

                                </label>
                                <label className="mb-2 flex">
                                    <p> boite :</p>
                                    <input type="text" value={box} onChange={e => setBox(e.target.value)}
                                           className="mt-1 mb-4 block w-60 bg-transparent border rounded "/>

                                </label>
                            </div>
                            <label className="mb-2 flex">
                                <p> localité :</p>
                                <input type="text" value={locality} onChange={e => setLocality(e.target.value)}
                                       className="mt-1 mb-4 block w-60 bg-transparent border rounded "/>

                            </label>

                            <div className="flex">
                                <button onClick={e => {
                                    handleSubmit(e)
                                }}
                                        className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                                    ajouter le lieu
                                </button>
                                <button onClick={() => {
                                    onCancel()
                                }}
                                        className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                                    fermer
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Portal>
        </Fragment>
    );
};

export default AddLocationModal;
