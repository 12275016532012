import React, {useState} from 'react';


const PaymentInscriptionButton = ({
                                      courseList,
                                      selectedCourse,
                                      handleAddToCart,
                                      onUpdate, setError,
                                      error,
                                      outputMessage
                                  }) => {

    const getDatas = () => {
        if (courseList && selectedCourse) {
            //return price of the course for the selected semester
            return courseList.find(course => course.id === selectedCourse).price
        }
    }
    return (
        <div>
            <div className="flex flex-col items-center justify-center ">
                <div className="flex flex-col items-center justify-center ">
                    <div className="flex flex-col items-center justify-center ">
                        <h1 className="text-3xl font-bold mb-2">Prix : {getDatas()} €</h1>
                        {error &&
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                <strong className="font-bold">Erreur !</strong>
                                <span className="block sm:inline">{error}</span>
                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                                             xmlns="XXXXXXXXXXXXXXXXXXXXXXXXXX" viewBox="0 0 20 20" onClick={() => {
                                            setError(null)
                                        }}>
                                            <title>Close</title>
                                            <path
                                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                                        </svg>
                                    </span>

                            </div>
                        }
                        <div className='flex flex-row'>
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => {
                                    handleAddToCart(selectedCourse);
                                    onUpdate();
                                }}>
                                Ajouter au panier
                            </button>
                            {outputMessage &&
                                <p className="rounded text-xl m-3 p-2 shadow-lg ">{outputMessage}</p>
                            }
                        </div>
                        <i className=" justify-center w-1/3">Lors de la première inscription (cours, abonnemenet) sur
                            une période, une cotisation au club obligatoire est ajoutée dans votre panier. Cette
                            cotisation vous donne droit à des avantages décrits sur le site à cette page <a
                                href='https://www.ev-club.be/devenir-membre-EV-CAB'>lien vers les avantages</a>.</i>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default PaymentInscriptionButton;