import React, {useEffect, useState} from "react";
import * as sub from "../../../services/SubscriptionService";
import {BarLoader} from "react-spinners";
import DataTable from "react-data-table-component";
import {DataTableTheme} from "../../DataTableComponents/DataTableTheme";
import moment from "moment";


const SelectorDuration = ({
                              handleSelectDuration,
                              selectedDuration,
                              selectedAccount,
                              setStartSubscriptionDate,
                              startSubscriptionDate,
                              selectedSubscription,
                              handlSelectSubscription,
                              handleAddToCart,
                              onUpdate,
                              error,
                              setError,
                              outputMessage
                          }) => {
    const [subscriptionList, setSubscriptionList] = useState([])
    const [pending, setPending] = React.useState(true);


    useEffect(() => {
        const subtypes = async () => {
            let subtypes = await sub.subscription_list()
            // FORMAT subtypes to fit in selector

            subtypes.forEach(subtype => {
                subtype.selectButton =
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                            onClick={() => handleSelectSubscriptionLocally(subtype)}>
                        Choisir
                    </button>
                subtype.price = subtype.price + " €"
                subtype.duration = subtype.validity_period_in_months + " mois"
            });
            setSubscriptionList(subtypes)
            await setPending(false);
        }
        subtypes();
    }, []);

    const formatData = (data) => {
        let table = {
            columns: [
                {
                    name: 'description',
                    selector: row => row.description,
                    sortable: true,
                    grow: 5

                },
                {
                    name: 'prix',
                    selector: row => row.price,
                    sortable: true,

                },
                {
                    name: 'durée',
                    selector: row => row.duration,
                    sortable: true,
                    grow: 2
                },
                {
                    name: ' ',
                    selector: row => row.selectButton,

                }
            ],
            rows: data
        }
        return table
    }
    const handleSelectSubscriptionLocally = (selectedSubscription) => {
        handlSelectSubscription(selectedSubscription);
        onUpdate();
    }

    const currentDate = () => {
        let dateFormat1 = moment().format('D-MM-YYYY')
        return dateFormat1
    }
    const startSubscriptionDateFormated = (duration) =>{
        let date = new moment()
        date.add(duration,'M').calendar()
        return date.format('D-MM-YYYY')
    }

    DataTableTheme()
    return (
        <div className="flex flex-col items-center justify-center m-5">
            {selectedSubscription ?
                <div className="flex flex-col items-center justify-center ">
                    <div className=" h-screen w-full">
                        <div className="flex flex-col items-center justify-center p-3 ">
                            {/*{error &&*/}
                            {/*    <div*/}
                            {/*        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">*/}
                            {/*        <strong className="font-bold">Erreur !</strong>*/}
                            {/*        <span className="block sm:inline">{error}</span>*/}
                            {/*        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">*/}
                            {/*            <svg className="fill-current h-6 w-6 text-red-500" role="button"*/}
                            {/*                 xmlns="XXXXXXXXXXXXXXXXXXXXXXXXXX" viewBox="0 0 20 20" onClick={() => {*/}
                            {/*                setError(null)*/}
                            {/*            }}>*/}
                            {/*                <title>Close</title>*/}
                            {/*                <path*/}
                            {/*                    d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>*/}
                            {/*            </svg>*/}
                            {/*        </span>*/}

                            {/*    </div>*/}
                            {/*}*/}
                            <div className='border rounded p-2 m-2'>
                                <span className='font-bold '>
                                  description:  {selectedSubscription.description}
                                    <br/>
                                  prix:  {selectedSubscription.price}
                                    <br/>
                                    du: {currentDate()} à { startSubscriptionDateFormated(selectedSubscription.validity_period_in_months)}
                                </span>
                            </div>
                            <div className='flex flew-wrap'>
                            <button className="bg-pinkEv text-white rounded-lg p-2"
                                    onClick={() => {
                                        handleAddToCart(selectedAccount, selectedSubscription)
                                        onUpdate();
                                    }}
                            >Ajouter au panier
                            </button>
                                {outputMessage &&
                                    <p className="rounded bg-pinkEv-800 m-3 p-2 shadow-lg ">{outputMessage}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <h1 className="text-3xl font-bold mb-2">Veuillez sélectionner une durée</h1>
                    <div className="flex flex-col items-center justify-center ">
                        <div className=" h-screen w-full">
                            <div className="flex flex-col items-center justify-center p-3 ">
                                <DataTable columns={formatData(subscriptionList).columns}
                                           data={subscriptionList}
                                           showGridlines
                                           theme="TableEV"
                                           highlightOnHover
                                           progressPending={pending}
                                           progressComponent={<BarLoader color="#e44c65" size='100'/>}
                                />
                            </div>
                        </div>
                    </div>


                </div>
            }
        </div>);
}

export default SelectorDuration;