import React, {useEffect, useMemo, useState} from 'react';
import {DashboardLayout} from "../../components/Layout/Layout";
import {deleteAccount, getAllAccounts} from "../../services/AccountService";
import {setRightToTeamMember} from "../../services/AccountRightService";
import {calculateAgeOnNextJan1, ifExpiredDisconnect} from "../../utils/toolbox";
import DataTable from "react-data-table-component";
import FilterComponent from "../../components/DataTableComponents/FilterComponent";
import {DataTableTheme} from "../../components/DataTableComponents/DataTableTheme";
import AccountDetailAdmin from "../../components/Modals/AccountDetailAdmin";
import {BarLoader} from "react-spinners";
import CsvDownloadButton from "react-json-to-csv";


const AdminMemberList = () => {

    const [memberList, setMemberList] = useState([])
    const [update, setUpdate] = useState(false)
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [formatedData, setFormatedData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectMember, setSelectedMember] = useState(null)
    const [selectedParent, setSelectedParent] = useState(null)
    const [pending, setPending] = React.useState(true);


    //useeffect to fetch all inscriptions
    useEffect(() => {
        ifExpiredDisconnect()

        async function loader() {
            try {
                const data = await getAllAccounts()
                //sort data by id (higher id on top)
                data.sort((a, b) => b.id - a.id)
                setMemberList(data)

                data.forEach(member => {
                    member.allow_going_home_alone = member.allow_going_home_alone ? "oui" : "non";
                    member.add = <span> <button className=" btn-danger p-1 inline-flex  rounded "
                                                onClick={() => add_Team(member.id)}>Add to Team</button></span>;
                    member.delete = <span><button className=" btn-danger p-1 inline-flex  rounded  "
                                                  onClick={() => onDelete(member.id)}>Supprimer </button></span>;
                    member.parentId = member.parent_id
                    member.parent_id = <a className=" font-bold"
                                          onClick={() => handleSelectedParent(member.parent_id)}> {member.parent_id}</a>

                    if (member.image_right === 0) {
                        member.image_right = <span
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">non</span>
                    }else{
                        member.image_right = <span
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">oui</span>
                    }
                })
                let table = {
                    columns: [
                        {
                            name: 'id',
                            selector: row => row.id,
                            sortable: true,
                        },
                        {
                            name: 'parent',
                            selector: row => row.parent_id,
                            sortable: true,
                            grow: -2,
                            style: {
                                textDecoration: 'underline'
                            }
                        },
                        {
                            name: 'prénom',
                            selector: row => row.first_name,
                            sortable: true
                        },
                        {
                            name: 'nom',
                            selector: row => row.last_name,
                            sortable: true
                        },
                        {
                            name: 'sex',
                            selector: row => row.gender,
                            sortable: true
                        },
                        {
                            name: 'birthdate',
                            selector: row => row.birth_date,
                            sortable: true
                        },
                        {
                            name: 'email',
                            selector: row => row.email,
                            sortable: true,
                            grow: 2,
                        },
                        {
                            name: 'phone',
                            selector: row => row.phone,

                        },
                        {
                            name: 't-shirt size',
                            selector: row => row.t_shirt,
                            sortable: true
                        },
                        {
                            name: 'rentrer seul',
                            selector: row => row.allow_going_home_alone,
                            sortable: true
                        },{
                            name:'droit image',
                            selector:row=>row.image_right,
                            sortable:true
                        },
                        {
                            name: 'options',
                            selector: row => row.add,
                        },
                        {
                            name: 'delete',
                            selector: row => row.delete,
                        }

                    ],
                    rows: data
                }
                setFormatedData(table)
                await setPending(false);
            } catch (e) {
                console.log(e)
            }
        }

        loader()
    }, [update])

    DataTableTheme()

    // functions------------------------------------------
    const add_Team = (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir ajouter ce membre à la team ?")) {
            setRightToTeamMember(id).then(r => console.log(r))
        }
    }
    const onDelete = (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce compte ainsi que les données ?")) {
            //delete the stage
            deleteAccount(id).then(r =>
                console.log(r))
            setUpdate(!update)
        }
    }

    const handleSetSelectedAccount = row => {
        setSelectedMember(row)
        setShowModal(true)
    }

    const handleSelectedParent = (id) => {
        setSelectedParent(id)
    }
    const onClose = () => {
        setShowModal(!showModal)
    }

    const onUpdate = () => {
        setUpdate(!update)
    }


    // DataTable--------------------------------------

    const filteredItems = (selected) => {
        if (selected) {
            //return memberList filtered on selected.id
            return memberList.filter(item => item.id === selected.props.children[1])

        } else {
            return memberList.filter(
                item =>
                    JSON.stringify(item)
                        .toLowerCase()
                        .indexOf(filterText.toLowerCase()) !== -1
            );
        }
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const currentDate = () => {
        let date = new Date().toLocaleDateString();
        return 'liste membres EV ' + date
    }

    return (
        <React.Fragment>
            <DashboardLayout>
                <h2 className="absolute -top-8 left-10 font-sans  text-xl">Admin Member List</h2>
                <div className=" mt-14 ml-10 mr-10 content-center">
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <div>
                            <CsvDownloadButton
                                className=" border font-bold rounded p-1 border-pinkEv text-pinkEv m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white"
                                data={memberList}
                                filename={currentDate()}>
                                télécharger le CSV
                            </CsvDownloadButton>
                        </div>

                        <DataTable
                            columns={formatedData.columns}
                            data={filteredItems(selectedParent)}
                            showGridlines
                            highlightOnHover
                            subHeader
                            onRowClicked={handleSetSelectedAccount}
                            theme="TableEV"
                            progressPending={pending}
                            progressComponent={<BarLoader color='#e44c65' size='100'/>}
                            subHeaderComponent={subHeaderComponent}
                        />
                    </div>
                </div>
                {showModal &&
                    <AccountDetailAdmin onCancel={onClose} onUpdate={onUpdate} row={selectMember}/>
                }
            </DashboardLayout>
        </React.Fragment>

    )


}

export default AdminMemberList;
