import React, {useEffect, useMemo, useState} from 'react';
import {DashboardLayout} from "../../components/Layout/Layout";
import {getTeamMembers} from "../../services/AccountService";
import {getStudents} from "../../services/CourseService";
import CsvDownloadButton from 'react-json-to-csv'
import {ifExpiredDisconnect} from "../../utils/toolbox";
import DataTable from "react-data-table-component";
import {DataTableTheme} from "../../components/DataTableComponents/DataTableTheme";
import FilterComponent from "../../components/DataTableComponents/FilterComponent";
import {BarLoader} from "react-spinners";

const AdminTeamList = () => {

    const [memberList, setMemberList] = useState([])
    const [formatedMember, setFormatedMember] = useState([])
    const [pending, setPending] = React.useState(true);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    //useeffect to fetch all inscriptions
    useEffect(() => {
        ifExpiredDisconnect()
        async function loader() {
            const teamMembers = await getTeamMembers();
            await setPending(false);
            setMemberList(teamMembers)
            setFormatedMember(formatData(teamMembers))
        }
        const formatData = (data) => {
            let table = {
                columns: [
                    {
                        name: 'id',
                        selector: row =>row.id,
                        sortable: true,

                    },
                    {
                        name: 'prénom',
                        selector: row =>row.first_name,
                        sortable: true,
                    },
                    {
                        name: 'nom',
                        selector: row =>row.last_name,
                        sortable: true,
                    },
                    {
                        name: 'sex',
                        selector: row =>row.gender,
                        sortable: true,
                    },
                    {
                        name: 'birthdate',
                        selector: row =>row.birth_date,
                        sortable: true,
                    },
                    {
                        name: 'email',
                        selector: row =>row.email,
                        sortable: true,
                    },
                    {
                        name: 'phone',
                        selector: row =>row.phone,
                        sortable: true,
                    },
                    {
                        name: 't-shirt',
                        selector: row =>row.t_shirt,
                        sortable: true,
                    },

                ],
                rows: data
            }
            return table
        }
        loader()
    }, [])




    const currentDate = () => {
        let date = new Date().toLocaleDateString();
        return 'liste team EV ' + date
    }




    DataTableTheme()
    const filteredItems = memberList.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <DashboardLayout>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Admin Team List</h2>
            <div className=" mt-14 ml-10 mr-10 content-center">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <div>
                        <CsvDownloadButton
                            className=" border font-bold rounded p-1 border-pinkEv text-pinkEv m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white"
                            data={memberList}
                            filename={currentDate()}>
                            télécharger le CSV
                        </CsvDownloadButton>
                    </div>

                    <DataTable columns={formatedMember.columns}
                               data={filteredItems}
                               showGridlines
                               theme="TableEV"
                               pagination
                               highlightOnHover
                               subHeader
                               progressPending={pending}
                               progressComponent={<BarLoader color="#e44c65" size='100' />}
                               paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                               subHeaderComponent={subHeaderComponent}
                    />

                </div>
            </div>
        </DashboardLayout>
    )
}

export default AdminTeamList;
