import React, {Fragment, useState} from "react";
import Portal from "../Portal/Portal";
import * as api from "../../services/SubscriptionService"

const AddSubscriptionModal = ({onCancel, onUpdate}) => {

    const [description, setDescription] = useState();
    const [price, setPrice] = useState();
    const [error, setError] = useState();
    const [validityPeriodInMonths, setValidityPeriodInMonths] = useState()
    const [reducedPrice, setReducedPrice] = useState()

    const handleSubmit = async e => {
        e.preventDefault();
        let res = await api.create_subscription(description, price, validityPeriodInMonths,reducedPrice)

        if (res.status !== undefined) {
            onUpdate();
            onCancel();
        } else {
            setError('une erreur est apparue sur le serveur');
        }
    }
    const handleChangeTextArea = event => {
        setDescription(event.target.value)
    }


    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            {error &&
                                <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                            }
                            <p className="font-bold">Ajouter un abonnement </p>
                            <p className="text-sm text-gray-700 mt-1">
                                veuillez remplir ce formulaire pour ajouter un abonnement.
                            </p>
                        </div>
                    </div>
                    <div className=" flex text-center  justify-center mt-3  ">
                        <form>
                            <div className="flex space-x-4 ">
                                <p className="mr-2">description:</p>
                                <textarea onChange={handleChangeTextArea} defaultValue={""}
                                          className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent">
                                    </textarea>
                                <label className="mb-2 flex">
                                    <p className="mr-2">prix:</p>
                                    <input type="number" min="0" onChange={e => setPrice(e.target.value)}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                    />
                                </label>
                                <label className="mb-2 flex">
                                    <p className="mr-2">validité (en mois):</p>
                                    <input type="number" min="0" value={validityPeriodInMonths}
                                           onChange={e => setValidityPeriodInMonths(e.target.value)}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                    />
                                </label>
                                <label className="mb-2 flex">
                                    <p className="mr-2">prix jeune(-24):</p>
                                    <input type="number" min="0"
                                           onChange={e => setReducedPrice(e.target.value)}
                                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                    />
                                </label>
                            </div>

                            <div className="flex">
                                <button onClick={e => {
                                    handleSubmit(e)
                                }}
                                        className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                                    ajouter le type d'abonnement
                                </button>

                                <button onClick={() => {
                                    onCancel()
                                }}
                                        className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                                    fermer
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Portal>
        </Fragment>
    );
};

export default AddSubscriptionModal;
