import React, {Fragment, useState} from "react";
import * as  api from '../../services/AccountService';
import Portal from "../Portal/Portal";
import Select from "react-select";
import {LANG_OPTIONS, LEVEL_OPTIONS, T_SHIRT_OPTIONS} from "../../utils/constants"
import Form from 'react-bootstrap/Form';
import moment from "moment";
import InfoIconWithTooltipLeft from "../InfoIconWithTooltip/InfoIconWithTooltipLeft";
import {calculateAge} from "../../utils/toolbox";

const AddChildModal = ({description, explainerText, onCancel, onSubmit, setChildList, onUpdate}) => {

    const [gender, setGender] = useState();
    const [birthDate, setBirthDate] = useState();
    const [lastName, setLastName] = useState();
    const [firstName, setFirstName] = useState();
    const [level, setLevel] = useState();
    const [tShirt, setTShirt] = useState();
    const [dataAuthorization, setDataAuthorization] = useState(false);
    const [imageAuthorization, setImageAuthorization] = useState(false);
    const [allowGoingHomeAlone, setAllowGoingHomeAlone] = useState(false);
    const [responsibleToRetrive, setResponsibleToRetrive] = useState(null);
    const [language, setLanguage] = useState()

    const [globalError, setGlobalError] = useState();

    const handleLevelChange = level => {
        setLevel(level.value);
    }

    const handleTShirtChange = tShirt => {
        setTShirt(tShirt.value);
    }

    const handleLangChange = language => {
        setLanguage(language.value);
    }


    const handleSubmit = async e => {
        e.preventDefault();
        try {
            if (calculateAge(birthDate)<5 ){
                setGlobalError("Vous ne pouvez pas ajouter un enfant de moins de 5 ans.");
            }else {
                await api.addChild(lastName, firstName, birthDate, gender, level, tShirt, allowGoingHomeAlone, responsibleToRetrive, language, imageAuthorization);
                setChildList(await api.getCurrentUserChildrens());
                onCancel();
            }
        } catch (e) {
            setGlobalError(e.response.data.details.body[0].message);
        }
    }
    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25"/>
            <Portal>
                <div className="bg-black opacity-50 absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg p-5 fixed inset-0 m-auto max-w-lg w-full h-auto z-50 md:relative md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 overflow-y-auto max-h-screen">
                    <div className="text-center md:text-left">
                        <p className="font-bold">Ajouter un membre a votre famille</p>
                        <p className="text-sm text-gray-700 mt-1">
                            veuillez remplir ce formulaire pour ajouter un membre à votre compte.
                        </p>
                        {globalError && <p className="text-red-700 mt-1 -mb-1 font-bold">{globalError}</p>}
                    </div>
                    <div className="mt-4">
                        <form className="space-y-4">
                            {/* Name and surname */}
                            <label className="flex items-center justify-center md:justify-start">
                                <p className="text-red-700 mr-1">*</p>
                                <p className="mr-2">Nom:</p>
                                <input
                                    type="text"
                                    onChange={e => setLastName(e.target.value)}
                                    className="shadow bg-transparent border rounded w-full max-w-xs py-2 px-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                />
                            </label>

                            <label className="flex items-center justify-center md:justify-start">
                                <p className="text-red-700 mr-1">*</p>
                                <p className="mr-2">Prénom:</p>
                                <input
                                    type="text"
                                    onChange={e => setFirstName(e.target.value)}
                                    className="shadow bg-transparent border rounded w-full max-w-xs py-2 px-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                />
                            </label>

                            {/* Gender picker */}
                            <div className="flex flex-wrap items-center justify-center md:justify-start">
                                <p className="text-red-700 mr-1">*</p>
                                <p className="mr-2">Sexe:</p>
                                <div className="form-check form-check-inline">
                                    <Form.Check
                                        inline
                                        label="M"
                                        name="inlineRadioOptions"
                                        type="radio"
                                        value="M"
                                        id="inlineRadio1"
                                        onChange={e => setGender(e.target.value)}
                                    />
                                </div>
                                <div className="form-check form-check-inline">
                                    <Form.Check
                                        inline
                                        label="F"
                                        name="inlineRadioOptions"
                                        type="radio"
                                        value="F"
                                        id="inlineRadio2"
                                        onChange={e => setGender(e.target.value)}
                                    />
                                </div>
                                <div className="form-check form-check-inline">
                                    <Form.Check
                                        inline
                                        label="X"
                                        name="inlineRadioOptions"
                                        type="radio"
                                        value="X"
                                        id="inlineRadio3"
                                        onChange={e => setGender(e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Birthdate */}
                            <div className="flex items-center justify-center md:justify-start">
                                <p className="text-red-700 mr-1">*</p>
                                <p>Date de naissance:</p>
                                <input
                                    type="date"
                                    onChange={e => setBirthDate(e.target.value)}
                                    className="block w-full max-w-xs bg-transparent border rounded px-2 py-2 mt-1"
                                />
                            </div>

                            {/* Climbing level and language */}
                            <div className="flex flex-col md:flex-row md:items-center justify-center md:justify-start space-y-4 md:space-y-0 md:space-x-4">
                                <div className="flex items-center">
                                    <p className="text-red-700 mr-1">*</p>
                                    <p>Niveau d'escalade:</p>
                                    <Select
                                        className="text-black ml-2 w-full max-w-xs"
                                        defaultValue={level}
                                        options={LEVEL_OPTIONS}
                                        isSearchable={false}
                                        onChange={handleLevelChange}
                                    />
                                </div>
                                <div className="flex items-center">
                                    <p className="text-red-700 mr-1">*</p>
                                    <p>Langue:</p>
                                    <Select
                                        className="text-black ml-2 w-full max-w-xs"
                                        defaultValue={language}
                                        options={LANG_OPTIONS}
                                        isSearchable={false}
                                        onChange={handleLangChange}
                                    />
                                </div>
                            </div>

                            {/* T-shirt size */}
                            <div className="flex items-center justify-center md:justify-start">
                                <p className="text-red-700 mr-1">*</p>
                                <p>Taille de t-shirt:</p>
                                <Select
                                    className="text-black ml-2 w-full max-w-xs"
                                    defaultValue={tShirt}
                                    options={T_SHIRT_OPTIONS}
                                    isSearchable={false}
                                    onChange={handleTShirtChange}
                                />
                            </div>

                            {/* Data privacy checkbox */}
                            <div className="flex items-center justify-center md:justify-start">
                                <input
                                    type="checkbox"
                                    className="mt-1 mr-2"
                                    onChange={e => setDataAuthorization(e.target.checked)}
                                />
                                <p className="text-red-700 mr-1">*</p>
                                <p>j'accepte que les données soient utilisées dans le cadre de l'association</p>
                                <InfoIconWithTooltipLeft info={'les informations stockées sont uniquement celles nécessaires pour la gestion de l\'association, et le suivi des cours.  Nous nous engageons à ne jamais utiliser ces données à des fins commerciales.  Toute demande de suppression des données doit être faite par mail à secretariat@evolutionverticale.be'}/>
                            </div>

                            {/* Picture authorization checkbox */}
                            <div className="flex items-center justify-center md:justify-start">
                                <input
                                    type="checkbox"
                                    className="mt-1 mr-2"
                                    onChange={e => setImageAuthorization(e.target.checked)}
                                />
                                <p>j'accepte que les photos soient utilisées dans le cadre de l'association</p>
                                <InfoIconWithTooltipLeft info={'J\'accepte que les photos prises durant les activités du club peuvent servir à des fins promotionnelles sur le site internet et réseaux sociaux du club.'}/>
                            </div>

                            {/* Checkbox for going home alone */}
                            {(new Date().getFullYear() - moment(birthDate, "YYYY-MM-DD").year()) < 16 && (
                                <div>
                                    <div className="flex items-center justify-center md:justify-start mb-4">
                                        <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            onChange={e => setAllowGoingHomeAlone(e.target.checked)}
                                        />
                                        <p className="text-red-700 mr-1">*</p>
                                        <p>j'autorise mon enfant à venir et partir seul de la salle</p>
                                    </div>
                                    {!allowGoingHomeAlone && (
                                        <div className="flex items-center justify-center md:justify-start">
                                            <p className="text-red-700 mr-1">*</p>
                                            <p>Nom et prénom de la personne autorisée à venir chercher l'enfant:</p>
                                            <input
                                                type="text"
                                                onChange={e => setResponsibleToRetrive(e.target.value)}
                                                className="shadow bg-transparent border rounded w-full max-w-xs py-2 px-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Submit and cancel buttons */}
                            <div className="flex justify-center space-x-2">
                                {(lastName !== null && firstName !== null && gender !== null && birthDate !== null && dataAuthorization === true) && (
                                    <button
                                        onClick={handleSubmit}
                                        className="w-1/2 md:w-auto border rounded p-2 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white"
                                    >
                                        Ajouter membre de la famille
                                    </button>
                                )}
                                <button
                                    onClick={onCancel}
                                    className="w-1/2 md:w-auto border rounded p-2 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black"
                                >
                                    Fermer
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Portal>
        </Fragment>
    );
};

export default AddChildModal;
