import React, {Fragment, useEffect, useState} from "react";
import Select from 'react-select'
import Portal from "../Portal/Portal";
import * as api from "../../services/BrevetService"
import {
  getAllAccountsNames
} from "../../services/AccountService";
import {getBrevetsList} from "../../services/BrevetService";

const AssignBrevetModal = ({onCancel, onUpdate}) => {
  const [accounts, setAccounts] = useState([]);
  const [brevets, setBrevets] = useState([]);
  const [error, setError] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedBrevet, setSelectedBrevet] = useState();

  useEffect(() => {
    async function loader() {
      try {
        const members = await getAllAccountsNames();
        members.forEach(member => {
          member.label=member.last_name+ " "+member.first_name;
          member.value=member.id;
        })
        setAccounts(members);

        const brevs = await getBrevetsList();
        brevs.forEach(br=>{
          br.label=br.brevet + " - " + br.position;
          br.value=br.id;
        })
        setBrevets(brevs);
      } catch (e) {
        console.log(e)
      }
    }

    loader()

  }, [])

  const handleSubmit = async e => {
    e.preventDefault();
    if (selectedAccount === undefined) {
      setError("membre non choisi")
      return;
    }
    if (selectedBrevet === undefined) {
      setError("brevet non choisi")
      return;
    }


    let res = await api.assignBrevet(selectedBrevet,selectedAccount);
    if (res.status !== undefined) {
      onUpdate();
      onCancel();
    } else {
      setError('une erreur est apparue sur le serveur');
    }
  }

  const handleChangeAccount = (account) => {
    setSelectedAccount(account);
  }

  const handleChangeBrevet = (brevet) => {
    setSelectedBrevet(brevet);
  }

  return (
      <Fragment>
        <div className="fixed inset-0 bg-black opacity-25 "/>
        <Portal>
          <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
          <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
            <div className="md:flex items-center">
              <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                {error &&
                    <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                }
                <p className="font-bold">Assigner un brevet </p>
                <p className="text-sm text-gray-700 mt-1">
                  Veuillez remplir ce formulaire pour assigner un brevet.
                </p>
              </div>
            </div>
            <div className=" flex text-center  justify-center mt-3  ">
              <form>
                  <div className="w-8/9">
                    <label className="mb-2 flex">
                      <p className="mr-2">Membre:</p>
                      <Select options={accounts} isSearchable={true} onChange={(e) => {
                        handleChangeAccount(e.value)
                      }}/>
                    </label>

                    <label className="mb-2 flex">
                      <p className="mr-2">Brevet:</p>
                      <Select options={brevets} isSearchable={true} onChange={(e) => {
                        handleChangeBrevet(e.value)
                      }}/>
                    </label>
                  </div>
                <div className="flex">
                  <button onClick={e => {
                    handleSubmit(e)
                  }}
                          className="  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                    Assigner le brevet
                  </button>
                  <button onClick={() => {
                    onCancel()
                  }}
                          className="  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                    Fermer
                  </button>
                </div>
              </form>
            </div>

          </div>
        </Portal>
      </Fragment>
  );
};

export default AssignBrevetModal;
