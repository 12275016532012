import {BsCart2} from "react-icons/bs";
import React, {useContext, useEffect, useState} from "react";
import OrderModal from "../Modals/OrderModal";
import CartContext from "../../contexts/CartContext";


const CartComponent = () => {
    const [Ordermodal, setOrdermodal] = useState(false);
    const {orders, updateCart} = useContext(CartContext);



    const onCancelOrderModal = () => {
        setOrdermodal(!Ordermodal);
        updateCart();
    }

    if (orders) {
        const sum = orders.inscriptionsCourses.length + orders.inscriptionsMembers.length + orders.inscriptionsTrainingCourses.length + orders.inscriptionsSubscriptions.length + orders.inscriptionsMembersCab.length
        return (
            <div className="absolute top-0 right-0 m-3">
                <button className="transition duration-500 ease-in-out  transform hover:-translate-y-1 flex "
                        onClick={onCancelOrderModal}>
                    <div>{orders.order.total_price}€</div>
                    <BsCart2 className="h-6 w-10 hover:text-pinkEv"/>
                    <div
                        className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
                        {sum}
                    </div>
                </button>
                <>
                    {Ordermodal &&
                        <>
                            <OrderModal onCancel={onCancelOrderModal} updateOrders={updateCart} orders={orders}/>
                        </>
                    }
                </>
            </div>);
    } else {
        return (
            <div className="absolute top-0 right-0 m-3">
                <button className="transition duration-500 ease-in-out  transform hover:-translate-y-1 flex "
                        onClick={onCancelOrderModal}>
                    <div>{orders.order.total_price}€</div>
                    <BsCart2 className="h-6 w-10 hover:text-pinkEv"/>
                    <div
                        className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
                        {0}
                    </div>
                </button>
                <>
                    {Ordermodal &&
                        <>
                            <OrderModal onCancel={onCancelOrderModal} updateOrders={updateCart} orders={orders}/>
                        </>
                    }
                </>
            </div>);
    }

}
export default CartComponent;