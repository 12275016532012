import React, {useState} from "react";
import {getMutualDocument} from "../../services/AccountService";

const Child = ({child, handleDelete}) => {
    const [error,setError]=useState()
    const handleOnClick = () => {
        const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer l'enfant ?");
        if (confirmed) {
            handleDelete(child.id);
        }
    }


    const handleOnClickMutual = async () => {
        try {
            const result=await getMutualDocument(child.id);
        }catch (e){
            setError(child.first_name +' n\'as pas d\'inscriptions')
        }
    }

    return (
        <>
            <tr>
                <td className="p-3">{child.last_name}</td>
                <td className="p-3">{child.first_name}</td>
                {
                    /**
                <td className="p-4">
                    <button
                        className=" border rounded p-1 transition duration-500 ease-in-out  transform hover:-translate-y-1"
                        onClick={handleOnClick}
                    >
                        Supprimer
                    </button>
                </td>
                    **/
                }
                <td>
                    <button
                        className=" border rounded p-1 transition duration-500 ease-in-out  transform hover:-translate-y-1"
                        onClick={handleOnClickMutual}>
                        document mutuel
                    </button>

                    {error &&
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                            <strong className="font-bold">Erreur</strong>
                            <span className="block sm:inline">{error}</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                                             xmlns="" viewBox="0 0 20 20" onClick={() => {
                                            setError(null)
                                        }}>
                                            <title>Close</title>
                                            <path
                                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                                        </svg>
                                    </span>

                        </div>
                    }
                </td>
            </tr>
        </>
    )
}

export default Child;
