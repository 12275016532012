import React, {useContext, useState} from 'react';
import AccountContext from "../../contexts/AuthenticationContext";
import * as  api from '../../services/AuthenticationService';
import {Link, useNavigate} from "react-router-dom";
import {RIGHT} from "../../utils/constants";
import {BeatLoader} from "react-spinners";

const Login = () => {
    const navigate = useNavigate();

    const {authenticate} = useContext(AccountContext);

    const [errorLogin, setErrorLogin] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loader,setLoader] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        setErrorLogin(null);
        try {
            setLoader(true);
            const resultLogin = await api.login(email, password);
            if(resultLogin === 'ERR_NETWORK'){
                navigate("/server_maintenance")
            }
            if (!resultLogin.jwt) {
                setErrorLogin('⚠️e-mail ou mot de passe incorrect ⚠️');
                setLoader(false);
            } else {
                const right = await api.getRight();
                localStorage.setItem(RIGHT, right);
                authenticate(resultLogin.jwt, right);
                navigate("/home");
                setLoader(false);
            }
        } catch (e) {
            setErrorLogin('⚠️e-mail ou mot de passe incorrect ⚠️');
            setLoader(false);
        }

    }

    return (
        <div className="min-h-screen bg-backgroundEV">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center ">
                {errorLogin &&
                    <>
                        <p className='text-red-700'>{errorLogin}</p>
                    </>
                }

                <form>
                    <label className="block mb-2">
                        <p>Email/Numéro de téléphone</p>
                        <input type="text" onChange={e => setEmail(e.target.value)}
                               autoComplete="username"
                               className="shadow bg-transparent border  rounded w-full py-2 px-3  leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                               placeholder="you@example.com"/>
                    </label>
                    <label className="block mb-2">
                        <p>Mot de passe</p>
                        <input type="password" onChange={e => setPassword(e.target.value)}
                               autoComplete="current-password"
                               className="shadow bg-transparent border  rounded w-full py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"/>
                    </label>
                    <div>
                        <button type="submit"
                                className="rounded font-bold bg-pinkEv border-gray-500 border-2 mt-4 p-3 focus:border-transparent hover:border-pinkEv"
                                onClick={handleSubmit}>
                            Connexion
                            {loader &&
                            <BeatLoader  className="ml-2" color="#FFFFFF" size='10px' />
                            }
                        </button>
                    </div>
                </form>

                <Link
                    to="/forgot"
                    className="mt-5 rounded underline hover:text-pinkEv hover:border-pinkEv"
                >
                    Impossible de se connecter ?
                </Link>

                <br/>

                <Link
                    to="/"
                    className="mt-5 rounded underline hover:text-pinkEv hover:border-pinkEv"
                >
                    Retour à la liste des cours
                </Link>

                <br/>

                <Link
                    to="/register"
                    className="mt-5 rounded underline hover:text-pinkEv hover:border-pinkEv"
                >
                    S'inscrire
                </Link>
            </div>
        </div>
    );
}

export default Login
