import React, {useEffect, useState} from "react";
import Collectives from "./Subpages_Inscriptions/Collectives";
import Subscriptions from "./Subpages_Inscriptions/Subscriptions";
import Stages from "./Subpages_Inscriptions/Stages";
import {DashboardLayout} from "../../components/Layout/Layout";
import {
    verifyCanRegister
} from "../../services/InscriptionDatesService";
import {useNavigate} from "react-router-dom";


const Indoors = () => {
    const [clickInscription, setClickInscription] = useState(false)
    const [clickAbonnement, setClickAbonnement] = useState(false)
    const [clickStage, setClickStage] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        async function loader() {
            const valueRegister= await verifyCanRegister();
            if(valueRegister!==1){
                const currentPath = window.location.pathname;
                const pathParts = currentPath.split('/');
                const pop = pathParts.pop();
                if(pop==="indoor"){
                    const parentPath = pathParts.join('/');
                    navigate(parentPath);
                }
            }
        }
        loader()
    }, [navigate])

    const handleClickInscription = () => {
        setClickAbonnement(false);
        setClickStage(false);
        setClickInscription(!clickInscription);
    }
    const handleClickAbonnement = () => {
        setClickInscription(false);
        setClickStage(false);
        setClickAbonnement(!clickAbonnement);
    }
    const handleClickStage = () => {
        setClickInscription(false);
        setClickAbonnement(false);
        setClickStage(!clickStage);
    }
    if (clickInscription) {
        // return responsive button to close the inscription
        return (
            <DashboardLayout>
                <h2 className="absolute -top-8 left-10 font-sans  text-xl">Indoor</h2>
                <div className="flex-wrap ">
                    <div
                        className=" desktop:flex tablet:flex-wrap  bg-gray-700  m-auto rounded-lg  p-5 mt-5 w-max">
                        <div>
                            <button
                                className=" m-3 border-black p-3 bg-pinkEv  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-gray-500 hover:text-white  "
                                onClick={handleClickInscription}>Cours
                                collectif
                            </button>
                        </div>
                        <div>
                            <button
                                className=" m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv"
                                onClick={handleClickAbonnement}>Abonnement
                                Grimpe
                            </button>
                        </div>
                        <div>
                            <button
                                className="  m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv"
                                onClick={handleClickStage}>Stage
                                Indoor
                            </button>
                        </div>
                    </div>
                    <div>
                        {clickInscription &&
                            <Collectives/>
                        }
                    </div>
                </div>

            </DashboardLayout>
        )
    } else if (clickAbonnement) {
        return (
            <DashboardLayout>
                <h2 className="absolute -top-8 left-10 font-sans  text-xl">Indoor</h2>
                <div className="flex-wrap ">
                    <div
                        className=" desktop:flex tablet:flex-wrap  bg-gray-700  m-auto rounded-lg p-5 mt-10 w-max">
                        <div>
                            <button
                                className=" m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white  "
                                onClick={handleClickInscription}>Cours
                                collectif
                            </button>
                        </div>
                        <div>
                            <button
                                className=" m-3 border-black p-3 bg-pinkEv  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-gray-500"
                                onClick={handleClickAbonnement}>Abonnement
                                Grimpe
                            </button>
                        </div>
                        <div>
                            <button
                                className="  m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv"
                                onClick={handleClickStage}>Stage
                                Indoor
                            </button>
                        </div>
                    </div>
                    <div>
                        {clickAbonnement &&
                            <Subscriptions retour={handleClickAbonnement}/>
                        }
                    </div>
                </div>

            </DashboardLayout>
        )
    } else if (clickStage) {
        return (
            <DashboardLayout>
                <h2 className="absolute -top-8 left-10 font-sans  text-xl">Indoor</h2>
                <div className="flex-wrap ">
                    <div
                        className=" desktop:flex tablet:flex-wrap  bg-gray-700  m-auto rounded-lg p-5 mt-10 w-max">
                        <div>
                            <button
                                className=" m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white  "
                                onClick={handleClickInscription}>Cours
                                collectif
                            </button>
                        </div>
                        <div>
                            <button
                                className=" m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv"
                                onClick={handleClickAbonnement}>Abonnement
                                Grimpe
                            </button>
                        </div>
                        <div>
                            <button
                                className="  m-3 border-black p-3 bg-pinkEv  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-gray-500"
                                onClick={handleClickStage}>Stage
                                Indoor
                            </button>
                        </div>
                    </div>
                    <div>
                        {clickStage &&
                            <Stages retour={handleClickStage}/>
                        }
                    </div>
                </div>

            </DashboardLayout>
        )
    } else {
        return (
            <DashboardLayout>
                <h2 className="absolute -top-8 left-10 font-sans  text-xl">Indoor</h2>
                <div className="flex-wrap ">
                    <div
                        className=" desktop:flex tablet:flex-wrap  bg-gray-700  m-auto rounded-lg p-5 mt-5 w-max absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                        <div>
                            <button
                                className=" m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white  "
                                onClick={handleClickInscription}>Cours
                                collectif
                            </button>
                        </div>
                        <div>
                            <button
                                className=" m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv"
                                onClick={handleClickAbonnement}>Abonnement
                                Grimpe
                            </button>
                        </div>
                        <div>
                            <button
                                className="  m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv"
                                onClick={handleClickStage}>Stage
                                Indoor
                            </button>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        )
    }
}

export default Indoors;
