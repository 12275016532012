import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "subscriptions"
})

const subscription_list= async () => {
    try {
       let data = await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}});
        return data.data;
    } catch (e) {
        console.error(e);
    }
}

const create_subscription = async (description, price,validityPeriodInMonths,reducedPrice) => {
    try {
        const payload = {
            description: description,
            price: parseInt(price),
            validityPeriodInMonths: validityPeriodInMonths,
            reducedPrice: reducedPrice,
        }
        let data = await client.post("/",payload, {headers: {'Authorization': localStorage.getItem('token')}});
       return data;

    } catch (e) {
        console.error(e.message);
        return e;
    }
}

const manual_inscription_subscription = async (accountId, subscriptionId) => {
    try {
        const payload = {
            accountId: accountId,
            subscriptionId: subscriptionId
        }
        return await axios.post(url+"inscriptions/subscriptions/manual", payload, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        throw e
    }
}

const delete_subscription = async (id) => {
    try {
        return await client.delete("/" + id, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e)
    }
}

const update_subscription = async (id, description, price, validityPeriodInMonths,reducedPrice) => {
    try {
        const payload = {
            description: description,
            price: parseInt(price),
            validityPeriodInMonths: validityPeriodInMonths,
            reducedPrice: reducedPrice,
        }
        return await client.put("/" + id, payload, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e)
    }
    return null;
}

export {
    subscription_list,create_subscription,delete_subscription,update_subscription,manual_inscription_subscription
}
