import React from "react";

import {NavSidebarAdmin} from "../NavSidebar/NavSidebarAdmin";
import {NavSidebarTeamMember} from "../NavSidebar/NavSidebarTeamMember";
import BodyWrapper from "../BodyWrapper/BodyWrapper";
import {NavSidebarUser} from "../NavSidebar/NavSidebarUser";
import {ScrollToTop} from "../ScrollTop/ScrollToTop";

export const DashboardLayout = ({right, children}) => {
    const getNavSidebar = (right) => {
        switch (right) {
            case "admin":
                return <NavSidebarAdmin/>;
            case "team_member":
                return <NavSidebarTeamMember/>;
            case "user":
                return <NavSidebarUser/>;
            case "underage_user":
                return <NavSidebarUser/>
            default:
                return null;
        }
    }


    return (
        <BodyWrapper>
            {getNavSidebar(right)}
            <div className="flex flex-col flex-1 overflow-hidden ">
                <main className="content ">
                    <section className="sm:flex-row flex flex-col flex-1 ">
                        <div
                            className="content-box"
                            style={{flexGrow: 2, flexBasis: "0%"}}
                        >
                            {children}
                        </div>
                    </section>
                </main>
                <ScrollToTop/>
            </div>
        </BodyWrapper>
    );
};
