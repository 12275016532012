import React, {Fragment, useEffect, useState} from "react";
import Portal from "../Portal/Portal";
import * as api from "../../services/BrevetService"
import {getOwnedBrevets} from "../../services/AccountService";

const AssignBrevetStudentModal = ({onCancel, student}) => {
  const [baseBrevets, setBaseBrevets] = useState([]);
  const [error, setError] = useState();
  const [outputMessage , setOutputMessage] = useState();
  const [allBrevets, setAllBrevets]=useState([]);

  useEffect(() => {
    async function loader(){
      try{
        const brevs = await getOwnedBrevets(student.value);
        brevs.forEach(br=>{
          br.label=br.brevet + " - " + br.position;
          br.value=br.id;
        })
        setBaseBrevets(brevs);
        setAllBrevets(brevs);
      }catch (e) {
        console.log(e);
      }
    }

    loader();

  }, []);

  const handleCheckboxChange = (id) => {
    setAllBrevets((prevBrevets) =>
        prevBrevets.map((brevet) =>
            brevet.id === id ? { ...brevet, assigned: !brevet.assigned } : brevet
        )
    );
  };

  const groupAndSortBrevets = (brevets) => {
    // Group by brevet
    const groupedBrevets = brevets.reduce((acc, brevet) => {
      (acc[brevet.brevet] = acc[brevet.brevet] || []).push(brevet);
      return acc;
    }, {});

    // Sort each brevet by position
    for (const brevet in groupedBrevets) {
      groupedBrevets[brevet].sort((a, b) => a.position - b.position);
    }

    const sortedBrevets = Object.keys(groupedBrevets).sort();

    return {groupedBrevets,sortedBrevets};
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Process the updated items list
    try{
      allBrevets.forEach(br=>{
        if(br.assigned===true){
          if(baseBrevets.find(brev=>brev.id===br.id).assigned===false){
            const assign = api.assignBrevet(br.id,student.value);
          }
        }else{
          if(baseBrevets.find(brev=>brev.id===br.id).assigned===true){
            const remove= api.removeAssignedBrevet(br.id,student.value);
          }
        }
      })
      setOutputMessage('modifié✅')
    }catch (e) {
      setOutputMessage('erreur❌')
    }
  };

  const { groupedBrevets, sortedBrevets } = groupAndSortBrevets(allBrevets);

    return (
        <Fragment>
          <div className="fixed inset-0 bg-black opacity-25" />
          <Portal>
            <div onClick={onCancel} className="bg-black opacity-50 fixed inset-0 z-10" />
            <div
                className="bg-white rounded-lg p-4 fixed inset-0 m-auto z-50 md:relative flex flex-col justify-center items-center overflow-auto"
                style={{
                  maxWidth: '100%',
                  maxHeight: '95%',
                  width: '100%',
                  padding: '2rem'
                }}
            >
              <button onClick={onCancel} className="absolute top-0 right-0 mt-4 mr-4 text-gray-500 hover:text-gray-700">
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <div className="mt-3 md:flex items-center w-full md:w-auto">
                <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                  {error && (
                      <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg">
                        {error}
                      </h1>
                  )}
                  <p className="font-bold">Gérer les brevets</p>
                  <p className="text-sm text-gray-700 mt-1">
                    Veuillez remplir ce formulaire pour gérer les brevets de {student.label}.
                  </p>
                </div>
              </div>
              <form onSubmit={handleSubmit} className="p-4 overflow-auto">
                {sortedBrevets.map((brevet, index) => (
                    <div key={brevet} className="mb-4">
                      <h2 className="text-xl font-bold mb-2">{brevet}</h2>
                      <ul>
                        {groupedBrevets[brevet].map((br) => (
                            <li key={br.id} className="mb-2 flex items-center">
                              <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={br.assigned}
                                    onChange={() => handleCheckboxChange(br.id)}
                                    className="mr-2"
                                />
                                {br.position} - {br.description}
                              </label>
                            </li>
                        ))}
                      </ul>
                      {index < sortedBrevets.length - 1 && <hr />}
                    </div>
                ))}
                <button
                    type="submit"
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded p-1 m-1 transition duration-500 ease-in-out transform hover:-translate-y-1"
                >
                  Submit
                </button>
                <button onClick={onCancel}
                        className="mt-4 bg-gray-300 text-black py-2 px-4 rounded p-1 m-1 transition duration-500 ease-in-out transform hover:-translate-y-1">
                  Fermer
                </button>
                <label>{outputMessage}</label>
              </form>
            </div>
          </Portal>
        </Fragment>
    );

};

export default AssignBrevetStudentModal;
