import React, {useEffect, useMemo, useState} from 'react';

import {DashboardLayout} from '../../components/Layout/Layout';
import * as apiCourses from "../../services/CourseService";
import * as apiLocation from "../../services/LocationService";
import * as apiSection from "../../services/SectionService";
import ShowStudentsCourseModal from "../../components/Modals/ShowStudentsCourseModal";
import {ifExpiredDisconnect} from "../../utils/toolbox";
import DataTable from "react-data-table-component";
import {BarLoader} from "react-spinners";
import {DataTableTheme} from "../../components/DataTableComponents/DataTableTheme";
import FilterComponent from "../../components/DataTableComponents/FilterComponent";

const TeamCourseList = ({right}) => {
    const [selectedCourse, setSelectedCourse] = useState(false);
    const [sectionModal, setSectionModal] = useState(false);
    const [locationModal, setLocationModal] = useState(false);
    const [membersList, setMembersList] = useState([]);
    const [courses, setCourses] = useState([]);
    const [locations, setLocations] = useState([]);
    const [sections, setSections] = useState([]);
    const [update, setUpdate] = useState(false);
    const current_id = parseInt(localStorage.getItem('userid'));
    const [pending, setPending] = React.useState(true);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [showStudentModal, setShowStudentModal] = useState(false);
    const [selectedCourseToShowDetails, setSelectedCourseToShowDetails] = useState();

    useEffect(() => {
        let array = []
        ifExpiredDisconnect()
        const getdatas = async () => {
            const output = await Promise.all([apiCourses.getCoursesList(), apiLocation.getLocationList(), apiSection.getSectionList(),,apiCourses.places()]);
            // courses
            //for each course replace the location_id by the location name and the section_id by the section description
            output[0].forEach(course => {
                let location = output[1].find(location => location.id === course.location_id)
                let section = output[2].find(section => section.id === course.section_id)
                let coursesWithPlaces = output[4].find(courseW => courseW.id === course.id)
                course.countInscriptions = coursesWithPlaces.countInscriptions
                course.ref = course.reference.substring(0, 8) + course.id + course.week_day.substring(0, 3)
                course.location_id = location.name
                course.section_id = section.description
                course.horaire = course.begin_time.substring(0, 5) + " à " + course.end_time.substring(0, 5)
            });
            setCourses(output[0]);
            // location
            setLocations(output[1]);
            array = []
            output[1].forEach(location => {
                array.push({value: location.id, label: location.name})
            })
            setLocations(array);
            // section
            setSections(await output[2]);
            array = []
            output[2].forEach(section => {
                array.push({value: section.id, label: section.description})
            })
            setSections(array);
        }
        // const getMembers = async () => {
        //     const output = await getAllAccounts();
        //     setMembersList(output);
        //     await setPending(false);
        // }
        getdatas();
        // getMembers();
        setPending(false);

    }, [update]);


    const onUpdate = () => {
        setUpdate(!update)
    }

    const handleShowStudentModal = () => {
        setShowStudentModal(!showStudentModal)
    }


    const handleSetShowDetailsCourse = row => {
        setSelectedCourseToShowDetails(row.id)
        setShowStudentModal(!showStudentModal)
    }

//           DATATABLE

    const formatData = (data) => {
        let table = {
            columns: [
                {
                    name: 'ref',
                    selector: row => row.ref,
                    sortable: true,
                    grow:2

                },
                {
                    name: 'section',
                    selector: row => row.section.description +'('+row.section.min_age+'/'+row.section.max_age+')',
                    sortable: true,
                    grow:2
                },
                {
                    name: 'jour',
                    selector: row => row.week_day,
                    sortable: true,
                },
                {
                    name: 'horaire',
                    selector: row => row.horaire,
                    sortable: true,
                },
                {
                    name: 'places',
                    selector: row => row.max_capacity-row.countInscriptions+'/'+row.max_capacity,
                    sortable: true,
                },
                {
                    name: 'prix',
                    selector: row => row.price,
                    sortable: true,
                },
                {
                    name: 'lieu',
                    selector: row => row.location_id,
                    sortable: true,
                },
                {
                    name: 'visible',
                    selector: row => row.visible,
                    sortable: true,
                },
                {
                    name: 'option',
                    selector: row => row.options,
                }

            ],
            rows: data
        }
        return table
    }

    DataTableTheme()
    const filteredItems = courses.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <DashboardLayout right={right}>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">CourseList</h2>
            <div className=" h-screen">
                <div className=" mt-14 ml-10 mr-10 content-center h-full overflow-auto">
                    <div className=" overflow-auto shadow-md sm:rounded-lg h-full ">
                        <DataTable columns={formatData(courses).columns}
                                   data={filteredItems}
                                   showGridlines
                                   theme="TableEV"
                                   pagination
                                   highlightOnHover
                                   subHeader
                                   progressPending={pending}
                                   progressComponent={<BarLoader color="#e44c65" size='100'/>}
                                   paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                                   onRowClicked={handleSetShowDetailsCourse}
                                   subHeaderComponent={subHeaderComponent}
                        />
                    </div>
                </div>
            </div>
            {showStudentModal && <ShowStudentsCourseModal selectedCourse={selectedCourseToShowDetails} onCancel={handleShowStudentModal}/>}
        </DashboardLayout>
    )
}

export default TeamCourseList;
