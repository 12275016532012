import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {getPlaces} from "../../services/CourseService";
import {getSectionList} from "../../services/SectionService";
import {getLocationList} from "../../services/LocationService";
import CourseList from "../../components/landingComponents/CourseList";

const LandingPage = () => {
    const [showCourseList, setShowCourseList] = useState(true);
    const [sections, setSections] = useState([]);
    const [places, setPlaces] = useState([])
    const [locations, setLocations] = useState([])
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            const resultPromise = await Promise.all([getSectionList(), getPlaces(), getLocationList()])
            setSections(await resultPromise[0]);
            setLocations(await resultPromise[2]);
            let received = await resultPromise[1];
            //for each place set section with the correspondent section the pk is section_id
            received.forEach(place => {
                place.section = resultPromise[0].find(section => section.id === place.section_id)
                place.location = resultPromise[2].find(location => location.id === place.location_id)
            })
            setPlaces(received);
        }
        loadData()
    }, [update])

    const handleUpdate = () => {
        setUpdate(!update);
    }

    return (
        <div className=" min-h-screen bg-backgroundEV ">
            {showCourseList ?
                <div className=" flex absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2  tablet:flex-wrap ">
                    <div>
                        <button
                            className=" m-2 rounded font-bold bg-transparent border-gray-400 border-2  p-3 hover:text-pinkEv hover:border-pinkEv "
                            onClick={() => {
                                handleUpdate();
                                setShowCourseList(!showCourseList);
                            }}
                        >
                            Voir la liste des cours
                        </button>
                    </div>
                    <div>
                        <Link to="/login">
                            <button
                                className=" m-2 rounded font-bold bg-pinkEv border-gray-500 border-2  p-3  text-white  focus:border-transparent hover:border-pinkEv">
                                Se connecter
                            </button>
                        </Link>
                    </div>
                    <div>
                        <Link to="/register">
                            <button
                                className=" m-2 rounded font-bold bg-transparent border-gray-400 border-2  p-3 text-white hover:border-pinkEv ">
                                S'inscrire
                            </button>
                        </Link>
                    </div>
                </div>
                :
                //display the same menu but centered on top of the screen and below the course list
                <div className="w-full desktop:flex desktop:justify-center desktop:items-center " >
                    <div className="justify-center  space-x-4  space-y-4 text-white">
                        <div className="flex flex-wrap">
                            <div>
                                <button
                                    className=" m-2 rounded font-bold bg-transparent border-gray-400 border-2  p-3 hover:text-pinkEv hover:border-pinkEv "
                                    onClick={() => {
                                        handleUpdate();
                                        setShowCourseList(!showCourseList);
                                    }}
                                >
                                    masquer la liste des cours
                                </button>
                            </div>
                            <div>
                                <Link to="/login">
                                    <button
                                        className=" m-2 rounded font-bold bg-pinkEv border-gray-500 border-2  p-3  text-white  focus:border-transparent hover:border-pinkEv">
                                        Se connecter
                                    </button>
                                </Link>
                            </div>
                            <div>
                                <Link to="/register">
                                    <button
                                        className=" m-2 rounded font-bold bg-transparent border-gray-400 border-2  p-3 text-white hover:border-pinkEv ">
                                        S'inscrire
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="">
                            <div>
                                <CourseList courseList={places}/>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </div>

    )
}


export default LandingPage;
