import {Navigate, Outlet} from "react-router-dom";
import React from "react";

const DefaultLayout = ({right}) => {
    switch (right) {
        case "user":
            return <Navigate to="/user/home" replace/>
        case "member":
            return <Navigate to="/member/home" replace/>
        case "team_member":
            return <Navigate to="/team-member/home" replace/>
        case "admin":
            return <Navigate to="/admin/home" replace/>
    }

    return (
        <Outlet/>
    )
}

export default DefaultLayout;
