import Select from "react-select";
import React from "react";

const SelectorStage = ({
                           selectedStageList,
                           handleSelectedStage,
                           selectedStage,
                           handleAddToCart,
                           onUpdate,
                           selectedAccount,
                           error,
                           setError,
                           outputMessage
                       }) => {

    return (
        <div className="flex flex-col items-center justify-center m-5">
            <h1 className="text-3xl font-bold mb-2">Veuillez sélectionner un stage</h1>
            <div className="flex flex-col items-center justify-center ">
                <div className=" h-screen w-full">
                    <Select className="text-black " options={selectedStageList} onChange={(e) => {
                        handleSelectedStage(e.value)
                    }}/>
                    {selectedStage &&
                        <div className="flex flex-col items-center justify-center p-3 ">
                            {error &&
                                <div
                                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                    <strong className="font-bold">Erreur !</strong>
                                    <span className="block sm:inline">{error}</span>
                                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                                             xmlns="XXXXXXXXXXXXXXXXXXXXXXXXXX" viewBox="0 0 20 20" onClick={() => {
                                            setError(null)
                                        }}>
                                            <title>Close</title>
                                            <path
                                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                                        </svg>
                                    </span>

                                </div>
                            }
                            <div className='flex flex-row'>
                                <button className="bg-pinkEv text-white rounded-lg p-2"
                                        onClick={() => {
                                            handleAddToCart(selectedStage, selectedAccount)
                                            onUpdate();
                                        }}
                                >Ajouter au panier
                                </button>
                                {outputMessage &&
                                    <p className="rounded text-xl m-3 p-2 shadow-lg ">{outputMessage}</p>
                                }
                            </div>

                        </div>
                    }
                </div>
            </div>
        </div>)
}
export default SelectorStage;