import React, {useEffect, useState} from 'react';
import {DashboardLayout} from "../../components/Layout/Layout";
import {MDBDataTable} from "mdbreact";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import AddSubscriptionModal from "../../components/Modals/AddSubscriptionModal";
import * as apiSubscription from "../../services/SubscriptionService";
import {deleteStageCategory} from "../../services/StageService";
import {delete_subscription} from "../../services/SubscriptionService";
import {ifExpiredDisconnect} from "../../utils/toolbox";
import EditSubscriptionModal from "../../components/Modals/EditSubscriptionModal";


const AdminSubscription = () => {
    const [addSubscriptionModal, setAddSubscriptionModal] = useState(false);
    const [update, setUpdate] = useState(false);
    const [formatedList,setFormatedList] = useState([])
    const [idSortingDesc,setIdSortingDesc] = useState(false)
    const [subscriptionToEdit,setSubscriptionToEdit] = useState()
    const [editSubscriptionModal,setEditSubscriptionModal] = useState(false)

    useEffect(() => {
        if (!ifExpiredDisconnect()) {
            async function loader() {
                const response = await apiSubscription.subscription_list()
                if (response !== undefined) {
                    setFormatedList(formatData(response))
                } else {
                    setFormatedList([])
                }
            }

            loader()
        }
    }, [update])
    const onUpdate = () => {
        setUpdate(!update)
    }
    const onCancelAddSubscriptionModal = () => {
        setAddSubscriptionModal(false);
    }
    const onCancelEditSubscriptionModal = () => {
        setEditSubscriptionModal(false);
    }
    const handleClick = (data) => {
        //e.preventDefault()
        if(idSortingDesc){
            //sort the data by the id in ASC
            if(!idSortingDesc){
                data.sort((a,b) => {
                    return a.id - b.id
                })
                setIdSortingDesc(true)
            }
        }else {
            //sort the data by the id in DSC
            if(idSortingDesc){
                data.sort((a,b) => {
                    return b.id - a.id
                })
                setIdSortingDesc(false)
            }
        }
    }


    const onDelete = (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet abonnement ?")) {
            //delete the stage
            delete_subscription(id).then(r =>
                console.log(r))
            onUpdate()
        }
    }
    const formatData = (data) => {
        if(data!==undefined) {
            //assign to all type this style  className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"
            data.forEach(item => {
                item.edit = <span> <button className="btn btn-warning p-1 inline-flex font-semibold  "
                                               onClick={() => {
                                                   setEditSubscriptionModal(true)
                                                   setSubscriptionToEdit(item)
                                                   onUpdate()
                                               }}>Modifier</button> </span>
                item.delete = <span> <button className="btn btn-danger p-1 inline-flex font-semibold  "
                                             onClick={() => onDelete(item.id)}>Supprimer</button> </span>

                item.ref = item.reference.substring(0,8)+item.id
            })
            let table = {
                columns: [
                    {
                        label: 'id',
                        field: 'id',
                        sort: "asc",
                        width: 15
                    },
                    {
                        label: 'reference',
                        field: 'ref',
                        sort: "asc",
                        width: 270
                    },
                    {
                        label: 'description',
                        field: 'description',
                        sort: "asc",
                        width: 200
                    },{
                        label: 'durée (mois)',
                        field: 'validity_period_in_months',
                        sort: "asc",
                        width: 200
                    }, {
                        label: 'prix',
                        field: 'price',
                        sort: "asc",
                        width: 200
                    }, {
                        label: 'prix(-24ans)',
                        field: 'reduced_price',
                        sort: "asc",
                        width: 200

                    },
                    {
                        label: 'edit',
                        field: 'edit',
                        width: 150,
                        sort: 'asc',
                    },
                    {
                        label: 'supprimer',
                        field: 'delete',
                        width: 150,
                        sort: 'asc',

                    }
                ],
                rows: data
            }
            return table
        }
    }


    return (
        <DashboardLayout>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Admin abonnement</h2>
            <div className=" mt-14 ml-10 mr-10 content-center">
                <button
                    onClick={() => {
                        setAddSubscriptionModal(true)
                    }}
                    className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                    ajouter un abonnement
                </button>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <MDBDataTable theadTextWhite={true} tbodyTextWhite={true} striped bordered
                                  data={formatedList} noBottomColumns={true} searching={true}/>
                </div>
            </div>
            {addSubscriptionModal &&
                <AddSubscriptionModal onCancel={onCancelAddSubscriptionModal} onUpdate={onUpdate} subscription={subscriptionToEdit}/>
            }
            {editSubscriptionModal &&
                <EditSubscriptionModal onCancel={onCancelEditSubscriptionModal} onUpdate={onUpdate} subscription={subscriptionToEdit}/>
            }
        </DashboardLayout>
    )
}

export default AdminSubscription;
