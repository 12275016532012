import {Navigate, Outlet} from "react-router-dom";
import React from "react";
import {NavSidebarAdmin} from "../../components/NavSidebar/NavSidebarAdmin";

const AdminLayout = ({right}) => {
    if (right !== "admin") {
        return <Navigate to="/"/>;
    }

    return (
        <>
            <NavSidebarAdmin/>
            <Outlet/>
        </>
    );
}

export default AdminLayout;
