import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "courses"
})

const getCourses = async () => {
    try {
        const courses = await client.get("/")
        return courses.data
    } catch (e) {
        console.error(e)
    }
}

const getCourse = async (id) => {
    try {
        const course = await client.get("/" + id, {headers: {'Authorization': localStorage.getItem('token')}})
        return course.data
    } catch (e) {
        console.error(e)
    }
}
const getCoursesList = async () => {
    try {
        const list = await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}})
        return list.data
    } catch (e) {
        console.error(e)
    }
}

const addCourse = async (sectionId,responsible ,locationId, weekDay, beginTime, endTime, maxCapacity, price, beginDate, endDate, reserved) => {
    try {
        const payload = {
            teacherId: responsible,
            sectionId: sectionId,
            locationId: locationId,
            weekDay: weekDay,
            beginTime: beginTime,
            endTime: endTime,
            maxCapacity: parseInt(maxCapacity),
            price:price,
            beginDate: beginDate,
            endDate: endDate,
            reserved: reserved,
        }
        return await client.post("/", payload, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e);
    }
}
const duplicateCourse = async (id) => {
    try {
        await client.post("/" + id + "/duplicate", {}, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e);
    }
}

const places =  async (id) => {
    try {
        let result= await client.get("/places", {headers: {'Authorization': localStorage.getItem('token')}})
        return result.data
    }catch (e){
        console.error(e);
    }
}

const deleteCourse = async (courseId) => {
    try {
        await client.delete("/" + courseId, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e);
    }
}

const getStudents = async (courseId) => {
    try {
        const students = await client.get("/" + courseId + "/members", {headers: {'Authorization': localStorage.getItem('token')}})
        return students.data
    } catch (e) {
        console.error(e);
    }
}

const getPlaces = async (courseId) => {
    try {
        const places = await client.get("/places")
        return places.data
    } catch (e) {
        console.error(e);
    }
}

const visibilityOfLocation = async (locationId, visibility) => {
    const payload={
        locationId:locationId,
        reserved:visibility
    }
    try {
        await client.put("/visibility", payload, {headers: {'Authorization': localStorage.getItem('token')}})
    }catch (e){
        console.error(e);
    }
}
const visibilityOfCourse = async (id,visibility) => {
    const payload={
        reserved:visibility
    }
    try {
        await client.put("/"+id+"/visibility", payload, {headers: {'Authorization': localStorage.getItem('token')}})
    }catch (e){
        console.error(e);
    }
}

const editCourse = async (id, sectionId, responsible, locationId, weekDay, beginTime, endTime, maxCapacity, price, beginDate, endDate, reserved) => {
    const payload = {
        teacherId: responsible,
        sectionId: sectionId,
        locationId: locationId,
        weekDay: weekDay,
        beginTime: beginTime,
        endTime: endTime,
        maxCapacity: parseInt(maxCapacity),
        price:price,
        beginDate: beginDate,
        endDate: endDate,
        reserved: reserved,
    }
    try {
        await client.put("/" + id, payload, {headers: {'Authorization': localStorage.getItem('token')}})
    } catch (e) {
        console.error(e);
    }
}


export {
    getCoursesList, addCourse, duplicateCourse, deleteCourse, getCourse,getCourses,places,getStudents,getPlaces,visibilityOfLocation,visibilityOfCourse,editCourse
}
