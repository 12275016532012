import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import AccountContext from "../../contexts/AuthenticationContext";

const Disconnect = () => {
    const navigate = useNavigate();

    const {disconnect} = useContext(AccountContext);

    const handleDisconnect = () => {
        disconnect();
        navigate("/");
    }

    return (
        <button
            className='absolute w-full my-1 border-1 rounded bg-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1 '
            onClick={handleDisconnect}
        >
            Déconnexion
        </button>
    );
}

export default Disconnect;
