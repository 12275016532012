import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
  baseURL: url + "inscriptions"
})

const addInscriptionDates = async (year, oldMemberDate, newMemberDate) => {
  try {
    const payload = {
      year:year,
      oldMemberDate: oldMemberDate,
      newMemberDate: newMemberDate
    }
    return await client.post("/dates", payload, {headers: {'Authorization': localStorage.getItem('token')}});
  } catch (e) {
    console.error(e);
    throw e;
  }
}

const getAllInscriptionDates = async () => {
  try {
    const inscriptionDates = await client.get("/dates", {headers: {'Authorization': localStorage.getItem('token')}});
    return inscriptionDates.data;
  } catch (e) {
    console.error(e);
  }
}

const getInscriptionDates = async (year) => {
  try {
    const inscriptionDate = await client.get("/dates/" + year, {headers: {'Authorization': localStorage.getItem('token')}});
    return inscriptionDate.data;
  } catch (e) {
    throw e
  }
}

const deleteInscriptionYear = async (year) => {
  try {
    await client.delete("/dates/" + year, {headers: {'Authorization': localStorage.getItem('token')}})
  } catch (e) {
    console.error(e);
  }
}

const editInscriptionDate = async (year,oldMemberDate,newMemberDate) => {
  if(oldMemberDate==="")oldMemberDate=null;
  if(newMemberDate==="")newMemberDate=null;
  const payload = {
    year:year,
    oldMemberDate: oldMemberDate,
    newMemberDate: newMemberDate
  }
  try {
    await client.put("/dates/"+year, payload, {headers: {'Authorization': localStorage.getItem('token')}})
  } catch (e) {
    console.error(e);
    throw e;
  }
}

const fullyCloseInscriptionDate = async(year)=>{
  try{
    const payload={year:year};
    await client.put("/dates/open/"+year+"/"+1,payload,{headers: {'Authorization': localStorage.getItem('token')}})
  }catch (e){
    console.error(e);
    throw e;
  }
}

const openInscriptionDate = async(year)=>{
  try{
    const payload={year:year};
    await client.put("/dates/open/"+year+"/"+0,payload,{headers: {'Authorization': localStorage.getItem('token')}})
  }catch (e){
    console.error(e);
    throw e;
  }
}

const verifyCanRegister = async () => {
  try {
    const response = await client.get("/canRegister", {headers: {'Authorization': localStorage.getItem('token')}});
    if (response.status === 200) {
      return 1;
    }
    return 0;
  } catch (e) {
    if(e.response.status===404){
      return 2;
    } else if(e.response.status===403){
      return 3;
    }else if (e.request) {
      // Request was made but no response was received
      console.error('No response received:', e.request);
      return 4;
    }
    console.error(e);
    return 4;
  }
}

export {
  addInscriptionDates, getAllInscriptionDates,getInscriptionDates,deleteInscriptionYear,editInscriptionDate,fullyCloseInscriptionDate,openInscriptionDate,verifyCanRegister
}
