import React, {Fragment, useEffect, useState} from "react";
import Select from 'react-select'
import Portal from "../Portal/Portal";
import * as api from "../../services/StageService"
import isPostalCode from "validator/es/lib/isPostalCode";
import {stageCategories} from "../../services/StageService";

const AddStageModal = ({onCancel, onUpdate}) => {
    const [categories, setCategories] = useState();
    const [description, setDescription] = useState();
    const [maxCapacity, setMaxCapacity] = useState();
    const [begin_date, setBegin] = useState();
    const [end_date, setEnd] = useState();
    const [size, setSize] = useState();
    const [price, setPrice] = useState();
    const [priceForTeamMember, setPriceForTeamMember] = useState();
    const [reserved, setReserved] = useState(0);
    const [error, setError] = useState();
    const [selectedType, setSelectedType] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const types = [
        {value: 0, label: 'indoor'},
        {value: 1, label: 'outdoor'},
    ]

    useEffect(() => {
        async function loader() {
            try {
                const categories = await stageCategories()
                //format categories for select component
                categories.forEach(category => {
                    category.label = category.name
                    category.value = category.id
                })
                setCategories(categories)
            } catch (e) {
                console.log(e)
            }
        }

        loader()

    }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        //let res = await api.addLocation(name, address, postalCode)
        if (description.length === 0) {
            setError("description vide")
            return;
        }
        if (maxCapacity.length === 0) {
            setError("capacité maximale vide")
            return;
        }
        if (begin_date.length === 0) {
            setError("date de début vide")
            return;
        }
        if (end_date.length === 0) {
            setError("date de fin vide")
            return;
        }
        if (price.length === 0) {
            setError("prix vide")
            return;
        }
        if (priceForTeamMember.length === 0) {
            setError("prix active vide")
            return;
        }
        if (selectedCategory === undefined) {
            setError("categorie vide")
            return;
        }


        let res = await api.addStage(selectedCategory,description, maxCapacity, begin_date, end_date, price, priceForTeamMember, selectedType, reserved)
        if (res.status !== undefined) {
            onUpdate();
            onCancel();
        } else {
            setError('une erreur est apparue sur le serveur');
        }
    }

    const handleChangeTextArea = event => {
        setDescription(event.target.value)
    }

    const handleChangeSelect = (type) => {
        setSelectedType(type)
    }

    const handleChangeCategory = (category) => {
        setSelectedCategory(category)
    }
    const handleChangeReserved = (event) => {
        if(reserved === 0){
            setReserved(1)
        }else if( reserved === 1) {
            setReserved(0)
        }
    }

    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            {error &&
                                <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                            }
                            <p className="font-bold">Ajouter un stage </p>
                            <p className="text-sm text-gray-700 mt-1">
                                veuillez remplir ce formulaire pour ajouter un stage.
                            </p>
                        </div>
                    </div>
                    <div className=" flex text-center  justify-center mt-3  ">
                        <form>
                            {
                                //description
                            }
                            <div className="flex ">
                                <p className="mr-2">description:</p>
                                <textarea onChange={handleChangeTextArea} defaultValue={""}
                                          className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent">
                                </textarea>
                            </div>
                            <div className="flex space-x-4 ">
                                {
                                    //dates
                                }
                                <div>
                                    <label className="mb-2 flex">
                                        <p> date debut :</p>
                                        <input type="date" onChange={e => setBegin(e.target.value)}
                                               className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                                    </label>
                                    <label className="mb-2 flex">
                                        <p className="mr-2">date de fin:</p>
                                        <input type="date" onChange={e => setEnd(e.target.value)}
                                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                        />
                                    </label>
                                </div>
                                {
                                    //  size
                                }
                                <div>
                                    <label className="mb-2 flex">
                                        <p className="mr-2">capacité maximale:</p>
                                        <input type="number" min="0" onChange={e => setMaxCapacity(e.target.value)}
                                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                        />
                                    </label>


                                    <label className="mb-2 flex">
                                        <p className="mr-2">type:</p>
                                        <Select options={types} isSearchable={false} onChange={(e) => {
                                            handleChangeSelect(e.label)
                                        }}/>
                                    </label>

                                    <label className="mb-2 flex">
                                        <p className="mr-2">category:</p>
                                        <Select options={categories} isSearchable={false} onChange={(e) => {
                                            handleChangeCategory(e.value)
                                        }}/>
                                    </label>
                                </div>
                                {
                                    //price
                                }
                                <div>
                                    <label className="mb-2 flex">
                                        <p className="mr-2">prix:</p>
                                        <input type="number" min="0" onChange={e => setPrice(e.target.value)}
                                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                        />
                                    </label>
                                    <label className="mb-2 flex">
                                        <p className="mr-2">prix actifs:</p>
                                        <input type="number" min="0"
                                               onChange={e => setPriceForTeamMember(e.target.value)}
                                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                        />
                                    </label>
                                </div>

                            </div>


                            <label className="mb-2 flex">
                                <p className="mr-2">reserver:</p>
                                <input type="checkbox" onClick={e=>{
                                    handleChangeReserved()
                                }}
                                       className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                />
                            </label>

                            <div className="flex">
                                <button onClick={e => {
                                    handleSubmit(e)
                                }}
                                        className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                                    ajouter le stage
                                </button>
                                <button onClick={() => {
                                    onCancel()
                                }}
                                        className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                                    fermer
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Portal>
        </Fragment>
    );
};

export default AddStageModal;
