import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";

import { FaEye, FaEyeSlash } from "react-icons/fa";

import InfoIconWithTooltipRight from "../../components/InfoIconWithTooltip/InfoIconWithTooltipRight";
import * as api from "../../services/AuthenticationService";
import { GENDERS, LEVEL_OPTIONS, T_SHIRT_OPTIONS } from "../../utils/constants";

const RegisterSchema = [
  Yup.object().shape({
    email: Yup.string()
      .email("Format d'email invalide")
      .required("L'email est requis")
      .max(255, "L'email ne peut pas dépasser 255 caractères"),
    password: Yup.string()
      .min(8, "Le mot de passe doit contenir au minimum 8 caractères")
      .max(50, "Le mot de passe ne peut pas dépasser 50 caractères")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,}$/,
        "Le mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre"
      )
      .required("Le mot de passe est requis"),
    verifyPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Les mots de passe doivent correspondre"
      )
      .required("La confirmation du mot de passe est requise"),
  }),
  Yup.object().shape({
    lastName: Yup.string()
      .min(2, "Le nom doit contenir au moins 2 caractères")
      .max(50, "Le nom ne peut pas dépasser 50 caractères")
      .matches(
        /^[a-zA-ZÀ-ÿ\s-']+$/,
        "Le nom ne peut contenir que des lettres, espaces, tirets et apostrophes"
      )
      .required("Le nom est requis"),
    firstName: Yup.string()
      .min(2, "Le prénom doit contenir au moins 2 caractères")
      .max(50, "Le prénom ne peut pas dépasser 50 caractères")
      .matches(
        /^[a-zA-ZÀ-ÿ\s-']+$/,
        "Le prénom ne peut contenir que des lettres, espaces, tirets et apostrophes"
      )
      .required("Le prénom est requis"),
    gender: Yup.string()
      .oneOf(GENDERS, "Veuillez sélectionner un genre valide")
      .required("Le genre est requis"),
  }),
  Yup.object().shape({
    street: Yup.string()
      .min(2, "La rue doit contenir au moins 2 caractères")
      .max(100, "La rue ne peut pas dépasser 100 caractères")
      .matches(/^[a-zA-ZÀ-ÿ0-9\s-'.]+$/, "Format de rue invalide")
      .required("La rue est requise"),
    houseNumber: Yup.string()
      .matches(/^\d+[a-zA-Z]?$/, "Format de numéro invalide (ex: 123 ou 123A)")
      .required("Le numéro de maison est requis"),
    box: Yup.string()
      .max(10, "La boîte ne peut pas dépasser 10 caractères")
      .nullable()
      .matches(/^[a-zA-Z0-9]*$/, "Format de boîte invalide"),
    locality: Yup.string()
      .min(2, "La localité doit contenir au moins 2 caractères")
      .max(50, "La localité ne peut pas dépasser 50 caractères")
      .matches(
        /^[a-zA-ZÀ-ÿ\s-']+$/,
        "La localité ne peut contenir que des lettres"
      )
      .required("La localité est requise"),
    postalCode: Yup.string()
      .matches(
        /^[0-9]{4}$/,
        "Le code postal doit contenir exactement 4 chiffres"
      )
      .required("Le code postal est requis"),
    phone: Yup.string()
      .matches(
        /^(?:0|\+32|0032)[1-9](?:\d{8}|\d\d(?:\d{6}))$/,
        "Format de téléphone invalide (ex: 0470123456 ou +32470123456)"
      )
      .required("Le numéro de téléphone est requis"),
  }),
  Yup.object().shape({
    birthDate: Yup.date()
      .max(
        new Date(Date.now() - 567648000000),
        "Vous devez avoir au moins 18 ans"
      )
      .min(new Date(1900, 0, 1), "Date de naissance invalide")
      .required("La date de naissance est requise"),
    level: Yup.string()
      .oneOf(
        LEVEL_OPTIONS.map((option) => option.value),
        "Veuillez sélectionner un niveau valide"
      )
      .required("Le niveau d'escalade est requis"),
    tShirt: Yup.string()
      .oneOf(
        T_SHIRT_OPTIONS.map((option) => option.value),
        "Veuillez sélectionner une taille valide"
      )
      .required("La taille de t-shirt est requise"),
    dataAuthorization: Yup.boolean().oneOf(
      [true],
      "Vous devez accepter l'utilisation de vos données"
    ),
    imageAuthorization: Yup.boolean(),
  }),
];

// Composants de formulaire améliorés
const FormField = ({
  name,
  label,
  type = "text",
  placeholder = "",
  maxLength,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === "password";

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="mb-4"
    >
      <label htmlFor={name} className="block text-gray-300 mb-2">
        {label}{" "}
        {maxLength && <span className="text-sm">({maxLength} car. max)</span>}
      </label>
      <div className="relative">
        <Field
          type={isPassword && showPassword ? "text" : type}
          id={name}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          className="w-full p-2 text-gray-900 rounded border-2 focus:border-pinkEv focus:ring-2 focus:ring-pinkEv"
        />
        {isPassword && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
          >
            {showPassword ? (
              <FaEyeSlash size={20} className="text-gray-500" />
            ) : (
              <FaEye size={20} className="text-gray-500" />
            )}
          </button>
        )}
      </div>
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 mt-1 text-sm"
      />
    </motion.div>
  );
};

const SelectField = ({ name, label, options, isSearchable = false }) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-gray-300 mb-2">
      {label}
    </label>
    <Field name={name}>
      {({ field, form }) => (
        <Select
          options={options}
          name={field.name}
          value={options.find((option) => option.value === field.value)}
          onChange={(option) => form.setFieldValue(field.name, option.value)}
          onBlur={field.onBlur}
          placeholder="Sélectionnez une option"
          className="text-gray-900"
          classNamePrefix="react-select"
          isSearchable={isSearchable}
          styles={{
            control: (base, state) => ({
              ...base,
              borderColor: state.isFocused ? "#FF0066" : base.borderColor,
              boxShadow: state.isFocused ? "0 0 0 1px #FF0066" : base.boxShadow,
              "&:hover": {
                borderColor: "#FF0066",
              },
              backgroundColor: "white",
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected
                ? "#FF0066"
                : state.isFocused
                ? "#FFE5EE"
                : "white",
              color: state.isSelected ? "white" : "#333",
              cursor: "pointer",
              ":active": {
                backgroundColor: state.isSelected ? "#FF0066" : "#FFE5EE",
              },
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: "white",
              zIndex: 9999,
            }),
            menuList: (base) => ({
              ...base,
              backgroundColor: "white",
            }),
            input: (base) => ({
              ...base,
              color: "#333",
            }),
            singleValue: (base) => ({
              ...base,
              color: "#333",
            }),
            placeholder: (base) => ({
              ...base,
              color: "#666",
            }),
            // Cache les checkbox
            indicatorsContainer: (base) => ({
              ...base,
              '& input[type="checkbox"]': {
                display: "none",
              },
            }),
            // S'assure que le container du select n'affiche pas de checkbox
            valueContainer: (base) => ({
              ...base,
              '& input[type="checkbox"]': {
                display: "none",
              },
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#FF0066",
              primary75: "#FF3385",
              primary50: "#FF99C2",
              primary25: "#FFE5EE",
            },
          })}
        />
      )}
    </Field>
    <ErrorMessage
      name={name}
      component="div"
      className="text-red-500 mt-1 text-sm"
    />
  </div>
);

const CheckboxField = ({ name, label, info }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    className="mb-4"
  >
    <div className="flex items-center">
      <Field
        type="checkbox"
        id={name}
        name={name}
        className="self-start form-checkbox h-6 w-6 text-pinkEv border-2 focus:border-pinkEv focus:ring-2 focus:ring-pinkEv"
      />
      <p className="self-start ml-2 text-red-500">*</p>
      <label htmlFor={name} className="ml-2 text-gray-300 cursor-pointer">
        {label}
      </label>
      <InfoIconWithTooltipRight info={info} />
    </div>
    <ErrorMessage
      name={name}
      component="div"
      className="text-red-500 mt-1 text-sm"
    />
    <article className="flex flex-row gap-x-8 pl-8">
      <p className="text-zinc-500 desktop:hidden italic">{info}</p>
    </article>
  </motion.div>
);

const formSteps = [
  {
    label: "Identifiants",
    fields: [
      <FormField
        key="email"
        name="email"
        label="Email"
        type="email"
        placeholder="votre.email@exemple.com"
        maxLength={255}
      />,
      <FormField
        key="password"
        name="password"
        label="Mot de passe"
        type="password"
        placeholder="Minimum 8 caractères"
        maxLength={50}
      />,
      <FormField
        key="verifyPassword"
        name="verifyPassword"
        label="Confirmer le mot de passe"
        type="password"
        placeholder="Confirmez votre mot de passe"
        maxLength={50}
      />,
    ],
  },
  {
    label: "Informations personnelles",
    fields: [
      <FormField key="lastName" name="lastName" label="Nom" />,
      <FormField key="firstName" name="firstName" label="Prénom" />,
      <SelectField
        key="gender"
        name="gender"
        label="Genre"
        options={GENDERS.map((gender) => ({ value: gender, label: gender }))}
      />,
    ],
  },
  {
    label: "Adresse",
    fields: [
      <FormField key="street" name="street" label="Rue" />,
      <FormField key="houseNumber" name="houseNumber" label="Numéro" />,
      <FormField key="box" name="box" label="Boîte" />,
      <FormField key="locality" name="locality" label="Localité" />,
      <FormField key="postalCode" name="postalCode" label="Code postal" />,
      <FormField key="phone" name="phone" label="Téléphone" type="tel" />,
    ],
  },
  {
    label: "Informations complémentaires",
    fields: [
      <FormField
        key="birthDate"
        name="birthDate"
        label="Date de naissance"
        type="date"
      />,
      <SelectField
        key="level"
        name="level"
        label="Niveau d'escalade"
        options={LEVEL_OPTIONS}
      />,
      <SelectField
        key="tShirt"
        name="tShirt"
        label="Taille de t-shirt"
        options={T_SHIRT_OPTIONS}
      />,
      <CheckboxField
        key="dataAuthorization"
        name="dataAuthorization"
        label="J'accepte que les données soient utilisées dans le cadre de l'association"
        info="Les informations stockées sont uniquement celles nécessaires pour la gestion de l'association et le suivi des cours. Nous nous engageons à ne jamais utiliser ces données à des fins commerciales. Toute demande de suppression des données doit être faite par mail à secretariat@evolutionverticale.be"
      />,
      <CheckboxField
        key="imageAuthorization"
        name="imageAuthorization"
        label="J'accepte que les photos soient utilisées dans le cadre de l'association"
        info="J'accepte que les photos prises durant les activités du club peuvent servir à des fins promotionnelles sur le site internet et réseaux sociaux du club."
      />,
    ],
  },
];

const Register = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  // gestion des erreurs de validation
  const [serverError, setServerError] = useState({
    message: null,
    field: null,
  });

  // Animation simple de transition entre les étapes
  const pageVariants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const handleSubmit = async (values, actions) => {
    if (step === RegisterSchema.length - 1) {
      try {
        await api.register(
          values.lastName,
          values.firstName,
          values.gender,
          values.birthDate,
          values.street,
          values.houseNumber,
          values.box,
          values.postalCode,
          values.locality,
          values.phone,
          values.level,
          values.tShirt,
          values.email,
          values.password,
          values.imageAuthorization
        );
        navigate("/login");
      } catch (error) {
        //console.log("=== DEBUG Register ===");
        // console.log("Error details:", {
        //   message: error.message,
        //   fields: error.fields,
        //   field: error.field,
        //   type: error.type,
        //   status: error.status,
        // });

        if (
          error.type === "duplicate_multiple" &&
          Array.isArray(error.fields)
        ) {
          // Cas de plusieurs champs dupliqués
          error.fields.forEach((field) => {
            const message =
              field === "email"
                ? "Cette adresse email est déjà utilisée"
                : "Ce numéro de téléphone est déjà utilisé";
            actions.setFieldError(field, message);
          });

          // Message global pour les doublons multiples
          setServerError({
            message: "L'email et le téléphone sont déjà utilisés",
            fields: error.fields,
          });
        } else if (error.type === "duplicate" && error.field) {
          // Cas d'un seul champ dupliqué
          actions.setFieldError(error.field, error.message);

          // Message global pour un seul doublon
          setServerError({
            message: error.message,
            field: error.field,
          });
        } else {
          // Autres erreurs
          setServerError({
            message: error.message,
            field: null,
          });
        }

        actions.setSubmitting(false);
      }
    } else {
      setStep(step + 1);
      actions.setTouched({});
    }
    actions.setSubmitting(false);
  };

  return (
    <section className="bg-backgroundEV text-white overflow-hidden min-h-screen">
      <section className="flex flex-col-reverse items-center gap-y-8 laptop:grid laptop:grid-cols-2 laptop:w-4/5 m-auto gap-x-8 pb-8">
        {/* Section d'information */}
        <article className="flex flex-col items-center justify-center text-center w-4/5">
          <h2 className="text-xl font-bold laptop:text-left laptop:w-full">
            Note pour les parents
          </h2>
          <p className="laptop:text-left laptop:w-full">
            Merci de vous inscrire en <strong>votre propre nom</strong>. Vous
            aurez la possibilité de renseigner les informations de votre enfant
            lors de l'inscription.
          </p>
        </article>

        <section className="px-4 pb-4 pt-6 mt-6 tablet:rounded-lg tablet:mx-1 bg-[#2B2D3B] w-full max-w-[515px]">
          {/* Barre de progression simple */}
          <motion.div
            className="h-2 bg-pinkEv rounded-full mb-6"
            initial={{ width: "0%" }}
            animate={{ width: `${((step + 1) / formSteps.length) * 100}%` }}
            transition={{ duration: 0.3 }}
          />

          <Formik
            initialValues={{
              email: "",
              password: "",
              verifyPassword: "",
              lastName: "",
              firstName: "",
              gender: "",
              street: "",
              houseNumber: "",
              box: "",
              locality: "",
              postalCode: "",
              phone: "",
              birthDate: "",
              level: "",
              tShirt: "",
              dataAuthorization: false,
              imageAuthorization: false,
            }}
            validationSchema={RegisterSchema[step]}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <AnimatePresence mode="wait">
                  <motion.div
                    key={step}
                    variants={pageVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{ duration: 0.2 }}
                  >
                    <h2 className="text-xl mb-4">{formSteps[step].label}</h2>
                    {formSteps[step].fields}
                  </motion.div>
                </AnimatePresence>

                {serverError.message && (
                  <div className="text-red-500 mb-4 text-center">
                    {serverError.message}
                  </div>
                )}

                <div className="flex flex-col-reverse gap-4 laptop:flex-row justify-between pt-4">
                  {step > 0 && (
                    <motion.button
                      type="button"
                      onClick={() => setStep(step - 1)}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                    >
                      Précédent
                    </motion.button>
                  )}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                  >
                    {step === RegisterSchema.length - 1
                      ? "S'inscrire"
                      : "Suivant"}
                  </button>
                </div>

                <footer className="pt-8">
                  <Link
                    to="/login"
                    className="hover:text-pinkEv hover:border-pinkEv transition-colors duration-200"
                  >
                    Déjà inscrit ? Connectez-vous
                  </Link>
                </footer>
              </Form>
            )}
          </Formik>
        </section>
      </section>
    </section>
  );
};

export default Register;
