import React, {Fragment, useState} from "react";
import Portal from "../Portal/Portal";
import * as api from "../../services/InscriptionDatesService"

const AddInscriptionDatesModal = ({onCancel, onUpdate}) => {
  const [year, setYear] = useState(2024);
  const [oldMemberDate, setOldMemberDate] = useState();
  const [newMemberDate, setNewMemberDate] = useState();
  const [error, setError] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    if (year.length === 0) {
      setError("année vide")
      return;
    }

    let res = await api.addInscriptionDates(year,oldMemberDate,newMemberDate);
    if (res.status !== undefined) {
      onUpdate();
      onCancel();
    } else {
      setError('une erreur est apparue sur le serveur');
    }
  }

  return (
      <Fragment>
        <div className="fixed inset-0 bg-black opacity-25 "/>
        <Portal>
          <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
          <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
            <div className="md:flex items-center">
              <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                {error &&
                    <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                }
                <p className="font-bold">Ajouter une année d'inscriptions </p>
                <p className="text-sm text-gray-700 mt-1">
                  Veuillez remplir ce formulaire pour ajouter une année d'inscription.
                </p>
              </div>
            </div>
            <div className=" flex text-center  justify-center mt-3  ">
              <form>
                <div className="flex space-x-4 ">
                  {
                    //year
                  }
                  <div>
                    <label className="mb-2 flex">
                      <p className="mr-2">year:</p>
                      <input type="number" min="2024"
                             defaultValue={2024}
                             onChange={e => setYear(e.target.value)}
                             className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                      />
                    </label>

                    {
                      // old member inscription date
                    }
                    <div className="flex tablet:flex-wrap ">
                      <div className="form-check form-check-inline ml-3 ">
                        <p className="form-check-label inline-block mr-2"> Date début inscription anciens membres :</p>
                        <input type="datetime-local"
                               onChange={e => setOldMemberDate(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"/>
                      </div>
                      {
                        // new member inscription date
                      }
                      <div className="form-check form-check-inline ml-3">
                        <p className="form-check-label inline-block mr-2"> Date début d'inscription nouveaux membres :</p>
                        <input type="datetime-local"
                               onChange={e => setNewMemberDate(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <button onClick={e => {
                    handleSubmit(e)
                  }}
                          className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                    Ajouter l'année d'inscriptions
                  </button>
                  <button onClick={() => {
                    onCancel()
                  }}
                          className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                    Fermer
                  </button>
                </div>
              </form>
            </div>

          </div>
        </Portal>
      </Fragment>
  );
};

export default AddInscriptionDatesModal;
