import React, {useState} from "react";

//if payment is successful show a page with a centered green box with a Payment Successful message and a button to go back to the home page
//if payment is not successful show a page with a centered red box with a Payment Failed message and a button to go back to the home page
const Payment_checkout_pass = () => {

    const [paymentStatus, setPaymentStatus] = useState("pending");

    //handle redirect to home page
    const menu = () => {
        window.location.href = "/";
    }
    return (
        <div className="min-h-screen bg-backgroundEV">
            <div className="h-screen flex items-center justify-center ">

                <div className="payment-success container ">
                    <h1 className='text-green-700'>Payment Successful</h1>
                    <button
                        className='rounded font-bold bg-pinkEv border-gray-500 border-2  p-3 mt-2 text-white  focus:border-transparent hover:border-pinkEv'
                        onClick={menu}>Go Back to Home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Payment_checkout_pass