import {Navigate, Outlet} from "react-router-dom";
import React from "react";
import {NavSidebarTeamMember} from "../../components/NavSidebar/NavSidebarTeamMember";

const TeamMemberLayout = ({right}) => {
    if (right !== "team_member") {
        return <Navigate to="/"/>;
    }

    return (
        <>
            <NavSidebarTeamMember/>
            <Outlet/>
        </>
    );
}

export default TeamMemberLayout;
