import React from "react";

const CourseList = ({courseList}) => {

    return (
        <div className=" overflow-auto overflow-scroll  ">
            <h2 className="text-3xl ">Places restantes aux cours d'escalade en salle</h2>
            <table className="table-auto border-2 rounded-l">
                <thead>
                <tr>
                    <th className="px-4 border py-2">Cours</th>
                    <th className="px-4 border py-2">Salle</th>
                    <th className="px-4 border py-2">catégorie(age)</th>
                    <th className="px-4 border py-2">Jour</th>
                    <th className="px-4 border py-2">Heure de début</th>
                    <th className="px-4 border py-2">Heure de fin</th>
                    <th className="px-4 border py-2">inscrits</th>
                </tr>
                </thead>
                <tbody>
                {courseList && courseList.map((course) => (
                    <tr key={course.id}>
                        <td className="px-4 py-2">{course.week_day.substring(0, 2) + course.begin_time.substring(0, 2) + course.end_time.substring(0, 2) + course.reference.substring(0, 2)}</td>
                        <td className="px-4 py-2">{course.location.name}</td>
                        <td className="px-4 py-2">{course.section.description}({course.section.min_age}-{course.section.max_age})</td>
                        <td className="px-4 py-2">{course.week_day}</td>
                        <td className="px-4 py-2">{course.begin_time}</td>
                        <td className="px-4 py-2">{course.end_time}</td>
                        <td className="px-4 py-2">{course.countInscriptions}/{course.max_capacity}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )

}
export default CourseList;