import React, {Fragment, useEffect, useState} from "react";
import Portal from "../Portal/Portal";
import * as api from "../../services/SectionService"
import Select from "react-select";
import {LEVEL_OPTIONS} from "../../utils/constants";



const AddSectionModal = ({onCancel, onUpdate}) => {
    const [description, setDescription] = useState();
    const [minAge, setMinAge] = useState();
    const [maxAge, setMaxAge] = useState();
    const [level, setLevel] = useState();
    const [error, setError] = useState();



    const handleSubmit = async e => {
        e.preventDefault();
        if (!description || !minAge || !maxAge || !level) {
            setError(' Veuillez completer tout les champs ');
            return;
        }
        let res = await api.addSection(description, minAge, maxAge,level);

        if (res.status !== undefined) {
            onUpdate();
            onCancel();
        } else {
            setError('une erreur est apparue sur le serveur');
        }
    }

    const handleLevelChange = (e) => {
        setLevel(e.value);
    }


    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            {error &&
                                <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                            }
                            <p className="font-bold">Ajouter une section</p>
                            <p className="text-sm text-gray-700 mt-1">
                                veuillez remplir ce formulaire pour ajouter une section.
                            </p>
                        </div>
                    </div>
                    <div className=" flex text-center  justify-center mt-3  ">
                        <form>
                            {
                                //description
                            }
                            <label className="mb-2 flex">
                                <p className="mr-2">description:</p>
                                <input type="text" value={description} onChange={e => setDescription(e.target.value)}
                                       className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                />
                            </label>
                            {
                                //level
                            }
                            <div className="flex ">
                                <p>Niveau d'escalade:</p>
                                <Select className="text-black ml-5 " defaultValue={level} options={LEVEL_OPTIONS}
                                        onChange={handleLevelChange}/>
                            </div>

                            {
                                //age min
                            }
                            <div className="flex ">
                                <p> age minimal :</p>
                                <input type="number" value={minAge} onChange={e => setMinAge(e.target.value)}
                                       className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                            </div>
                            {
                                //age max
                            }
                            <div className="flex ">
                                <p> age maximal :</p>
                                <input type="number" value={maxAge} onChange={e => setMaxAge(e.target.value)}
                                       className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                            </div>
                            <div className="flex">
                                <button onClick={e => {
                                    handleSubmit(e)
                                }}
                                        className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white ">
                                    ajouter la section
                                </button>
                                <button onClick={() => {
                                    onCancel()

                                }}
                                        className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                                    fermer
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Portal>
        </Fragment>
    );
};

export default AddSectionModal;
