import React, {Fragment, useEffect, useState} from "react";
import Portal from "../Portal/Portal";
import {editInscriptionDate} from "../../services/InscriptionDatesService";


const EditInscriptionDatesModal = ({onCancel, onUpdate, inscriptionDatesSelected,}) => {
  const [year, setYear] = useState(inscriptionDatesSelected.year);
  const [oldMemberDate, setOldMemberDate] = useState("");
  const [newMemberDate, setNewMemberDate] = useState("");
  const [error, setError] = useState();
  const [outputMessage , setOutputMessage] = useState();

  useEffect(() => {
    async function loader() {
      if(inscriptionDatesSelected.old_member_date!==null){
        setOldMemberDate(formatDate(inscriptionDatesSelected.old_member_date));
      }
      if(inscriptionDatesSelected.new_member_date!==null){
        setNewMemberDate(formatDate(inscriptionDatesSelected.new_member_date));
      }
    }
    loader()
  }, [])
  const handleSubmit = async (e) =>{
    e.preventDefault()
    try{
      await editInscriptionDate(year,oldMemberDate,newMemberDate);
      setOutputMessage('modifié✅')
    }catch (e) {
      setOutputMessage('erreur❌')
    }
    onUpdate()
  }

  const formatDate = (date) => {
    const d = new Date(date);
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const year = d.getFullYear();
    const hours = (`0${d.getHours()}`).slice(-2);
    const minutes = (`0${d.getMinutes()}`).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };


  return (
      <Fragment>
        <div className="fixed inset-0 bg-black opacity-25 "/>
        <Portal>
          <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
          <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
            <div className="md:flex items-center">
              <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                {error &&
                    <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                }
                <p className="font-bold">Editer les dates d'inscription pour l'année {year}</p>
              </div>
            </div>
            <div className=" flex h-full overflow-y-scroll  text-center  justify-center mt-3 ">
              <form>
                <div className="flex flex-wrap ">
                  <div className="form-check form-check-inline ml-3">
                    <label
                        className="form-check-label inline-block mr-2">Date début d'inscriptions des anciens membres :</label>
                    <input type="datetime-local"
                           onChange={e => setOldMemberDate(e.target.value)}
                           value={oldMemberDate}
                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                    />
                  </div>
                  <div className="form-check form-check-inline ml-3">
                    <label
                        className="form-check-label inline-block mr-2">Date début d'inscriptions des nouveaux membres :</label>
                    <input type="datetime-local"
                           onChange={e => setNewMemberDate(e.target.value)}
                           value={newMemberDate}
                           className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                  </div>
                </div>
                <div className="flex">
                  <button
                      onClick={e => handleSubmit(e)}
                      className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1  bg-pinkEv text-white ">
                    Modifier les dates de début d'inscriptions de l'année {year}
                  </button>
                  <label>{outputMessage}</label>
                  <button onClick={() => {
                    onCancel()
                  }}
                          className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                    Fermer
                  </button>
                </div>
              </form>
            </div>

          </div>
        </Portal>
      </Fragment>
  );
};

export default EditInscriptionDatesModal;
