import React, {Fragment, useState} from "react";
import Portal from "../Portal/Portal";
import {updatePassword} from "../../services/AuthenticationService";
import {useNavigate} from "react-router-dom";


const EditInfoModal = ({onCancel}) => {
    const navigate = useNavigate();
    const [description, setDescription] = useState();
    const [minAge, setMinAge] = useState();
    const [maxAge, setMaxAge] = useState();
    const [error, setError] = useState();
    const [changePassword,setChangePassword] = useState(false)
    const [password, setPassword] = useState();
    const [verifyPassword, setVerifyPassword] = useState();
    const [passwordError, setPasswordError] = useState();
    const [verifyPasswordError, setVerifyPasswordError] = useState();
    const [outputMessage , setOutputMessage] = useState();

    const handlePasswordChange = password => {
        if (password.length < 8) {
            setPasswordError("Le mot de passe doit contenir au minimum 8 caractères.");
            return;
        }
        setPassword(password);
        setPasswordError(null);
    }

    const handleVerifyPasswordChange = verifyPassword => {
        if (password !== verifyPassword) {
            setVerifyPasswordError("Les mots de passes ne correspondent pas.");
            return;
        }

        setVerifyPassword(verifyPassword);
        setVerifyPasswordError(null);
    }

    const  handleSetChangePassword = () => {
        setChangePassword(!changePassword)
    }
    const handleSendNewPassword = async () => {
        try {
            const result = await updatePassword(password)
            if(result === 'ERR_NETWORK'){
                navigate("/server_maintenance")
            }
            setOutputMessage('modifié✅')

        }catch (e) {
            console.error(e);
            setOutputMessage('erreur❌')
        }
        handleSetChangePassword()
    }

    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
                    <div className="md:flex items-center">
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            {error &&
                                <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                            }
                            <p className="font-bold">Editer vos informations</p>
                            <p className="text-sm text-gray-700 mt-1">
                                que désirez vous éditer
                            </p>
                            {changePassword?
                                <div>
                                    <label className="mb-2 flex justify-center">
                                        <div className="mr-2">
                                            <p className="mr-2">Mot de passe:</p>
                                            <input type="password" onChange={e => handlePasswordChange(e.target.value)}
                                                   autoComplete="new-password"
                                                   className="shadow bg-transparent border  rounded w-full py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                            />
                                        </div>

                                        {
                                            passwordError &&
                                            <p className="text-red-700">{passwordError}</p>
                                        }

                                        <div>
                                            <p className="mr-2">Confirmez:</p>
                                            <input type="password"
                                                   onChange={e => handleVerifyPasswordChange(e.target.value)}
                                                   autoComplete="new-password"
                                                   className="shadow bg-transparent border  rounded w-full py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                                            />
                                        </div>

                                        {
                                            verifyPasswordError &&
                                            <p className="text-red-700">{verifyPasswordError}</p>
                                        }
                                    </label>
                                    <button className="m-3 p-2 border-1 rounded bg-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1" onClick={event => handleSendNewPassword()}> changer </button>
                                </div>
                                :
                                <div className=" flex flex-wrap justify-center items-center">

                                    <button onClick={handleSetChangePassword}
                                            className="m-3 p-2 border-1 rounded bg-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1"> changer
                                        de mots de passe
                                    </button>
                                    {outputMessage &&
                                        <p className="rounded bg-pinkEv-800 m-3 p-2 shadow-lg ">{outputMessage}</p>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className=" flex text-center  justify-center mt-3  ">
                        <form>


                        </form>
                    </div>

                </div>
            </Portal>
        </Fragment>
    );
};

export default EditInfoModal;
