import React, {Fragment, useEffect, useState} from "react";
import Portal from "../Portal/Portal";
import Select from "react-select";
import {editBrevet} from "../../services/BrevetService";

const EditBrevetModal = ({onCancel, onUpdate, brevetSelected,}) => {
  const [brevet, setBrevet] = useState(brevetSelected.brevet);
  const [position, setPosition] = useState(brevetSelected.position);
  const [type, setType] = useState(brevetSelected.type);
  const [description, setDescription] = useState(brevetSelected.description);
  const [error, setError] = useState();
  const [outputMessage , setOutputMessage] = useState();
  const [typeList, setTypeList] = useState([])

  useEffect(() => {
    async function loader() {
      const types=[
        {
          value:"indoor",
          label:"indoor"},
        {
          value:"outdoor",
          label:"outdoor"
        }
      ]
      setTypeList(types)
    }

    loader()
  }, [])

  const handleSubmit = async (e) =>{
    e.preventDefault()
    try{
      await editBrevet(brevetSelected.id,type,brevet,description,position)
      setOutputMessage('modifié✅')
    }catch (e) {
      setOutputMessage('erreur❌')
    }
    onUpdate()
  }


  return (
      <Fragment>
        <div className="fixed inset-0 bg-black opacity-25 "/>
        <Portal>
          <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
          <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative">
            <div className="md:flex items-center">
              <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                {error &&
                    <h1 className="rounded bg-red-600 w-60 m-auto shadow-lg ">{error}</h1>
                }
                <p className="font-bold">Editer le brevet</p>
              </div>
            </div>
            <div className=" flex h-full overflow-y-scroll  text-center  justify-center mt-3 ">
              <form>
                <div className="flex flex-wrap ">
                  <div className="form-check form-check-inline ml-3">
                    <label
                        className="form-check-label inline-block mr-2">brevet:</label>
                    <input type="text"
                           onChange={e => setBrevet(e.target.value)}
                           value={brevet}
                           className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                    />
                  </div>
                  <div className="form-check form-check-inline ml-3">
                    <label
                        className="form-check-label inline-block mr-2">position:</label>
                    <input type="number"
                           onChange={e => setPosition(e.target.value)}
                           value={position}
                           className="mt-1 mb-4 block w-60 bg-transparent border rounded px-2 py-2"/>
                  </div>
                  <div className="form-check form-check-inline ml-3">
                    <label
                        className="form-check-label inline-block mr-2">type: </label>
                    <Select className="text-black ml-2 mb-2  "
                            options={typeList} value={typeList.find(option => option.value === type)}
                            onChange={setType}
                    />
                  </div>
                </div>
                <div
                    className="form-check form-check-inline ml-3 w-auto h-auto">
                  <label
                      className="form-check-label inline-block mr-2">description:</label>
                  <textarea onChange={e => setDescription(e.target.value)} value={description}
                            className="shadow bg-transparent border rounded w-80 h-32 py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent">
                  </textarea>
                </div>
                <div className="flex">
                  <button
                      onClick={e => handleSubmit(e)}
                      className=" w-1/2  border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1  bg-pinkEv text-white ">
                  Modifier le brevet
                  </button>
                  <label>{outputMessage}</label>
                  <button onClick={() => {
                    onCancel()
                  }}
                          className=" w-1/2  border rounded p-1  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black ">
                    Fermer
                  </button>
                </div>
              </form>
            </div>

          </div>
        </Portal>
      </Fragment>
  );
};

export default EditBrevetModal;
