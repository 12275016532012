import React, {useEffect} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {NavSidebarUser} from "../../components/NavSidebar/NavSidebarUser";
import {ifExpiredDisconnect} from "../../utils/toolbox";

const UserLayout = ({right}) => {

    if (right !== "user") {
        return <Navigate to="/"/>;
    }

    return (
        <>
            <NavSidebarUser/>
            <Outlet/>
        </>
    );
}

export default UserLayout;
