import './App.css';
import React from "react";
import Routers from "../../pages/_routers";


const App = () => {
    return (
        <div className="  bg-backgroundEV m-auto text-white ">
            <Routers/>
        </div>
    );
}

export default App;
