import React from 'react';
import {createRoot} from 'react-dom/client';
import './styles/index.css';
import AppLoader from "./components/App/AppLoader";


createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <AppLoader/>
    </React.StrictMode>,
);
